<template>
  <div class="container">
    <h1 class="title">Account Details</h1>
    <table>
      <tr>
        <td>
          <strong>Name</strong>
        </td>
        <td>{{ currentUser.firstName }} {{ currentUser.lastName }}</td>
      </tr>
      <tr>
        <td>
          <strong>Email</strong>
        </td>
        <td>{{ currentUser.email }}</td>
      </tr>
      <tr>
        <td>
          <strong>User since</strong>
        </td>
        <td>{{ currentUser.createdOn | toDate }}</td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { Invoice } from '@/models/purchases';
import { getUserOrders } from '@/services/payments.service';
import app from '@/store/modules/app';
import { User } from '@/models/user';

@Component
export default class AccountHome extends Vue {
  public loading = false;

  /** User orders. */
  public orders: Invoice[] = [];

  /** Current user */
  get currentUser(): User {
    return app.currentUser as User;
  }

  mounted() {
    this.loadOrders();
  }

  public async loadOrders() {
    try {
      this.loading = true;
      this.orders = await getUserOrders();
    } catch (err) {
      error(
        this,
        err,
        'Orders Unavailable',
        'Viewing orders is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
tr {
  td:first-of-type {
    text-align: right;
    padding-right: 20px;
    padding-bottom: 10px;
  }
}
</style>
