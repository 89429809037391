import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=234013bc&scoped=true&"
import script from "./NavBar.vue?vue&type=script&lang=ts&"
export * from "./NavBar.vue?vue&type=script&lang=ts&"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=234013bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234013bc",
  null
  
)

export default component.exports