import Vue from 'vue';
import { format, parseISO } from 'date-fns';
import { dateFormat } from '@/utilities/constants';

Vue.filter('toDateTime', (date: string | Date) => {
  if (!date) {
    return '';
  }
  let dt: Date = date as Date;
  if (!(date instanceof Date)) {
    dt = parseISO(date);
  }
  return format(dt, `${dateFormat} h:mm a`);
});

Vue.filter('toDate', (date: string | Date) => {
  if (!date) {
    return '';
  }
  let dt: Date = date as Date;
  if (!(date instanceof Date)) {
    dt = parseISO(date);
  }
  return format(dt, dateFormat);
});
