<template>
  <div class="home container">
    <div class="columns">
      <div class="column title-row">
        <h1 class="title">Reports</h1>
        <h2 v-if="site" class="subtitle">{{ site.siteName }}</h2>
      </div>
      <div class="column date-selector">
        <b-datepicker
          placeholder="Select date range"
          v-model="dates"
          :max-date="new Date()"
          range
        ></b-datepicker>
        <b-button type="is-primary" v-on:click="loadReport(site)" :loading="loading"
          >Load Report</b-button
        >
      </div>
    </div>
    <div class="report-content" v-show="loaded">
      <SiteAnalyticsReport ref="report" :i18n="reportText" />
    </div>
  </div>
</template>

<script lang="ts">
import { format, parse } from 'date-fns';
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SiteAnalyticsReport from '@/components/SiteAnalyticsReport.vue';
import { Site } from '@/models/site';
import { error, warn } from '@/services/user-messages.service';
import { SiteRouting } from '@/mixins/SiteRouting';
import { ReportText } from '@/models/analytics';
import { dateFormat } from '@/utilities/constants';

@Component({
  components: { SiteAnalyticsReport }
})
export default class Reports extends mixins(SiteRouting) {
  /** Start date (from query param) */
  @Prop({
    type: String,
    default: ''
  })
  readonly start!: string;

  /** End date (from query param) */
  @Prop({
    type: String,
    default: ''
  })
  readonly end!: string;

  /** Used to show spinners */
  public loading = false;
  /** Show report content */
  public loaded = false;
  /** Start and end dates for report */
  public dates: Date[] = [];

  public reportText!: ReportText;

  public $refs!: {
    report: SiteAnalyticsReport;
  };

  constructor() {
    super();
    this.buildReportText();
  }

  private readonly urlDateFormat = 'MM-dd-yyyy';

  created() {
    this.setDatesFromQuery();
    this.$on('site-updated', (site: Site) => {
      if (this.dates.length > 1) {
        // only auto load report if dates are set in URL
        // duplicate date check happens here first to prevent toast warning when page first loaded
        this.loadReport(site);
      }
    });
  }

  private setDatesFromQuery() {
    if (this.start && this.end) {
      try {
        const start = parse(this.start, this.urlDateFormat, new Date());
        const end = parse(this.end, this.urlDateFormat, new Date());
        this.dates = [start, end];
      } catch (err) {
        console.error('Failed to set dates from query params.');
      }
    }
  }

  private setQueryFromDates() {
    const query = {
      start: format(this.dates[0], this.urlDateFormat),
      end: format(this.dates[1], this.urlDateFormat)
    };
    if (this.$route.query.start === query.start && this.$route.query.end === query.end) {
      // queries match, no need to update URL
      return;
    }
    this.$router.push({ query });
  }

  async loadReport(site: Site) {
    try {
      if (!site) {
        // active site not yet set
        return;
      }
      if (this.dates.length < 1) {
        warn(this, 'Missing Dates', 'Set a date range for the report.');
        return;
      }
      this.loading = true;
      this.setQueryFromDates();
      this.buildReportText();
      await this.$refs.report.loadReport(site, this.dates);
      this.loaded = true;
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }

  private buildReportText() {
    const timeRange = this.dates?.length
      ? `from ${format(this.dates[0], dateFormat)} to ${format(this.dates[1], dateFormat)}`
      : '';
    this.reportText = {
      timeRange,
      topPages: 'Top pages',
      topPagesDescription: `The most popular pages ${timeRange}.`,
      topPageViews: 'Views per page',
      topPageViewsDescription: `Site views ${timeRange}, grouped by page.`
    };
  }
}
</script>

<style lang="scss">
.title-row {
  display: flex !important;
  align-items: center;

  h1 {
    margin: 0 1em 0 0 !important;
  }

  h1,
  h2 {
    margin-top: 0 !important;
    display: inline-block;
  }
}

div.date-selector {
  display: flex;
  align-items: center;

  div.datepicker {
    margin-right: 10px;
  }

  button {
    margin-top: 0;
  }
}
</style>
