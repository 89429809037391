<template>
  <div class="full-layout">
    <b-sidebar position="static" :mobile="'reduce'" open>
      <div class="p-1">
        <b-menu class="is-custom-mobile">
          <b-menu-list label="Site Settings">
            <b-menu-item
              label="Notifications"
              :to="{ name: 'site-notifications'}"
              tag="router-link"
            ></b-menu-item>
            <b-menu-item
              label="Users"
              :to="{ name: 'site-users', params: { siteName } }"
              tag="router-link"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
    <div class="inline-content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SiteRouting } from '@/mixins/SiteRouting';

@Component
export default class SettingsLayout extends mixins(SiteRouting) {}
</script>

<style scoped lang="scss">
div.b-sidebar,
div.inline-content {
  display: inline-flex;
}

div.inline-content {
  padding: 1em 2em;
}
</style>
