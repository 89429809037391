<template>
  <div class="container">
    <h1 class="title">Plans</h1>
    <div v-for="plan in plans" :key="plan.id">
      <p class="title is-5">{{ plan.title }}</p>
      <p class="subtitle is-5">{{ plan.price | currencyToString }} / month</p>
      <p>{{ plan.description }}</p>
      <b-button type="is-primary" tag="router-link" :to="{ name: 'add-plan' }">Add plan</b-button>
    </div>
    <h2 class="title is-4">Subscriptions</h2>
    <template v-if="!noneToShow">
      <div class="subscription" v-for="sub in subscriptions" :key="sub.id">
        <h6 class="title is-6">{{ sub.domain }}</h6>
        <div class="columns">
          <p class="column">
            {{ sub.plan.title }}
          </p>
          <p class="column">{{ sub.plan.price | currencyToString }} / month</p>
          <div class="column">
            <p>Started on {{ sub.started | toDate }}</p>
            <p>Next payment on {{ sub.nextPayment | toDate }}</p>
          </div>
        </div>
      </div>
    </template>
    <p v-else>
      You aren't signed up for a monthly plan.
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { SiteSubscription } from '@/models/plans';
import { getAvailablePlans, getPlanSubscriptions } from '@/services/plans.service';
import { StoreItem } from '@/models/purchases';

@Component
export default class UserPlans extends Vue {
  /** All plans */
  public plans: StoreItem[] = [];

  /** User's subscriptions */
  public subscriptions: SiteSubscription[] = [];

  public loading = false;

  /** If `true`, no subscriptions to show so table is hidden and friendly message displayed. */
  public noneToShow = false;

  mounted() {
    this.loadPlans();
  }

  public async loadPlans() {
    try {
      this.loading = true;
      const [plans, subscriptions] = await Promise.all([
        getAvailablePlans(),
        getPlanSubscriptions()
      ]);
      this.plans = plans;
      this.subscriptions = subscriptions;
      this.noneToShow = this.subscriptions.length === 0;
    } catch (err) {
      error(
        this,
        err,
        'Plans Unavailable',
        'Viewing plan subscriptions is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-top: 2em;
}

.subscription {
  margin-bottom: 1.5em;

  h6 {
    margin-bottom: 0.25em;
  }
}
</style>
