<template>
  <div class="container">
    <h1 class="title">Files</h1>
    <b-table stickyHeader hoverable :data="siteAssets">
      <b-table-column field="name" label="Name" sortable v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="type" label="File type" sortable v-slot="props">
        {{ props.row.type }}
      </b-table-column>
      <b-table-column field="usageCount" label="Pages used" sortable numeric v-slot="props">
        <b-tooltip
          :triggers="['click']"
          :auto-close="['outside', 'escape']"
          type="is-white"
          multilined
          append-to-body
        >
          <template v-slot:content>
            <strong>Pages using this file</strong>
            <ul>
              <li v-for="page in pagesUseAsset" :key="page">{{ page }}</li>
            </ul>
          </template>
          <b-field class="pointer">
            <span @click="getPagesUseAsset(props.row)">{{ props.row.usageCount }}</span>
          </b-field>
        </b-tooltip>
      </b-table-column>
      <b-table-column field="uploadDate" label="Upload date" sortable centered v-slot="props">
        {{ props.row.uploadDate | toDate }}
      </b-table-column>
      <b-table-column v-slot="props" width="100">
        <font-awesome-icon
          title="Replace file"
          icon="upload"
          class="action-icon"
          v-on:click="showReplaceFileDialog(props.row)"
        />
        <font-awesome-icon
          title="Delete file"
          icon="trash"
          :disabled="props.row.usageCount > 0"
          class="action-icon"
          v-on:click="deleteSiteAsset(props.row)"
        />
      </b-table-column>

      <template #empty>
        <div class="has-text-centered">No files were found.</div>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { ModalProgrammatic as Modal } from 'buefy';
import { SiteRouting } from '@/mixins/SiteRouting';
import SiteAssetService from '@/services/site-asset.service';
import { SiteAsset } from '@/models/site-asset';
import UploadModal from '@/components/UploadModal.vue';
import { success, error } from '@/services/user-messages.service';

@Component
export default class SiteAssets extends mixins(SiteRouting) {
  siteAssets: SiteAsset[] = [];
  private siteAssetService = new SiteAssetService();
  pagesUseAsset: string[] = [];

  created() {
    this.$on('site-updated', () => this.loadFiles());
  }

  async loadFiles() {
    this.siteAssets = await this.siteAssetService.getSiteAssets(this.site.siteId);
  }

  async deleteSiteAsset(siteAsset: SiteAsset) {
    await this.siteAssetService.deleteSiteAsset(this.site.siteId, siteAsset.id);
    await this.loadFiles();
  }

  async showReplaceFileDialog(siteAsset: SiteAsset) {
    Modal.open({
      parent: this,
      component: UploadModal,
      hasModalCard: true,
      customClass: 'upload-modal',
      props: {
        siteId: this.site.siteId,
        existingSiteAsset: siteAsset || null
      },
      events: {
        fileUpload: (file: File, assetId: string) => this.replaceAsset(file, assetId)
      }
    });
  }

  async replaceAsset(file: File, assetId: string) {
    try {
      await this.siteAssetService.uploadFile(this.site.siteId, file, assetId);
      success(this, 'File replace', 'File was replaced successfully.');
      await this.loadFiles();
    } catch (e) {
      error(this, null, 'File replace', 'Error occured while trying to replace the site file.');
    }
  }

  async getPagesUseAsset(siteAsset: SiteAsset) {
    this.pagesUseAsset = await this.siteAssetService.getPagesUseAsset(
      this.site.siteId,
      siteAsset.id
    );
  }
}
</script>

<style lang="scss">
.action-icon {
  margin: 0 8px;
}
</style>
