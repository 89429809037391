<template>
  <div>
    <h1 class="title is-3">Available Themes</h1>
    <Theme></Theme>
    <form v-on:submit.prevent="saveTheme">
      <section>
        <b-field>
          <b-upload v-model="dropFiles" multiple drag-drop>
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"> </b-icon>
                </p>
                <p>Drop your files here or click to upload</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
          <span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
            {{ file.name }}
            <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
          </span>
        </div>
        <b-field label="Theme Name">
          <b-input v-model="themeName"></b-input>
        </b-field>
        <b-button class="field" native-type="submit" :disabled="disableSave">Add Theme</b-button>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { addTheme } from '@/services/site-content.service';
import { success, error } from '@/services/user-messages.service';
import Theme from '@/pages/content/Theme.vue';

@Component({
  components: { Theme }
})
export default class AddTheme extends Vue {
  public dropFiles: File[] = [];
  public themeName = '';

  public get disableSave(): boolean {
    return this.dropFiles.length === 0 || this.themeName === '';
  }

  public deleteDropFile(index: number) {
    this.dropFiles.splice(index, 1);
  }

  public async saveTheme() {
    try {
      await addTheme(this.themeName, this.dropFiles);
      success(this, 'Theme save', `${this.themeName} saved successfully.`);
    } catch (err) {
      error(this, err);
    }
  }
}
</script>
