<template>
  <div class="container">
    <h2 class="title">{{ adding ? 'Add' : 'Edit' }} Collection</h2>
    <form v-on:submit.prevent="save">
      <section>
        <b-field label="Name">
          <b-input type="text" v-model="collection.name" required></b-input>
        </b-field>
        <b-field label="Child Item name">
          <b-input type="text" v-model="collection.childItemName" required></b-input>
        </b-field>
        <b-field label="Path">
          <b-input type="text" v-model="collection.path" required></b-input>
        </b-field>
        <b-field label="Page layout">
          <b-select v-model="collection.template" placeholder="Select a layout" required expanded>
            <option v-for="template in templates" :key="template.id" :value="template.id">{{
              template.name
            }}</option>
          </b-select>
        </b-field>
      </section>
      <div class="buttons">
        <b-button type="is-primary" native-type="submit" :loading="loading"
          >Save Collection</b-button
        >
        <b-button type="is-light" tag="router-link" :to="{ name: 'site-editor' }">Cancel</b-button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SiteRouting } from '@/mixins/SiteRouting';
import { addOrUpdateCollection, getTemplates } from '@/services/site-content.service';
import { CollectionType, PageTemplate, SiteCollection } from '@/models/site-content';
import { error, success } from '@/services/user-messages.service';
import { Site } from '@/models/site';
import { emptyGuid } from '@/utilities/guid';

/**
 * Site content home component.
 */
@Component
export default class AddCollection extends mixins(SiteRouting) {
  /** Flag set to true if adding, false if updating */
  public adding = true;

  public loading = false;
  public templates: PageTemplate[] = [];

  public collection: SiteCollection = {
    id: 0,
    name: 'Blog',
    path: '/blog/',
    childItemName: 'post',
    type: CollectionType.UserGeneratedPost,
    childItemsCount: 0,
    lastUpdated: new Date().toISOString(),
    template: emptyGuid,
    inputs: []
  };

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadTemplates(site);
    });
  }

  async save() {
    try {
      this.loading = true;
      this.collection.id = await addOrUpdateCollection(this.site.siteId, this.collection);
      success(this, `${this.collection.name} saved`, 'Collection successfully created.');
      this.$router.push({ name: 'site-collections' });
    } catch (err) {
      error(this, err as Error, 'Unable to save collection');
    } finally {
      this.loading = false;
    }
  }

  private async loadTemplates(site: Site) {
    this.templates = await getTemplates(site.siteId);
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 600px;
}

.add-collection {
  font-size: 0.9em;
}

.collection {
  margin-bottom: 14px;
}
</style>
