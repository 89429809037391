import { emptyGuid } from '@/utilities/guid';

export enum TemplateInputType {
  Unknown = 0,

  /**
   * Text/string value
   */
  Text = 1,

  /**
   * Text with customizable label
   */
  LabelValue = 2,

  /**
   * EditorJS content (to be rendered as HTML)
   */
  EditorJs = 3,

  /**
   * Array of inputs. Array type can be any input type, rendered through recursion.
   */
  ArrayOfInputs = 4,

  /**
   * File can be any file type. Saved as an asset. Typically image or video.
   */
  File = 5,

  /**
   * Tags.
   */
  Tags = 6
}

/**
 * Site collection type.
 */
export enum CollectionType {
  Unknown = 0,
  /**
   * User-generated collections. Typically a blog.
   */
  UserGeneratedPost = 1,

  /**
   * Image or photo gallery.
   */
  ImageGallery = 2,

  // FUTURE IDEAS: social aggregators (recent instagram posts, FB posts, etc)
}

export interface TemplateInput {
  id: string;
  name: string;
  type: TemplateInputType;
  content: any;
  assetId?: string;
}

/**
 * A site page template.
 */
export interface PageTemplate {
  /** Guid ID */
  id: string;

  /** Name of template */
  name: string;

  /** Template inputs */
  inputs: TemplateInput[];
}

/**
 * Site page.
 */
export interface SitePage {
  /**
   * Page ID.
   * Integer-based, so only unique per site.
   */
  id: number;

  /** Page title */
  title: string;

  /** Deployed path of page (not including protocol or domain) */
  path: string;

  /** Page description. */
  description: string;

  /** Template ID of the page's layout */
  template: string;

  inputs: TemplateInput[];

  /** Page properties */
  properties: {
    /** Collection ID */
    collection?: number;
  };
}

/** New page created client-side. An ID will be assigned when it is saved to the backend. */
export type NewPage = Omit<SitePage, 'id'>;

export interface PageCreationProperties {
  /** Template */
  template: PageTemplate;
}

export interface SiteContent {
  pages: SitePage[];
}

export interface SiteCollection {
  id: number;

  name: string;

  /** Base URL path */
  path: string;

  /**
   * Name of child item (blog, post, announcement, coupon, etc)
   */
  childItemName: string;

  type: CollectionType;

  /** Count of child items. */
  childItemsCount: number;

  /**
   * Last time a child item was modified.
   */
  lastUpdated: string;

  /** Template ID */
  template: string;

  inputs: TemplateInput[];

  items?: SitePage[];
}

export const newCollection: SiteCollection = {
  id: 0,
  name: '',
  path: '',
  template: emptyGuid,
  childItemName: '',
  childItemsCount: 0,
  type: 0,
  lastUpdated: '',
  inputs: []
};

export interface Asset {
  id: string;

  name: string;

  /**
   * Date added, in ISO string.
   */
  added: string;
}

export interface SiteTheme {
  id: number;

  /**
   * Theme name.
   */
  name: string;

  /**
   * Images for this theme.
   */
  assetIds: string;
}
