<template>
  <div class="container">
    <h1 class="title">Store Items</h1>
    <div class="controls buttons is-pulled-right">
      <b-button
        tag="router-link"
        :to="{ siteName: site.siteName, name: 'add-store-item' }"
        size="is-small"
        icon-right="plus"
      />
      <b-button
        size="is-small"
        type="is-primary"
        :outlined="!search"
        @click="search = !search"
        icon-right="search"
      />
    </div>

    <b-table
      :data="items"
      :loading="loading"
      :paginated="true"
      :per-page="75"
      :debounce-search="200"
    >
      <b-table-column label="Title" field="title" v-slot="props" :searchable="search" sortable>
        {{ props.row.title }}
      </b-table-column>
      <b-table-column
        label="Description"
        field="description"
        v-slot="props"
        :searchable="search"
        sortable
      >
        {{ props.row.description }}
      </b-table-column>
      <b-table-column label="Price" field="price" v-slot="props" sortable>
        {{ props.row.price | currencyToString }}
        <router-link
          :to="{ name: 'edit-store-item', params: { siteName: site.siteName, id: props.row.id } }"
          >Edit</router-link
        >
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { error } from '@/services/user-messages.service';
import { getAllItems } from '@/services/site-store.service';
import { Site } from '@/models/site';
import { StoreItem } from '@/models/purchases';
import { SiteRouting } from '@/mixins/SiteRouting';

@Component
export default class StoreItems extends mixins(SiteRouting) {
  public loading = true;
  public items: StoreItem[] = [];
  public search = false;
  public readonly itemColumns = [
    {
      field: 'title',
      label: 'Title',
      sortable: true
    },
    {
      field: 'description',
      label: 'Description',
      sortable: true
    },
    {
      field: 'price',
      label: 'Price',
      numeric: true,
      sortable: true
    }
  ];

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadItems(site);
    });
  }

  public async loadItems(site: Site) {
    try {
      this.loading = true;
      this.items = await getAllItems(site.siteId);
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
div.controls {
  margin-top: -3em;
  margin-bottom: 5px;
}
</style>
