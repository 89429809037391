import axios from '@/utilities/axios';
import { User, SiteUser, Address } from '@/models/user';
import { SiteRole } from '@/models/site';

/**
 * Get current signed in user.
 * Returns null if user not signed in.
 */
export async function getCurrentUser(): Promise<User | null> {
  try {
    const { data } = await axios.get<User>('account');
    return data;
  } catch (error) {
    return null;
  }
}

export async function getAll(): Promise<User[]> {
  const response = await axios.get<User[]>('account/all');
  return response.data;
}

/**
 * Get all users for a site.
 * @param siteId Site ID
 */
export async function getAllBySite(siteId: string): Promise<SiteUser[]> {
  const response = await axios.get<SiteUser[]>(`sites/${siteId}/users`);
  return response.data;
}

export async function getUser(userId: string): Promise<User> {
  const users = await getAll();
  return users.find(u => u.id === userId) as User;
}

/**
 * Create a new user
 */
export function addUser(user: User) {
  return axios.post('account', user);
}

/**
 * Add user to a site
 */
export function addUserToSite(siteId: string, email: string, role: SiteRole) {
  return axios.post(`sites/${siteId}/users`, {
    email,
    role
  });
}

/**
 * Remove a user's permission from a site
 * @param siteId Site ID
 * @param userId User ID
 */
export function removeUserFromSite(siteId: string, userId: string) {
  return axios.delete(`sites/${siteId}/users/${userId}`);
}

/**
 * Update an existing user
 */
export function updateUser(user: User) {
  return axios.post('account/update', user);
}

export async function getUserAddress(): Promise<Address> {
  const address = await axios.get<Address>('account/address');
  return address.data;
}

export function updateUserAddress(address: Address): Promise<void> {
  return axios.post('account/address', address);
}

export async function updateUserImage(file: File): Promise<string> {
  const formData = new FormData();
  formData.append('image', file, 'user-image.jpg');
  const response = await axios.post<string>('account/image', formData);
  return response.data;
}
