<template>
  <div class="container">
    <h1>Reset Password</h1>
    <form v-on:submit.prevent="resetPassword">
      <section>
        <b-field label="New Password">
          <b-input type="password" v-model="password" required></b-input>
        </b-field>
        <b-field label="Confirm Password">
          <b-input type="password" v-model="passwordConfirmed" required></b-input>
        </b-field>
        <b-button type="is-primary" native-type="submit" :loading="submitting">Save New Password</b-button>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { resetPassword as requestPasswordReset } from '@/services/auth.service';
import { success, error } from '@/services/user-messages.service';

@Component
export default class ResetPassword extends Vue {
  public password = '';
  public passwordConfirmed = '';
  private resetToken = '';
  public submitting = false;

  public async resetPassword() {
    try {
      if (this.password !== this.passwordConfirmed) {
        error(this, null, 'Verify New Password', 'Passwords do not match.');
        return;
      }
      this.submitting = true;
      await requestPasswordReset(
        this.resetToken,
        this.password,
        this.passwordConfirmed
      );
      success(this, 'Password Reset', 'Password successfully reset.');
      this.$router.push('/login');
    } catch (err) {
      error(
        this,
        err as Error,
        'Password Reset Failed',
        'Password reset token no longer valid.'
      );
    } finally {
      this.submitting = false;
    }
  }

  public mounted() {
    this.resetToken = this.$route.params.token;
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 500px;
}
</style>
