<template>
  <div>
    <h1>Send User Message</h1>
    <form v-on:submit.prevent>
      <b-field label="User to message">
        <b-autocomplete
          v-model="searchedUser"
          :data="filteredUsers"
          :custom-formatter="userToString"
          required
          icon="user"
          keep-first
          @select="option => (user = option)"
        >
          <template slot="empty">No user found.</template>
          <template slot-scope="props"
            >{{ props.option.firstName }} {{ props.option.lastName }} ({{
              props.option.email
            }})</template
          >
        </b-autocomplete>
      </b-field>
      <b-field label="Message">
        <b-input maxlength="200" type="textarea" v-model="message.message"></b-input>
      </b-field>
      <p>{{ status }}</p>
      <b-button type="is-primary" @click="sendMessage" :loading="loading">Send Message</b-button>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { v4 as uuid } from 'uuid';
import { error } from '@/services/user-messages.service';
import { getAll } from '@/services/users.service';
import { User, UserMessage, UserMessageType } from '@/models/user';
import app from '@/store/modules/app';

@Component
export default class SendUserMessage extends Vue {
  public user!: User;
  public users: User[] = [];
  public searchedUser = '';

  public loading = true;

  public message: UserMessage;

  public status = '';

  constructor() {
    super();
    this.message = {
      id: '',
      type: UserMessageType.Info,
      title: '',
      message: 'Message here.'
    } as UserMessage;
  }

  public mounted() {
    this.loadUsers();
  }

  public async loadUsers() {
    try {
      this.loading = true;
      this.users = await getAll();
    } catch (err) {
      error(this, err);
    } finally {
      this.loading = false;
    }
  }

  public async sendMessage() {
    try {
      this.loading = true;
      this.message.id = uuid();
      await app.hub.sendUserMessage(this.user.id, this.message);
      this.status = '✔ Message sent.';
      this.message.message = '';
    } catch (err) {
      this.status = 'Failed to send.';
      error(this, err, 'Error sending message.', (err as Error).message);
    } finally {
      this.loading = false;
    }
  }

  get filteredUsers(): User[] {
    const searchTerm = this.searchedUser.toLowerCase();
    return this.users.filter(user => {
      const name = `${user.firstName} ${user.lastName}`;
      return name.toLowerCase().indexOf(searchTerm) >= 0;
    });
  }

  public userToString(user: User) {
    return `${user.firstName} ${user.lastName} | ${user.email}`;
  }
}
</script>

<style lang="scss" scoped>
tr:hover {
  .process {
    visibility: visible;
  }
}

.process {
  visibility: hidden;
}
</style>
