<template>
  <div class="container">
    <h2 class="title">{{ pageTitle }}</h2>
    <form v-on:submit.prevent="savePage">
      <section>
        <div class="details">
          <b-field label="Title">
            <b-input type="text" v-model="page.title" required @input="titleUpdated" />
          </b-field>
          <b-field label="Description">
            <b-input type="text" v-model="page.description" required @input="pageContentChanged" />
          </b-field>
          <b-field label="Url">
            <b-input
              disabled
              type="text"
              v-model="page.path"
              required
              @input="pageContentChanged"
            />
          </b-field>

          <template-input
            v-for="input in page.inputs"
            :key="input.id"
            :type="input.type"
            :value="input"
            @change="pageContentChanged"
          />
        </div>
        <div />
      </section>
      <div class="buttons">
        <a class="button" :disabled="!previewUrl.base" :href="previewUrl.url" target="_blank"
          >Preview {{ collection.childItemName }}</a
        >
        <b-button type="is-primary" :loading="loading || publishing" @click="publish"
          >Publish {{ collection.childItemName }}</b-button
        >
        <b-button type="is-light" tag="router-link" :to="{ name: 'site-collections' }"
          >Cancel</b-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { v4 as uuid } from 'uuid';
import urlJoin from 'url-join';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import TemplateInputComponent from '@/components/TemplateInput.vue';
import NewPageProperties, { PagePropertiesSelected } from '@/components/NewPageProperties.vue';
import { SiteRouting } from '@/mixins/SiteRouting';
import { addOrUpdatePage, getCollection, getPage } from '@/services/site-content.service';
import { error } from '@/services/user-messages.service';
import {
  PageCreationProperties,
  SiteCollection,
  SitePage,
  TemplateInput
} from '@/models/site-content';
import { LarkHub } from '@/services/lark.hub';
import { getEditorUrl } from '@/services/site-editor.service';
import { emptyGuid } from '@/utilities/guid';
import app from '@/store/modules/app';
import { setPageTitle } from '@/utilities/helpers';

@Component({
  components: {
    'template-input': TemplateInputComponent
  }
})
export default class EditPage extends mixins(SiteRouting) {
  @Prop({
    type: Object,
    default: () => {
      return {
        nothing: true
      };
    }
  })
  public readonly options: PageCreationProperties | undefined;

  @Prop(String)
  public readonly id: string | undefined;

  @Prop(Number)
  public readonly collectionId: number | undefined;

  public collection: SiteCollection | false = false;

  /** Preview URL of the post */
  public previewUrl = {
    base: '',
    url: ''
  };

  public words = {
    verb: 'Add',
    item: 'post'
  };

  /** `true` if adding a new page */
  public newPage = true;

  public loading = false;

  /** Flag set to `true` if a site publish is currently in progress */
  public publishing = false;

  public page: SitePage = {
    id: 0,
    path: 'new-post',
    title: 'New Post',
    description: '',
    template: emptyGuid,
    inputs: [],
    properties: {}
  };

  public get pageTitle() {
    const verb = this.newPage ? 'Add' : 'Edit';
    const item = this.collection ? this.collection.childItemName : 'Post';
    return `${this.words.verb} ${this.words.item}`;
  }

  private saver = {
    lastModified: new Date(),
    lastSaved: new Date(),
    timer: 0,
    saving: false
  };

  private larkHub: LarkHub;

  constructor() {
    super();
    this.larkHub = app.hub;
  }

  editorSessionStarted(sessionId: string) {
    this.previewUrl.base = getEditorUrl(sessionId);
    this.setPreviewUrl();
  }

  public async mounted() {
    const session = this.larkHub.getSiteSession(this.site.siteId);
    this.larkHub.sessionStart.subscribe((hub, s) => {
      this.editorSessionStarted(s.sessionId);
    });
    this.larkHub.sitePublishCompleted.subscribe((_, msg) => {
      // publish finished, hide spinner
      this.publishing = false;
    });
    if (!session) {
      this.larkHub.startSession(this.site.siteId);
    }
    let colPromise: Promise<SiteCollection | undefined> | null = null;
    if (this.options) {
      // if collection ID selected, fetch details
      if (this.collectionId) {
        this.page.properties.collection = this.collectionId;
        colPromise = this.loadCollection(this.page.properties.collection);
      }
      if (this.options.template) {
        this.setPageInputs(this.options.template.inputs);
      }
    }

    if (this.id) {
      this.newPage = false;
      const promises: Promise<unknown>[] = [this.loadPage(this.id)];
      if (colPromise) {
        promises.push(colPromise);
      }
      await Promise.all(promises);
      this.setPreviewUrl();
      return;
    }

    // give new page an 0 id, it will be generated server-side
    this.page.id = 0;

    if (colPromise) {
      // it's a new page, and it's part of a collection
      const collection = await colPromise;
      const inputs = collection?.inputs || [];
      this.setPageInputs(inputs);
    } else {
      this.showTemplateSelection();
    }

    this.titleUpdated(true);
  }

  /**
   * Save the page before the user navigates away.
   */
  public beforeRouteLeave(to: any, from: any, next: () => void) {
    this.savePage();
    next();
  }

  private setPageInputs(inputs: TemplateInput[]) {
    inputs.forEach((input) => {
      if (input.id === emptyGuid) {
        input.id = uuid();
      }
    });
    this.page.inputs = inputs;
  }

  private showTemplateSelection() {
    const modal = this.$buefy.modal.open({
      parent: this,
      component: NewPageProperties,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      events: {
        propertiesSelected: this.setPageProperties
      }
    });
  }

  private setPageProperties(props: PageCreationProperties) {
    this.page.template = props.template.id;
    this.setPageInputs(props.template.inputs);
  }

  private setPreviewUrl() {
    this.previewUrl.url = urlJoin(this.previewUrl.base, this.page.path);
  }

  private async loadCollection(collectionId: number) {
    if (collectionId) {
      const collection = await getCollection(this.site.siteId, collectionId);
      if (collection) {
        this.collection = collection;
        this.words.item = this.collection.childItemName;
        setPageTitle(`Edit ${collection.childItemName}`);
        return collection;
      }
    }
    this.loading = false;
    return undefined;
  }

  private pageContentChanged() {
    this.saver.lastModified = new Date();
    if (!this.saver.timer) {
      this.saver.timer = window.setTimeout(this.savePage.bind(this), 4500);
    }
  }

  private async loadPage(id: string) {
    const page = await getPage(this.site.siteId, id);
    this.page = page;
    return page;
  }

  private resetAutoSaveTimer() {
    window.clearTimeout(this.saver.timer);
    this.saver.timer = 0;
  }

  private titleUpdated(initialLoad = false) {
    this.page.path = this.buildPagePath(this.page.title);
    this.setPreviewUrl();
    if (!initialLoad) {
      this.pageContentChanged();
    }
  }

  private buildPagePath(title: string): string {
    let pageUrl = title.replaceAll(' ', '-').toLowerCase();
    if (this.collection) {
      pageUrl = this.collection.path + pageUrl;
    } else {
      pageUrl = `/${pageUrl}`;
    }
    return pageUrl;
  }

  async savePage() {
    try {
      this.resetAutoSaveTimer();
      const localSaveDate = new Date();
      const pageId = await addOrUpdatePage(this.site.siteId, this.page);
      this.saver.lastSaved = localSaveDate;
      if (pageId !== this.page.id) {
        // new page was saved and an ID was generated
        this.page.id = pageId;
        this.$router.push({
          name: 'edit-page',
          params: {
            id: pageId.toString(), // vue router props should be strings
            siteName: this.siteName!
          }
        });
      }
    } catch (err) {
      error(this, err as Error, 'Error adding page', 'Unable to add new page, please try again.');
    }
  }

  publish() {
    this.publishing = true;
    this.larkHub.deploySite(this.site.siteId);
  }
}
</script>

<style lang="scss">
.button {
  margin-top: 10px;
}

.details {
  .title.post-title {
    margin-bottom: 0;
  }

  .edit-details {
    padding: 0.7em 1em 1.2em 1em;
    border-radius: 0.3em;

    .columns {
      margin-bottom: 0;
    }

    .image-upload {
      max-width: 450px;
    }
  }
}
</style>
