import { StoreItem } from './purchases';

/**
 * Domain available to purchase
 */
export interface AvailableDomain {
  /** Domain name, including TLD */
  domain: string;

  /** Availability status */
  available: boolean;

  /** Premium status. Premium domains are available to buy in the resellers market. */
  premium: boolean;

  registration?: StoreItem;
}

/** An owned domain */
export interface Domain {
  /** Domain name */
  name: string;

  /** Date added on */
  added: Date;

  /** Date expires on */
  expires: string;

  /** One-year renewal for adding to cart */
  renewal?: StoreItem;
}

export interface DomainTransfer {
  userId: string;

  domainName: string;

  state: TransferDomainState;

  transfer?: StoreItem;
}

export enum TransferDomainState {
  Unknown = 0,

    /**
   * waiting on payment
   */
  PaymentPending = 1,

      /**
   * waiting on transfer
   */
  InProgress = 2,

      /**
   * domain transferred
   */
  Complete = 3,

      /**
   * error during transfer
   */
  Error,

      /**
   * Cancelled
   */
  Cancelled
}

export interface DomainRegistrationInfo {
  tlds: string[];

  subscriptionPlan: StoreItem;
}
