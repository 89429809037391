<template>
  <div>
    <h1>New Page Properties</h1>
    <form v-on:submit.prevent="selectProperties">
      <b-field label="Page layout">
        <b-select v-model="selectedTemplate" placeholder="Select a layout" required expanded>
          <option v-for="template in templates" :key="template.id" :value="template">{{
            template.name
          }}</option>
        </b-select>
      </b-field>
      <div class="buttons">
        <b-button type="is-primary" native-type="submit"
          >Continue</b-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { PageCreationProperties, PageTemplate } from '@/models/site-content';
import { getTemplates } from '@/services/site-content.service';
import app from '@/store/modules/app';

/** Event name emitted when properties are selected. */
export const PagePropertiesSelected = 'propertiesSelected';

@Component
export default class NewPageProperties extends Vue {
  /** Selected template ID */
  public selectedTemplate: PageTemplate = {} as PageTemplate;
  public templates: PageTemplate[] = [];

  async mounted() {
    this.templates = await getTemplates(app.site!.siteId);
  }

  selectProperties() {
    this.$emit(PagePropertiesSelected, {
      template: this.selectedTemplate
    } as PageCreationProperties);
    this.$emit('close');
  }
}
</script>

<style scoped lang="scss">
div {
  background-color: lightcyan;
}
</style>
