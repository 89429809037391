<template>
  <div class="container">
    <h1 class="title">Your Cart</h1>
    <div class="cart" v-if="cart.items.length">
      <div class="columns">
        <div class="column">
          <div class="cart-item" v-for="item in cart.items" :key="item.id">
            <div class="columns">
              <div class="column">
                <div class="title is-6">{{ getItemTitle(item) }}</div>
                <div class="is-size-7">{{ item.description }}</div>
                <div class="action-row">
                  <b-button type="is-text is-size-7" v-on:click="removeFromCart(item)"
                    >Remove</b-button
                  >
                </div>
              </div>
              <div class="column">{{ item.price | currencyToString }}</div>
            </div>
          </div>
        </div>
        <div class="column summary">
          <div class="total" v-if="cart.hasCartItems">
            <span class="title is-5 has-text-grey">Cart total</span>
            <span class="is-pulled-right has-text-right">{{
              cart.cartTotal | currencyToString
            }}</span>
            <div class="ml-4">
              {{ $pluralize('item', cart.cartItems.length, true) }}
            </div>
          </div>
          <div class="total">
            <span class="title is-5 has-text-grey" v-if="cart.hasSubscriptions">Subscriptions</span>
            <div class="ml-4">
              <div class="columns" v-for="sub in cart.subscriptions" :key="sub.id">
                <div class="column">{{ sub.title }}</div>
                <div class="column has-text-right">
                  {{ sub.price | currencyToString }}
                  <div class="is-size-7">per {{ sub.properties.interval }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="total cumulative" v-if="cart.hasCartItems && cart.hasSubscriptions">
            <div>
              <span class="title is-4 mr-0">Total</span>
              <span class="title is-4 is-pulled-right has-text-right mr-0">{{
                cart.total | currencyToString
              }}</span>
            </div>
            <p class="is-size-7">
              Cart items and subscriptions will show as separate charges on your card.
            </p>
          </div>
          <div>
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <div class="cart" v-else>
      <h4>
        You have no items in your cart. Try
        <router-link :to="{ name: 'add-domain' }">registering a domain</router-link> or
        <router-link :to="{ name: 'add-plan' }">signing up for a site plan.</router-link>
      </h4>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ShoppingCart, StoreItem, CartItem } from '@/models/purchases';
import cart from '@/store/modules/cart';

/**
 * Shopping cart.
 */
@Component
export default class Cart extends Vue {
  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }

  getItemTitle(item: CartItem) {
    if (item.properties && item.properties.domain) {
      return item.properties.domain;
    }
    return item.title;
  }

  removeFromCart(item: StoreItem) {
    cart.removeItemFromCart(item);
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/bulma-variables';

.summary {
  max-width: 400px;
}

.cart-item {
  margin-bottom: 10px;
  padding: 0.5em 2em;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
  max-width: 800px;

  &:hover {
    border-color: $white-ter;
  }

  .title {
    margin-bottom: 0;
  }

  .action-row {
    padding: 0.1em 1em;
  }
}

.total {
  margin-bottom: 1em;

  &.cumulative {
    margin-top: 3em;
  }

  .title {
    margin-right: 1em;
  }
}
</style>
