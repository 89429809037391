<template>
  <div>
    <div class="columns">
      <div class="column">
        <header-with-info :message="i18n.topPagesDescription" position="is-right">{{
          i18n.topPages
        }}</header-with-info>
        <b-table :data="analytics.pageStats.slice(0, 5)" :loading="loading" :mobile-cards="false">
          <b-table-column label="Page" v-slot="props" cell-class="path">
            {{ props.row.path }}
            <a :href="`https://${site.siteName}${props.row.path}`" target="_blank" aria-label="View page">
              <font-awesome-icon class="fa-xs" icon="external-link"></font-awesome-icon>
            </a>
          </b-table-column>
          <b-table-column label="Visitors" v-slot="props">
            {{ props.row.visitors | numberToString }}
          </b-table-column>
          <b-table-column label="Views" v-slot="props">
            {{ props.row.views | numberToString }}
          </b-table-column>
        </b-table>
      </div>
      <div class="column is-half">
        <header-with-info :message="i18n.topPageViewsDescription">{{
          i18n.topPageViews
        }}</header-with-info>
        <canvas class="pie" ref="pageViewPie"></canvas>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <header-with-info
          :message="`Sites that referred traffic ${i18n.timeRange}, shown by site visitor count.`"
          position="is-right"
          >Traffic sources</header-with-info
        >
        <canvas class="pie" ref="trafficSourcesPieChart"></canvas>
      </div>
      <div class="column">
        <header-with-info :message="`Daily visitors ${i18n.timeRange}.`"
          >Daily visitors</header-with-info
        >
        <canvas ref="trafficLineChart"></canvas>
      </div>
    </div>
    <div class="columns">
      <div class="column is-half">
        <header-with-info :message="`Site load speed ${i18n.timeRange}.`" position="is-right"
          >Site load time</header-with-info
        >
        <canvas ref="siteLoadTimeLineChart"></canvas>
      </div>
      <div class="column is-half">
        <header-with-info :message="`Approximate location of users ${i18n.timeRange}.`"
          >Traffic map</header-with-info
        >
        <geo-map ref="map" :geolocations="analytics.geolocations"></geo-map>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Chart, ChartConfiguration, ChartTypeRegistry, TooltipItem } from 'chart.js';

import { Component, Vue, Prop } from 'vue-property-decorator';
import Geomap from '@/components/GeoMap.vue';
import HeaderWithInfo from '@/components/HeaderWithInfo.vue';
import { Summary, ReportText } from '@/models/analytics';
import { themePalette, theme } from '@/utilities/colors';
import { getSummary, getSummaryByRange } from '@/services/analytics.service';
import { Site } from '@/models/site';

@Component({
  components: {
    'geo-map': Geomap,
    'header-with-info': HeaderWithInfo
  }
})
export default class SiteAnalyticsReport extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        topPages: "Yesterday's top pages",
        topPagesDescription: "Yesterday's most popular pages.",
        topPageViews: "Yesterday's views per page",
        topPageViewsDescription: 'Site views from yesterday, grouped by page.',
        timeRange: 'in the past month'
      };
    }
  })
  i18n!: ReportText;

  public site!: Site;

  public analytics: Summary = {
    pageStats: [],
    dailyStats: [],
    dailyPerformance: [],
    trafficSources: [],
    geolocations: []
  };
  public loading = true;

  public $refs!: {
    /** Div container for page view pie chart */
    pageViewPie: HTMLCanvasElement;
    trafficSourcesPieChart: HTMLCanvasElement;
    trafficLineChart: HTMLCanvasElement;
    siteLoadTimeLineChart: HTMLCanvasElement;
    svg: SVGElement;
    tooltip: HTMLDivElement;
    map: Geomap;
  };

  // Have to use any hear cause I can't get an array of different chart types to work.
  // Revisit when we upgrade Chart.js versions.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private charts: { [key: string]: Chart<any> } = {};

  async loadReport(site: Site, dates: Date[] = []) {
    try {
      this.site = site;
      if (!site?.siteId) {
        // active site not yet set
        return;
      }
      this.loading = true;
      if (dates.length > 1) {
        // use start/end dates
        this.analytics = await getSummaryByRange(site.siteId, dates[0], dates[1]);
      } else {
        // no dates, return dashboard defaults
        this.analytics = await getSummary(site.siteId);
      }
      this.drawPageViewPieChart();
      this.drawTrafficSourcesPieChart();
      this.drawTrafficLineChart();
      this.drawSiteLoadTimeLineChart();
      this.drawLocationsOnMap();
    } finally {
      this.loading = false;
    }
  }

  drawPageViewPieChart() {
    const canvas = this.$refs.pageViewPie?.getContext('2d') as CanvasRenderingContext2D;
    if (!canvas) {
      // canvas not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }
    const pageNames = this.analytics.pageStats.map((s) => s.path);
    const pageViews = this.analytics.pageStats.map((s) => s.views);
    const existingChart = this.charts.pageViewPie;
    if (existingChart) {
      // chart already initialized, only need to update data
      existingChart.data.labels = pageNames;
      existingChart.data.datasets![0].data = pageViews;
      existingChart.update();
      return;
    }

    this.charts.pageViewPie = new Chart(canvas, {
      type: 'pie',
      data: {
        labels: pageNames,
        datasets: [
          {
            data: pageViews,
            backgroundColor: themePalette,
            borderWidth: 1
          }
        ]
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem: TooltipItem<'pie'>) =>
                ` ${tooltipItem.label} · ${tooltipItem.parsed} views`
            }
          }
        }
      }
    } satisfies ChartConfiguration<'pie', number[], string>);
  }

  drawTrafficSourcesPieChart() {
    const canvas = this.$refs.trafficSourcesPieChart?.getContext('2d') as CanvasRenderingContext2D;
    if (!canvas) {
      // canvas not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }

    const sources = this.analytics.trafficSources.map((s) => s.source);
    const visitors = this.analytics.trafficSources.map((s) => s.visitors);
    const existingChart = this.charts.trafficPie;
    if (existingChart) {
      // chart already initialized, only need to update data
      existingChart.data.labels = sources;
      existingChart.data.datasets![0].data = visitors;
      existingChart.update();
      return;
    }
    this.charts.trafficPie = new Chart(canvas, {
      type: 'pie',
      data: {
        labels: sources,
        datasets: [
          {
            data: visitors,
            backgroundColor: themePalette,
            borderWidth: 1
          }
        ]
      }
    } satisfies ChartConfiguration);
  };

  drawTrafficLineChart() {
    const canvas = this.$refs.trafficLineChart?.getContext('2d') as CanvasRenderingContext2D;
    if (!canvas) {
      // canvas not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }

    const trafficData = this.analytics.dailyStats.map((s) => ({
      x: s.date,
      y: s.visitors
    }));
    const existingChart = this.charts.trafficLine;
    if (existingChart) {
      // chart already initialized, only need to update data
      existingChart.data.datasets![0].data = trafficData as any;
      existingChart.update();
      return;
    }
    this.charts.trafficLine = new Chart(canvas, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Visitors',
            backgroundColor: theme.darkPrimary,
            borderColor: theme.primary,
            fill: false,
            data: trafficData as any
          }
        ]
      },
      options: {
        scales: {
          x: {
            type: 'time' as any,
            time: {
              displayFormats: {
                millisecond: 'MMM dd',
                second: 'MMM dd',
                minute: 'MMM dd',
                hour: 'MMM dd',
                day: 'MMM dd',
                week: 'MMM dd',
                month: 'MMM dd',
                quarter: 'MMM dd',
                year: 'MMM dd'
              },
              tooltipFormat: 'MMM dd',
              unit: 'day'
            },
            // maxTicksLimit: 8,
            ticks: {
              autoSkip: true
            }
          },
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            mode: 'point'
          }
        }
      }
    });
  }

  drawSiteLoadTimeLineChart() {
    const canvas = this.$refs.siteLoadTimeLineChart?.getContext('2d') as CanvasRenderingContext2D;
    if (!canvas) {
      // canvas not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }

    const loadTimes = this.analytics.dailyPerformance.map((s) => ({
      x: s.date,
      y: s.loadTime
    }));
    const existingChart = this.charts.loadTimeLine;
    if (existingChart) {
      // chart already initialized, only need to update data
      existingChart.data.datasets![0].data = loadTimes as any;
      existingChart.update();
      return;
    }
    this.charts.loadTimeLine = new Chart(canvas, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Load time',
            backgroundColor: theme.darkPrimary,
            borderColor: theme.primary,
            fill: false,
            data: loadTimes as any
          }
        ]
      },
      options: {
        scales: {
          x: {
            type: 'time' as any,
            time: {
              displayFormats: {
                hour: 'MMM dd',
                day: 'MMM dd'
              },
              tooltipFormat: 'MMM dd',
              unit: 'day'
            },
            // maxTicksLimit: 8,
            ticks: {
              autoSkip: true
            }
          },
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            intersect: true,
            mode: 'x',
            callbacks: {
              label: (tooltipItem: TooltipItem<'line'>) => ` ${tooltipItem.formattedValue} seconds`
            }
          }
        }
      }
    });
  }

  drawLocationsOnMap() {
    const map = this.$refs.map;
    if (!map) {
      // map not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }

    map.drawGeolocations(this.analytics.geolocations);
  }
}
</script>

<style lang="scss">
/* Unscoped, global styles */

td.path {
  a {
    display: inline-flex;
    align-items: center;
    margin-left: 0.5em;
    font-size: 0.85em;
    padding: 0.2em;
  }
}
</style>

<style scoped lang="scss">
.pie {
  max-height: 15em;
}

.columns {
  margin-bottom: 2.75em !important;
}
</style>
