import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { Site } from '@/models/site';
import app from '@/store/modules/app';

/**
 * SiteRouting exposes functionality to add site routing to any page.
 * Allows for /sites/domain.com and /settings/domain.com
 */
@Component
export class SiteRouting extends Vue {
  /** Site name to load (from URL segment) */
  @Prop(String) readonly siteName: string | undefined;

  get site(): Site {
    return app.site as Site;
  }

  get sites(): Site[] {
    return app.sites;
  }

  /** Function called when active site is updated */
  private siteUpdated(site: Site) {
    this.$emit('site-updated', site);
  }

  mounted() {
    if (this.siteName) {
      // if site name is set in URL, set active site for the app
      const siteByDomain = this.sites.find(s => s.siteName === this.siteName);
      if (siteByDomain) {
        if (siteByDomain === this.site) {
          // site already active
          this.siteUpdated(this.site);
        }
        app.setActiveSite(siteByDomain);
        return;
      }
    }
    if (this.site) {
      // update URL with current active site (the default site)
      this.setSiteNameUrl(this.site.siteName);
      this.siteUpdated(this.site);
    }
  }

  @Watch('site')
  private activeSiteChanged(site: Site, previousSite: Site) {
    if (site) {
      this.setSiteNameUrl(site.siteName, true);
      this.siteUpdated(site);
    }
  }

  /**
   * Set site domain name in URL
   * @param siteName Domain name
   * @param force Force domain URL change
   */
  private setSiteNameUrl(siteName: string, force = false) {
    // if site name not set yet, update URL
    if (!this.siteName || force) {
      if (this.siteName === siteName) {
        // verify URL is not already set
        return;
      }
      const updatedRoute: RawLocation = {
        name: undefined,
        params: { siteName },
        query: this.$route.query
      };
      if (this.$route.name) {
        updatedRoute.name = this.$route.name;
      }
      this.$router.push(updatedRoute);
    }
  }
}
