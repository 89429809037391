<template>
  <div class="container">
    <h1 class="title">Receipts</h1>
    <b-table v-if="!noneToShow" :data="orders" :loading="loading">
      <b-table-column field="orderId" label="Order" v-slot="props">
        <router-link :to="{ name: 'user-order', params: { invoiceId: props.row.id } }">
          {{ props.row.orderId }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props">
        <b-tag v-if="!props.row.paid" type="is-info">Payment needed</b-tag>
      </b-table-column>
      <b-table-column field="added" label="Amount" v-slot="props">{{
        props.row.total | currencyToString
      }}</b-table-column>
      <b-table-column field="created" label="Order Date" v-slot="props">
        {{ props.row.created | toDate }}
      </b-table-column>
    </b-table>
    <p v-else>
      You haven't made any purchases. Why not
      <router-link :to="{ name: 'add-domain' }">buy a domain</router-link> and get your very own
      site started?
    </p>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { Invoice } from '@/models/purchases';
import { getUserOrders } from '@/services/payments.service';

@Component
export default class UserReceipts extends Vue {
  public loading = false;

  /** If `true`, no receipts to show so table is hidden and friendly message displayed. */
  public noneToShow = false;

  /** User orders. */
  public orders: Invoice[] = [];

  mounted() {
    this.loadOrders();
  }

  public async loadOrders() {
    try {
      this.loading = true;
      this.orders = await getUserOrders();
      this.noneToShow = (this.orders.length === 0);
    } catch (err) {
      error(
        this,
        err,
        'Receipts Unavailable',
        'Viewing receipts is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin-top: 1em;
}

table {
  td {
    vertical-align: middle;
  }
}

a.button {
  margin-top: 1.5em;
}

div.continue {
  display: flex;
  align-items: center;
  margin: 1.5em 0;

  span {
    margin-right: 1.5em;
  }
}
</style>
