import { emptyGuid } from '@/utilities/guid';
import { SiteRole } from './site';
import { ShoppingCart } from './purchases';

export interface Address {
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  phoneNumber: string;
  countryCallingCode: string;
}

/** Lone Lark User */
export interface User {
  /** Guid ID of the user */
  id: string;
  /** User first name */
  firstName: string;
  /** User last name */
  lastName: string;
  /** Email used for login and to receive notifications */
  email: string;

  cart?: ShoppingCart;

  /** A Lone Lark site admin */
  larkAdmin?: boolean;

  /** only set when changing password */
  password?: string;

  sites: {
    siteId: string;
    siteName: string;
    role: SiteRole;
  }[];

  address?: Address;
}

/**
 * An invited user.
 * User information is incomplete, since they have not setup their account yet.
 */
export interface InvitedUser {
  /** Guid ID of the user */
  id: string;
  /** User email address */
  email: string;
}

export interface SiteUser {
  /** Guid ID of the user */
  id: string;
  /** User first name */
  firstName: string;
  /** User last name */
  lastName: string;
  /** Email used for login and to receive notifications */
  email: string;
  /** Role for a specific site */
  role: SiteRole;
}

export function newAddress(): Address {
  return {
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
    phoneNumber: '',
    countryCallingCode: ''
  } as Address;
}

export function newUser(): User {
  return {
    id: emptyGuid,
    firstName: '',
    lastName: '',
    email: '',
    sites: [],
    address: newAddress()
  };
}

export interface UserSummary {
  /** Guid ID of the user */
  id: string;
  /** User name */
  name: string;
  /** ID of profile image */
  profileImage: string;
}

export enum UserMessageType {
  Info = 1,
  Warning = 2,
  Error = 3,
  InProgress = 4,
  Success = 5
}

export interface UserMessage {
  /**
   * Message ID.
   */
  id: string;

  /** Message sent date. */
  date: string;

  /**
   * Message title.
   */
  title: string;

  /**
   * Message body.
   */
  message: string;

  /**
   * User message type
   */
  type: UserMessageType;

  /** Flag, set to `true` if unread. */
  unread?: boolean;
}
