import { render, staticRenderFns } from "./AddPlan.vue?vue&type=template&id=6f2a36ce&scoped=true&"
import script from "./AddPlan.vue?vue&type=script&lang=ts&"
export * from "./AddPlan.vue?vue&type=script&lang=ts&"
import style0 from "./AddPlan.vue?vue&type=style&index=0&id=6f2a36ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2a36ce",
  null
  
)

export default component.exports