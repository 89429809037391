<template>
  <div class="container">
    <h1 class="title">Domains</h1>
    <div v-if="noDomains">
      You currently do not own any domains. Add a domain today to get started!
    </div>
    <b-table v-if="!noDomains" :data="domains" :loading="loading">
      <b-table-column field="name" label="Domain" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column field="added" label="Added On" v-slot="props">
        {{ props.row.added | toDate }}
      </b-table-column>
      <b-table-column field="expires" label="Expires" v-slot="props">
        {{ props.row.expires | toDate }}
      </b-table-column>
      <b-table-column v-slot="props">
        <div v-if="props.row.renewal">
          <div class="action-row" v-if="inCart(props.row.name)">
            <span class="has-text-success">
              <font-awesome-icon icon="check-circle" />Added to cart
            </span>
            <router-link :to="{ name: 'checkout' }" class="button is-primary">
              Proceed to checkout
            </router-link>
          </div>
          <b-button type="is-primary" v-on:click="addToCart(props.row)" v-else>
            <strong>Renew</strong> for {{ props.row.renewal.price | currencyToString }}
          </b-button>
        </div>
      </b-table-column>
    </b-table>
    <div class="buttons">
      <b-button tag="router-link" :to="{ name: 'add-domain' }">Add domain</b-button>
      <b-button tag="router-link" :to="{ name: 'transfer-domain' }">Transfer domain</b-button>
    </div>
    <div class="cost-explanation">
      <h4 class="is-size-5">FAQs</h4>
      <h6>How are domain registration/renewal costs calculated?</h6>
      <p>
        Domain registration and renewal fees are set by
        <a href="https://www.icann.org/" target="_blank" rel="noopener noreferrer">ICANN</a> and
        domain registrars. Lone Lark does not make any money on domain transactions. In fact, we
        even lose a little! But it's worth it in our mission to make websites affordable to all.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { subMonths, parseISO } from 'date-fns';
import { Domain } from '@/models/domains';
import { getOwnedDomains } from '@/services/domains.service';
import { error } from '@/services/user-messages.service';
import cart from '@/store/modules/cart';

@Component
export default class UserDomains extends Vue {
  public loading = false;

  /** Flag set to `true` if domains are loaded and user has none */
  public noDomains = false;

  /** User domains. */
  public domains: Domain[] = [];

  mounted() {
    this.loadDomains();
  }

  public async loadDomains() {
    try {
      this.loading = true;
      this.domains = await getOwnedDomains();
      this.noDomains = this.domains.length === 0;
    } catch (err) {
      error(
        this,
        err,
        'Domains Unavailable',
        'Viewing managed domains is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }

  public addToCart(domain: Domain) {
    if (domain.renewal) {
      cart.addToCart(domain.renewal);
    }
  }

  /**
   * Check if domain is already in cart
   */
  public inCart(domain: string): boolean {
    return cart.items.some(d => d.properties?.domain === domain);
  }

  public expiringSoon(domain: Domain) {
    const expiring = parseISO(domain.expires);
    return subMonths(expiring, 1) <= new Date();
  }
}
</script>

<style lang="scss" scoped>
.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin-top: 1em;
}

div.buttons {
  .button {
    margin-right: 0.5em;
  }
}

table {
  td {
    vertical-align: middle;

    .button {
      margin-top: -4px;
    }
  }
}

a.button {
  margin-top: 1.5em;
}

div.continue {
  display: flex;
  align-items: center;
  margin: 1.5em 0;

  span {
    margin-right: 1.5em;
  }
}

.cost-explanation {
  margin-top: 4em;
  font-size: 0.97em;

  h4 {
    margin-bottom: 1em;
  }

  h6 {
    font-style: italic;
    margin-bottom: 0.3em;
  }

  p {
    color: #606160;
    font-size: 0.94em;
  }
}
</style>
