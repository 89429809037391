<template>
  <div class="app-container">
    <NavBar />
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import NavBar from './components/NavBar.vue';

@Component({
  components: { NavBar }
})
export default class App extends Vue {}
</script>

<style lang="scss">
.app-container {
  padding-bottom: 35px;
}
.svg-inline--fa {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
</style>
