<template>
  <div class="user-container">
    <div class="row">
      <div class="col-md-6">
        <h1>Users</h1>
      </div>
      <div class="col-md-6">
        <router-link tag="button" class="btn btn-primary float-right" :to="{ name: 'add-user' }">
          <font-awesome-icon icon="plus" />Create User
        </router-link>
      </div>
    </div>

    <table class="table" v-if="!!users && !!users.length">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th colspan="2">Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.email">
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>{{ user.email }}</td>
          <td>
            <div class="actions">
              <router-link :to="{ name: 'edit-user', params: {id: user.id } }">Edit</router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as usersService from '@/services/users.service';
import { error } from '@/services/user-messages.service';
import { User } from '@/models/user';

@Component
export default class Users extends Vue {
  public users: User[] = [];

  public mounted() {
    this.loadUsers();
  }

  public async loadUsers() {
    try {
      this.users = await usersService.getAll();
    } catch (err) {
      error(this, err);
    }
  }
}
</script>

<style lang="scss" scoped>
.user-container {
  max-width: 700px;
}

tr:hover {
  .actions {
    visibility: visible;
  }
}

.actions {
  visibility: hidden;
}
</style>
