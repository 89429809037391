import axios from '@/utilities/axios';
import { User, InvitedUser } from '@/models/user';
import app from '@/store/modules/app';

/**
 * Login to server
 * @param email Email address
 * @param password User password
 */
export async function login(email: string, password: string): Promise<User> {
  const response = await axios.post<User>('account/login', {
    email,
    password
  });
  const user = response.data;
  app.setUserAndSites(user);
  return user;
}

/**
 * Send forgotten password email
 */
export function forgotPassword(email: string) {
  return axios.post('account/forgot-password', {
    email
  });
}

/**
  * Reset password by token
  * @param resetToken Password reset token
  * @param password New password.
  * @param passwordConfirmed New password confirmed.
  */
export function resetPassword(resetToken: string, password: string, passwordConfirmed: string) {
  return axios.post('account/reset-password', {
    password,
    passwordConfirmed,
    resetToken
  });
}

/**
 * Change a user's password.
 * @param password New password
 * @param passwordConfirmed New password confirmed
 */
export function changePassword(password: string, passwordConfirmed: string) {
  return axios.post('account/change-password', {
    password,
    passwordConfirmed
  });
}

export async function getInvitedUser(invitationToken: string): Promise<InvitedUser> {
  const { data } = await axios.get<InvitedUser>(`account/setup/${invitationToken}`);
  return data;
}

export async function completeUserSetup(invitationToken: string, user: User) {
  const response = await axios.post<User>(`account/setup/${invitationToken}`, user);
  const savedUser = response.data;
  app.setUserAndSites(savedUser);
}

/** Logout user of application */
export async function logout() {
  try {
    await axios.post('account/logout');
  } catch (error) {
    // ignore log out errors
    console.error(error);
  } finally {
    app.setUserAndSites(null);
  }
}

/** Check if user is logged in to app */
export async function isLoggedIn(): Promise<User | null> {
  const user = await app.getCurrentUser();
  return user;
}
