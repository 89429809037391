<template>
  <div>
    <svg
      ref="svg"
      class="w-full h-full"
      viewBox="0 0 1000 500"
      preserveAspectRatio="xMidYMid meet"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as d3 from 'd3';
import { ExtendedFeatureCollection } from 'd3';
import { Geocode } from '@/models/analytics';
import { wait } from '@/utilities/helpers';

@Component({
  name: 'geo-map'
})
export default class GeoMap extends Vue {
  @Prop({
    type: Array,
    default: () => {
      return [] as Geocode[];
    }
  })
    geolocations!: Geocode[];

  public svg!: d3.Selection<SVGGElement, unknown, HTMLElement, any>;

  public countries: ExtendedFeatureCollection | undefined;

  public path!: d3.GeoPath<any, d3.GeoPermissibleObjects>;

  public projection!: d3.GeoProjection;

  private mapSetup = false;

  mounted() {
    this.setupMap();
  }

  async setupMap() {
    this.projection = d3.geoEquirectangular();
    this.path = d3.geoPath().projection(this.projection);
    this.countries = await d3.json<ExtendedFeatureCollection>('/world_countries.json');
    this.svg = d3.select(this.$refs.svg as SVGElement) as any;

    this.svg
      .append('g')
      .attr('class', 'countries')
      .selectAll('path')
      .data(this.countries!.features)
      .enter()
      .append('path')
      .attr('d', this.path)
      .style('stroke', 'white')
      .style('stroke-width', 1.5)
      .style('opacity', 0.8);

    this.svg.append('g').attr('class', 'geocodes');
    this.mapSetup = true;
  }

  async drawGeolocations(locations: Geocode[]) {
    await this.isReady();

    const visitors = this.svg
      .select('g.geocodes')
      .selectAll('circle')
      .data(locations);

    visitors
      .enter()
      .append('circle')
      .attr('cx', loc => {
        return this.projection([loc.y, loc.x])![0];
      })
      .attr('cy', loc => {
        return this.projection([loc.y, loc.x])![1];
      })
      .attr('r', '4px');

    // remove drawn visitors no longer existing
    visitors.exit().remove();
  }

  private async isReady() {
    for (let attempt = 0; attempt < 400; attempt++) {
      if (this.mapSetup) {
        // map is setup
        return;
      }
      // map not ready, keep waiting
      await wait(100);
    }
    // if map not ready in 40 seconds, then give up
    throw new Error('Timeout error loading map');
  }
}
</script>

<style lang="scss">
g.countries {
  fill: #0b3954;
  stroke: #fff;
}

g.geocodes {
  circle {
    fill: #f44;
    stroke: #1c1c1c;
  }
}
</style>
