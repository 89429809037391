<template>
  <div class="container">
    <div v-if="!emailSent">
      <h1 class="title">Forgot Password</h1>
      <p>Enter your email address below to have a password reset link sent to you.</p>
      <form v-on:submit.prevent="forgotPassword">
        <section>
          <b-field label="Email">
            <b-input type="email" v-model="email" required :autofocus="true"></b-input>
          </b-field>
          <b-button type="is-primary" native-type="submit" :loading="submitting">Send Password Reset</b-button>
        </section>
      </form>
    </div>
    <div v-else>
      <h2>Password Reset Email Sent</h2>
      <p>An email with password reset instructions has been sent to {{ email }}.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { forgotPassword as sendPasswordReset } from '@/services/auth.service';
import { error } from '@/services/user-messages.service';

@Component
export default class ForgotPassword extends Vue {
  public email = '';
  public emailSent = false;

  /** if form is in submitting state */
  public submitting = false;

  public async forgotPassword() {
    try {
      this.submitting = true;
      await sendPasswordReset(this.email);
      this.emailSent = true;
    } catch (err) {
      error(
        this,
        err as Error,
        'Error sending reset',
        'Password reset is currently unavailable, please try again later.'
      );
    } finally {
      this.submitting = false;
    }
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 500px;
}
</style>
