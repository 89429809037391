/** Bulma theme colors */
const theme = {
  primary: '#BFD7EA',
  light: '#878787',
  yellow: '#FFF37A',
  black: '#1C1C1C',
  darkPrimary: '#708291'
};

type ThemeKey = keyof typeof theme;

/** Theme colors in an array format */
const themePalette = Object.values(theme);

function getColor(index: number): string {
  const colorNames = Object.keys(theme) as ThemeKey[];
  const colorName = colorNames[index % colorNames.length];
  const color = theme[colorName];
  return color;
}

export {
  theme,
  themePalette,
  getColor
};
