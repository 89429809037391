import axios from 'axios';

const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // needed so API serves 401 instead of attempting to redirect to login page
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  },
  // including cookie for auth status
  withCredentials: true
});

axiosBase.interceptors.response.use(
  (response) => response,
  (error) => {
    const alreadyOnLoginScreen = window.location.pathname === '/login';
    if (error.response && error.response.status === 401 && !alreadyOnLoginScreen) {
      // redirect user to login screen if unauthorized
      window.location.replace(
        `/login?path=${encodeURIComponent(window.location.pathname + window.location.search)}`
      );
      return new Error('Please login first.');
    }
    throw error;
  }
);

export default axiosBase;
