<template>
  <form action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Replace file</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="uploadButton">
          <b-field class="file is-primary" :class="{ 'has-name': !!newAsset }">
            <b-upload v-model="newAsset" class="file-label" rounded>
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" />
                <span class="file-label">Click to upload</span>
              </span>
              <span class="file-name" v-if="newAsset">
                {{ newAsset.name }}
              </span>
            </b-upload>
          </b-field>
        </div>
        <div v-if="assetType === 'application/pdf'">
          <embed :type="assetType" :src="assetSource" width="800" height="1000" />
        </div>
        <div v-else>
          <b-image :src="assetSource" />
        </div>
      </section>
      <footer class="modal-card-foot">
        <span class="float-right">
          <b-button label="Close" @click="$emit('close')" />
          <b-button :disabled="!newAsset" label="Save" type="is-primary" @click="emitUpload" />
        </span>
      </footer>
    </div>
  </form>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import SiteAssetService from '@/services/site-asset.service';
import { SiteAsset } from '@/models/site-asset';

@Component
export default class UploadModal extends Vue {
  private siteAssetService = new SiteAssetService();
  @Prop({ type: Object, default: null }) existingSiteAsset!: SiteAsset;
  @Prop({ type: String, default: 0 }) siteId!: string;

  public newAsset: File | null = null;

  public get assetSource(): string {
    if (this.newAsset) return URL.createObjectURL(this.newAsset);

    if (this.existingSiteAsset?.id && this.siteId) {
      return this.siteAssetService.getAssetPath(this.siteId, this.existingSiteAsset?.id);
    }
    return '/img/file-upload.png';
  }

  public get assetType(): string {
    return this.newAsset?.type ?? this.existingSiteAsset?.type ?? 'image/png';
  }

  emitUpload() {
    this.$emit('fileUpload', this.newAsset, this?.existingSiteAsset?.id);
    this.newAsset = null;
    this.$emit('close');
  }
}
</script>

<style>
.float-right {
  float: right;
}
.uploadButton {
  margin-bottom: 1em;
}
</style>
