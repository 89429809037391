import axios from '@/utilities/axios';
import {
  SiteContent,
  NewPage,
  SitePage,
  SiteCollection,
  SiteTheme,
  PageTemplate
} from '@/models/site-content';
import AppModule from '@/store/modules/app';

/**
 * Gets all site content.
 * @param siteId Site ID.
 */
export async function getAllPages(siteId: string): Promise<SiteContent> {
  const response = await axios.get<SiteContent>(`sites/${siteId}/content/pages`);
  return response.data;
}

/**
 * Get specific page.
 * @param siteId Site ID.
 * @param pageId Page ID.
 */
export async function getPage(siteId: string, pageId: string | number): Promise<SitePage> {
  const response = await axios.get<SitePage>(`sites/${siteId}/content/pages/${pageId}`);
  return response.data;
}

export function deletePage(siteId: string, pageId: number) {
  return axios.delete(`sites/${siteId}/content/pages/${pageId}`);
}

/**
 * Get themes.
 * @param siteId Site ID.
 */
export async function getThemes(siteId: string): Promise<SiteTheme[]> {
  const response = await axios.get<SiteTheme[]>(`sites/${siteId}/content/themes`);
  return response.data;
}

export async function getTemplates(siteId: string): Promise<PageTemplate[]> {
  const response = await axios.get<PageTemplate[]>(`sites/${siteId}/content/templates`);
  return response.data;
}

/**
 * Add a new page or update an existing page.
 * @param siteId Site ID.
 * @param page Page info.
 */
export async function addOrUpdatePage(siteId: string, page: NewPage): Promise<number> {
  const response = await axios.post<number>(`sites/${siteId}/content/pages`, page);
  return response.data;
}

export async function getAllCollections(siteId: string): Promise<SiteCollection[]> {
  const response = await axios.get<SiteCollection[]>(`sites/${siteId}/collections`);
  return response.data;
}

/**
 * Get site collection details.
 * @param siteId Site ID
 * @param collectionId Collection ID.
 */
export async function getCollection(
  siteId: string,
  collectionId: number,
  includeItems = false
): Promise<SiteCollection> {
  let properties = {};
  if (includeItems) {
    properties = {
      params: {
        includeItems: true
      }
    };
  }
  const response = await axios.get<SiteCollection>(
    `sites/${siteId}/collections/${collectionId}`,
    properties
  );
  return response.data;
}

export async function addOrUpdateCollection(
  siteId: string,
  collection: SiteCollection
): Promise<number> {
  const response = await axios.post<number>(`sites/${siteId}/collections`, collection);
  return response.data;
}

/**
 *
 * @param theme to be added.
 * @param content the images for this theme.
 * @returns all existing themes.
 */
export async function addTheme(themeName: string, content: File[]): Promise<SiteTheme[]> {
  const formData = new FormData();
  for (let i = 0; i < content.length; i++) {
    formData.append('images', content[i]);
  }
  const response = await axios.post<SiteTheme[]>(
    `sites/${AppModule.lonelarkSiteId}/content/${themeName}`,
    formData
  );
  return response.data;
}
