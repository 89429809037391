<template>
  <div class="home container">
    <SiteAnalyticsReport ref="report" />
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import SiteAnalyticsReport from '@/components/SiteAnalyticsReport.vue';
import { Site } from '@/models/site';
import { error } from '@/services/user-messages.service';
import { SiteRouting } from '../mixins/SiteRouting';

@Component({
  components: { SiteAnalyticsReport }
})
export default class Dashboard extends mixins(SiteRouting) {
  public $refs!: {
    report: SiteAnalyticsReport;
  };

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadAnalytics(site);
    });
  }

  async loadAnalytics(site: Site) {
    try {
      if (!site) {
        // active site not yet set
        return;
      }
      await this.$refs.report.loadReport(site);
    } catch (err) {
      error(this, err as Error);
    }
  }
}
</script>

<style lang="scss">
.home.container {
  margin-top: 1em;
}

.title-row {
  h1 {
    margin-right: 1em;
  }

  h1,
  h2 {
    display: inline-block;
  }
}
</style>
