<template>
  <section class="section columns is-multiline">
    <div class="column is-4" v-for="page in pages" :key="page.text">
      <router-link :to="page.route">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title has-text-grey">{{ page.text }}</p>
          </header>
          <div class="card-content">
            <div class="content has-text-centered">
              <b-icon :icon="page.icon" size="is-large" type="is-primary" />
            </div>
          </div>
          <footer class="card-footer">
            <div class="card-footer-item">
              <span>{{ page.description }}</span>
            </div>
          </footer>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Pages from './pages';

@Component
export default class AdminHome extends Vue {
  pages = Pages;
}
</script>

<style lang="scss" scoped>
svg {
  margin-right: 7px;
}

a:hover {
  text-decoration: none;
}
</style>
