import axios from '@/utilities/axios';
import { StoreItem, StoreItemWithInvoice } from '@/models/purchases';

export async function getAllItems(siteId: string): Promise<StoreItem[]> {
  const response = await axios.get<StoreItem[]>(`sites/${siteId}/store`);
  return response.data;
}

/**
 * Get store item.
 * @param site Site id.
 * @param item Item id.
 * @returns Store item.
 */
export async function getItem(site: string, item: string): Promise<StoreItem> {
  const response = await axios.get<StoreItem>(`sites/${site}/store/${item}`);
  return response.data;
}

/**
 * Saves a store item. Either creates a new record or updates existing item.
 * @param site Site store being updated.
 * @param item Item to save.
 * @returns Saved item. If a new item, contains a server-side generated ID.
 */
export async function saveItem(site: string, item: StoreItem): Promise<StoreItem> {
  let apiEndpoint = `sites/${site}/store`;
  if (item.id) {
    apiEndpoint += `/${item.id}`;
  }
  const response = await axios.post<StoreItem>(apiEndpoint, item);
  // if new item added, full object is returned from server
  // if existing item updated, no data is returned from server so just return argument
  return response.data || item;
}

export async function getUnprocessedItems(): Promise<StoreItemWithInvoice[]> {
  const response = await axios.get<StoreItemWithInvoice[]>('invoices/unprocessed');
  return response.data;
}
