<template>
  <div class="container">
    <h1 class="title">Site Notifications</h1>
    <p>Update your notification settings.</p>
    <div class="site-notification" v-for="notification in notifications" :key="notification.id">
      <h3 class="title is-4">{{ notification.title }}</h3>
      <p>{{ notification.description }}</p>
      <div class="action-row" v-if="notification.subscription">
        <span class="has-text-success">
          <font-awesome-icon icon="check-circle" />Subscribed
        </span>
        <b-button
          type="is-text"
          v-on:click="updateSubscription(notification)"
          :loading="notification.updating"
        >Unsubscribe</b-button>
      </div>
      <div class="action-row" v-else>
        <b-button
          type="is-success"
          outlined
          v-on:click="updateSubscription(notification)"
          :loading="notification.updating"
        >Subscribe</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { Notification } from '@/models/notification';
import {
  getNotifications,
  subscribeToNotification,
  unsubscribeFromNotification
} from '@/services/notifications.service';
import { error } from '@/services/user-messages.service';
import { Site } from '@/models/site';
import { SiteRouting } from '@/mixins/SiteRouting';

@Component
export default class SiteSettings extends mixins(SiteRouting) {
  /** if loading data, prop is `true` */
  loading = false;

  notifications: Notification[] = [];

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadSiteNotifications(site);
    });
  }

  async loadSiteNotifications(site: Site) {
    try {
      if (!site) {
        // site not set
        return;
      }
      this.loading = true;
      this.notifications = await getNotifications(site.siteId);
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }

  async updateSubscription(notification: Notification) {
    try {
      notification.updating = true;
      if (notification.subscription) {
        // unsubscribe to notification
        await unsubscribeFromNotification(
          this.site.siteId,
          notification.subscription
        );
        notification.subscription = undefined;
      } else {
        // subscribe to notification
        const subscriptionId = await subscribeToNotification(
          this.site.siteId,
          notification.id
        );
        notification.subscription = subscriptionId;
      }
    } catch (err) {
      error(this, err as Error);
    } finally {
      notification.updating = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.site-notification {
  margin: 2em;
}

.action-row {
  display: flex;
  align-items: center;
  margin-top: 10px;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin: 0 2em;
  }
}
</style>
