<template>
  <div>
    <h3>{{ newAccount ? 'Create New' : 'Edit' }} User</h3>
    <form v-on:submit.prevent="saveUser">
      <section>
        <b-field label="First name">
          <b-input type="text" v-model="user.firstName" required :autofocus="true"></b-input>
        </b-field>
        <b-field label="Last name">
          <b-input type="text" v-model="user.lastName" required></b-input>
        </b-field>
        <b-field label="Email">
          <b-input type="email" v-model="user.email" required></b-input>
        </b-field>

        <b-field>
          <b-radio-button v-model="user.role" :native-value="roles.User">
            <span>User</span>
          </b-radio-button>

          <b-radio-button v-model="user.role" :native-value="roles.SiteAdmin">
            <span>Admin</span>
          </b-radio-button>

          <b-radio-button v-model="user.role" :native-value="roles.LarkAdmin">Lark Admin</b-radio-button>
        </b-field>

        <div class="field">
          <b-checkbox v-model="changePassword">Set password</b-checkbox>
        </div>

        <b-field label="Password" v-if="changePassword">
          <b-input type="password" v-model="user.password" password-reveal></b-input>
        </b-field>

        <b-button type="is-primary" native-type="submit" :loading="submitting">Save</b-button>
        <router-link tag="button" class="button" to="/admin/users">Cancel</router-link>
      </section>
    </form>
    <div>{{errorMessage}}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, newUser } from '@/models/user';
import { SiteRole } from '@/models/site';
import * as userService from '@/services/users.service';
import { success, error } from '@/services/user-messages.service';
import { emptyGuid } from '@/utilities/guid';

@Component
export default class EditUser extends Vue {
  public user: User = newUser();
  public roles = SiteRole;
  public errorMessage = '';
  public newAccount = true;
  public changePassword = false;

  @Prop({ default: '' })
  public id!: string;

  public mounted() {
    if (this.id) {
      this.newAccount = false;
      this.loadUser(this.id);
    } else {
      // give new user an empty guid
      this.user.id = emptyGuid;
    }
  }

  public async saveUser() {
    try {
      const save = this.newAccount
        ? userService.addUser
        : userService.updateUser;
      await save(this.user);
      success(
        this,
        'User Saved',
        `User ${this.user.firstName} ${this.user.lastName} saved.`
      );
      this.$router.push('/admin/users');
    } catch (err) {
      error(this, err, 'Save Failed', 'Error saving user.');
    }
  }

  private async loadUser(id: string) {
    this.user = await userService.getUser(id);
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 700px;

  button {
    margin-right: 5px;
  }
}
</style>
