<template>
  <div class="container">
    <div class="title is-4">Complete Payment</div>
    <b-loading :active.sync="cardsLoading" :is-full-page="false"></b-loading>
    <label class="label" for="saved-cards">Payment Method</label>
    <div class="cards" v-if="cards.length > 0">
      <b-select
        name="saved-cards"
        v-show="!useNewCard"
        placeholder="Select a credit card"
        v-model="paymentMethodId"
      >
        <option v-for="card in cards" :value="card.id" :key="card.id">
          {{ card.brand }} {{ card.lastFourDigits }}
        </option>
      </b-select>
      <b-button type="is-text" v-on:click="useNewCard = !useNewCard">{{
        useNewCard ? 'Use saved card' : 'Add a card'
      }}</b-button>
    </div>
    <div class="box add-card" v-if="useNewCard">
      <h6 class="title is-6">Enter Card Details</h6>
      <CreditCard ref="creditCard" actionText="Use card" :showSubmitButton="false" />
      <div class="field">
        <b-checkbox v-model="saveCard">Save card for future payments</b-checkbox>
      </div>
    </div>
    <b-button type="is-primary" v-on:click="completeCheckout" :loading="paymentProcessing"
      >Complete Purchase</b-button
    >
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import CreditCard from '@/components/CreditCard.vue';
import { error } from '@/services/user-messages.service';
import {
  beginCheckout,
  getSavedCards,
  confirmCardPayment,
  completeCheckout,
  beginInvoicePayment,
  invoicePaymentComplete,
  addCard
} from '@/services/payments.service';
import { ShoppingCart, CardSummary } from '@/models/purchases';
import { StripeIntegrated } from '@/mixins/StripeIntegrated';
import cart from '@/store/modules/cart';

@Component({
  components: { CreditCard }
})
export default class Checkout extends mixins(StripeIntegrated) {
  /**
   * Invoice ID.
   * If provided, checkout is for an invoice.
   * If not provided, then cart checkout will be used.
   */
  @Prop(String) readonly invoiceId!: string;

  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }

  public $refs!: {
    creditCard: CreditCard;
  };

  /** Saved credit cards. */
  public cards: CardSummary[] = [];

  /** Stripe payment method ID */
  public paymentMethodId = '';

  /** Strip payment intent ID */
  public paymentIntentId = '';

  /** If true, shows a form to enter new card info. */
  public useNewCard = false;

  /** Whether to save manually added card */
  public saveCard = true;

  public paymentProcessing = false;

  /** Flag set to `true` while saved payment methods load from LL backend. */
  cardsLoading = true;

  async mounted() {
    this.loadPaymentMethods();
    if (this.invoiceId) {
      // it's an invoice
      await this.startInvoicePayment(this.invoiceId);
    } else {
      // it's a shopping cart
      await this.startCartCheckout();
    }
  }

  private async startInvoicePayment(invoiceId: string) {
    try {
      this.paymentIntentId = await beginInvoicePayment(invoiceId);
    } catch (err) {
      error(
        this,
        err,
        'Order Unavailable',
        'Unable to load order. Please verify the link is correct.'
      );
      this.$router.push({ name: 'user-orders' });
    }
  }

  private async startCartCheckout() {
    if (this.cart.items.length) {
      this.paymentIntentId = await beginCheckout(this.cart);
    } else {
      // nothing in cart, redirect to cart page
      this.$router.push({ name: 'cart' });
    }
  }

  async completeCheckout() {
    try {
      this.paymentProcessing = true;
      if (this.useNewCard) {
        const paymentMethodId = await this.$refs.creditCard.stripeFormSubmitted();
        if (!paymentMethodId) {
          // stripe payment method failed
          // error already shown by CreditCard component, so just return
          return;
        }
        if (this.saveCard) {
          await addCard(paymentMethodId);
        }
        this.paymentMethodId = paymentMethodId;
      }
      if (!this.paymentMethodId) {
        error(
          this,
          null,
          'Missing Payment Info',
          'Please enter payment details to complete checkout.'
        );
        return;
      }
      await this.chargeCustomer();
      let invoiceId = this.invoiceId;
      if (this.invoiceId) {
        // complete invoice
        await invoicePaymentComplete(this.invoiceId);
      } else {
        // complete cart checkout
        invoiceId = cart.id;
        await completeCheckout(cart.id, this.paymentMethodId);
        // clear local cache
        cart.updateCart(null as any);
      }
      this.$router.push({
        name: 'user-order',
        params: { invoiceId }
      });
    } catch (err) {
      error(
        this,
        err,
        'Payment Error',
        'There was an error processing your payment. Please try again.'
      );
    } finally {
      this.paymentProcessing = false;
    }
  }

  private async chargeCustomer() {
    if (!this.paymentIntentId) {
      // no payment intent, must be subscription-only checkout
      return null;
    }
    const results = await confirmCardPayment(this.paymentIntentId, this.stripe, {
      payment_method: this.paymentMethodId
    });
    console.log('results', results);
    return results;
  }

  public async loadPaymentMethods() {
    try {
      this.cardsLoading = true;
      this.cards = await getSavedCards();
      if (this.cards.length > 0) {
        this.paymentMethodId = this.cards[0].id;
      } else {
        // no saved cards, default to new card
        this.useNewCard = true;
      }
    } catch (err) {
      error(
        this,
        err as Error,
        'Payment Methods Unavailable',
        'Unable to retrieve saved payment methods. Please try again.'
      );
    } finally {
      this.cardsLoading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
div.cards {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;

  button {
    margin-left: 10px;
  }
}

.add-card {
  max-width: 550px;

  form {
    margin-bottom: 2em;
  }
}
</style>
