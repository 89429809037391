<template>
  <div class="container">
    <h1 class="title">Find a Domain</h1>
    <form v-on:submit.prevent="searchDomains">
      <div class="columns">
        <div class="column is-one-third">
          <b-field label="Enter a domain name">
            <b-input
              v-model="searchTerm"
              placeholder="Example: google, wikipedia, lonelark"
              type="text"
            ></b-input>
          </b-field>
        </div>
        <div class="column period">.</div>
        <div class="column">
          <b-field label="Select a few TLDS">
            <b-taginput
              v-model="selectedTlds"
              :data="filteredTlds"
              autocomplete
              :open-on-focus="true"
              field="option"
              placeholder="Select top level domain"
              @typing="getFilteredTlds"
            >
            </b-taginput>
          </b-field>
        </div>
      </div>
      <b-field>
        <b-button native-type="submit" :loading="loading">Search</b-button>
      </b-field>
    </form>
    <b-table v-if="domains.length > 0" :data="domains" :loading="loading">
      <b-table-column field="domain" label="Domain" v-slot="props">
        {{ props.row.domain }}
      </b-table-column>
      <b-table-column field="premium" label v-slot="props">
        <b-tooltip
          v-if="props.row.premium"
          label="Premium domains are already owned but still available for purchase from domain resellers."
          animated
        >
          <b-tag>Premium</b-tag>
        </b-tooltip>
      </b-table-column>
      <b-table-column class="cost" label="Price" v-slot="props">
        <span v-if="!props.row.available">Unavailable</span>
        <span v-else>{{
          props.row.registration.price | currencyToString
        }}</span>
      </b-table-column>
      <b-table-column label="Add to cart" width="425" v-slot="props">
        <div class="action-row" v-if="inCart(props.row)">
          <span class="has-text-success">
            <font-awesome-icon icon="check-circle" />Added to cart
          </span>
        </div>
        <div class="action-row" v-else>
          <b-field v-if="props.row.available">
            <p class="control">
              <b-button
                v-on:click="addToCartWithPlan(props.row)"
                label="Domain & plan"
                type="is-primary"
              />
            </p>
            <p class="control">
              <b-dropdown>
                <template #trigger>
                  <b-button type="is-primary" icon-left="chevron-down" />
                </template>
                <b-dropdown-item v-on:click="addToCart(props.row)">
                  Domain only
                </b-dropdown-item>
              </b-dropdown>
            </p>
          </b-field>
        </div>
      </b-table-column>
    </b-table>
    <div class="continue" v-if="cart.items.length > 0">
      <b-button type="is-primary" tag="router-link" :to="{ name: route }">{{
        actionText
      }}</b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { AvailableDomain } from '@/models/domains';
import { search, getRegistrationData } from '@/services/domains.service';
import { ShoppingCart, StoreItem } from '@/models/purchases';
import { error } from '@/services/user-messages.service';
import cart from '@/store/modules/cart';
import { addPlanToCart } from '@/services/plans.service';

@Component({
  name: 'domain-search'
})
export default class DomainSearch extends Vue {
  @Prop({
    type: String,
    default: 'cart'
  })
    route!: string;

  @Prop({
    type: String,
    default: 'Continue to payment'
  })
    actionText!: string;

  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }

  public plan!: StoreItem;
  public tlds: string[] = [];
  public filteredTlds = this.tlds;
  public selectedTlds = ['com', 'org', 'net', 'io'];

  public searchTerm = '';
  public loading = false;
  /** Available domains. */
  public domains: AvailableDomain[] = [];

  async mounted() {
    // set step index
    this.$emit('step-changed', 1);
    const regInfo = await getRegistrationData();
    console.log(regInfo);
    this.plan = regInfo.subscriptionPlan;
    this.tlds = regInfo.tlds;
    this.filteredTlds = this.tlds;
  }

  public async searchDomains() {
    try {
      this.loading = true;
      this.domains = await search(this.searchTerm, this.selectedTlds);
    } catch (err) {
      error(
        this,
        err,
        'Search Unavailable',
        'Domain search is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }

  /** Add domain to cart */
  public async addToCart(domain: AvailableDomain) {
    if (domain.registration) {
      await cart.addToCart(domain.registration);
    }
  }

  /** Add domain to cart with hosting plan */
  public async addToCartWithPlan(domain: AvailableDomain) {
    if (domain.registration && this.plan) {
      await cart.addToCart(domain.registration);
      await addPlanToCart(this.plan, domain.domain);
    }
  }

  /**
   * Check if domain is already in cart
   */
  public inCart(domain: AvailableDomain): boolean {
    return this.cart.items.some(d => d.properties?.domain === domain.domain);
  }

  public getFilteredTlds(term: string) {
    this.filteredTlds = this.tlds.filter(tld => {
      return tld.indexOf(term) >= 0;
    });
  }
}
</script>

<style lang="scss" scoped>
form {
  button {
    margin-left: 10px;
  }
}

.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin-top: 1em;
}

table {
  td {
    vertical-align: middle;
  }

  span.tag {
    // premium tag
    margin-right: 10px;
  }
}

div.continue {
  display: flex;
  align-items: center;
  margin: 1.5em 0;

  span {
    margin-right: 1.5em;
  }
}

.period {
  flex-grow: 0;
  align-self: flex-end;
  font-weight: bold;
  font-size: 36px;
  margin: 0 -20px -5px -20px;
  float: left;
}
</style>
