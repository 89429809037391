import axios from '@/utilities/axios';
import { Asset } from '@/models/site-content';
import { SiteAsset } from '@/models/site-asset';

export default class SiteAssetService {
  /**
   * Upload site file.
   * @param siteId Site ID.
   * @param file File.
   * @returns Site asset.
   */
  public async uploadFile(siteId: string, file: File, assetId?: string): Promise<Asset> {
    const formData = new FormData();
    formData.append('file', file);
    let url = `sites/${siteId}/assets`;
    if (assetId) url += `/${assetId}`;
    const response = await axios.post<Asset>(url, formData);
    return response.data;
  }

  /**
   * Get url to file.
   * @param siteId Site ID.
   * @param file File.
   * @returns Site asset.
   */
  public async getFile(siteId: string, assetId: string) {
    const response = await axios.get<File>(`sites/${siteId}/assets/${assetId}`);
    return response.data;
  }

  /*
   * Get the details of assets that are associated for a site.
   * @param siteId Site ID.
   * @returns collection of site asset details
   */
  public async getSiteAssets(siteId: string): Promise<SiteAsset[]> {
    const response = await axios.get<SiteAsset[]>(`sites/${siteId}/assets`);
    return response.data;
  }

  /*
   * Deletes the site asset.
   * @param siteId (required)
   * @param assetId (required)
   * @returns collection of site asset details
   */
  public async deleteSiteAsset(siteId: string, assetId: string): Promise<void> {
    await axios.delete(`sites/${siteId}/assets/${assetId}`);
  }

  public getAssetPath(siteId: string, assetId: string): string {
    return `${process.env.VUE_APP_API_URL}sites/${siteId}/assets/${assetId}`;
  }

  public async getPagesUseAsset(siteId: string, assetId: string): Promise<string[]> {
    const response = await axios.get<string[]>(`sites/${siteId}/assets/${assetId}/pages`);
    return response?.data || [];
  }
}
