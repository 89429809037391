import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { EventDispatcher, IEvent } from 'strongly-typed-events';
import { UserMessage, UserMessageType } from '@/models/user';

export interface SessionDetails {
  sessionId: string;
  port: number;
}

/** Hub for general Lark SignalR usage. */
export class LarkHub {
  public connection: HubConnection;
  private readonly messageReceivedEvent = new EventDispatcher<LarkHub, UserMessage>();
  private readonly sessionStartedEvent = new EventDispatcher<LarkHub, SessionDetails>();
  private readonly publishCompletedEvent = new EventDispatcher<LarkHub, UserMessage>();
  private currentSessions: {
    [key: string]: SessionDetails | undefined;
  } = {};

  /**
   * Hub connection state
   */
  private connected!: Promise<void>;

  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_URL}hubs/lark`)
      .build();

    this.connect();

    this.connection.on('sessionStarted', this.sessionStarted.bind(this));
    this.connection.on('sendHubMessage', this.sendHubMessage.bind(this));
    this.connection.on('messageReceived', (message: UserMessage) => {
      if (
        message.title.toLowerCase().indexOf('publish') >= 0 &&
        message.type !== UserMessageType.InProgress
      ) {
        // site publish finished
        this.publishCompletedEvent.dispatch(this, message);
      }
      this.messageReceivedEvent.dispatch(this, message);
    });
  }

  /**
   * Starts hub connection.
   */
  private async connect() {
    try {
      this.connected = this.connection.start();
    } catch (err) {
      console.log(err);
      setTimeout(this.connect, 7000);
    }
  }

  public async startSession(siteId: string) {
    await this.connected;
    return this.connection.invoke('RequestEditorSession', siteId);
  }

  /** Finished editing site so end the session */
  public async endSession(siteId: string) {
    this.currentSessions[siteId] = undefined;
    await this.connected;
    return this.connection.invoke('EditorSessionFinished', siteId);
  }

  /**
   * Deploy site
   * @param sessionId
   */
  public async deploySite(sessionId: string) {
    await this.connected;
    return this.connection.invoke('RequestSiteDeploy', sessionId);
  }

  public sendHubMessage(message: string) {
    console.log('sendHubMessage', message);
  }

  public getSiteSession(siteId: string): SessionDetails | undefined {
    const session = this.currentSessions[siteId];
    return session;
  }

  public sessionStarted(sessionId: string, port: number): void {
    const session: SessionDetails = {
      sessionId,
      port
    };
    console.log('sessionStarted', session);
    this.currentSessions[sessionId] = session;
    this.sessionStartedEvent.dispatch(this, session);
  }

  /**
   * Message received event stream.
   */
  public get sessionStart(): IEvent<LarkHub, SessionDetails> {
    return this.sessionStartedEvent.asEvent();
  }

  /**
   * Message received event stream.
   */
  public get messageReceived(): IEvent<LarkHub, UserMessage> {
    return this.messageReceivedEvent.asEvent();
  }

  /**
   * Event fired when a site publish completes. Check the message type to see if publish succeeded or errored.
   */
  public get sitePublishCompleted(): IEvent<LarkHub, UserMessage> {
    return this.publishCompletedEvent.asEvent();
  }

  /**
   * Send a user a message.
   * @param userId User ID.
   * @param message User message.
   */
  public async sendUserMessage(userId: string, message: UserMessage) {
    await this.connected;
    return this.connection.invoke('SendUserMessage', userId, message);
  }
}
