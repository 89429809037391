import Vue from 'vue';

Vue.filter('currencyToString', (cents: number) => {
  if (!cents) {
    return '$0';
  }

  const dollars = cents / 100;
  return dollars.toLocaleString(undefined, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
});
