<template>
  <div>
    <b-table :loading="loading" :data="systems">
      <b-table-column cell-class="status" label="Status" v-slot="props">
        <font-awesome-icon icon="circle" :class="props.row.state.class"></font-awesome-icon>
        {{ props.row.state.text }}
      </b-table-column>
      <b-table-column label="Name" v-slot="props">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column label="Details" v-slot="props">
        {{ props.row.result }}
      </b-table-column>
      <b-table-column label="Last Success" v-slot="props">
        {{ props.row.lastSuccess | toDateTime }}
      </b-table-column>
      <b-table-column label="Last Failure" v-slot="props">
        {{ props.row.lastFailure | toDateTime }}
      </b-table-column>
    </b-table>
    <div class="columns">
      <div class="column">
        <div class="site-loads">
          <canvas ref="lastDayLoads"></canvas>
        </div>
        <h2>Recent Errors</h2>
        <b-table :data="recentErrors" :loading="loading">
          <b-table-column label="Date" v-slot="props">
            {{ props.row.checkedOn | toDateTime }}
          </b-table-column>
          <b-table-column label="Site" v-slot="props">
            {{ props.row.siteName }}
          </b-table-column>
          <b-table-column label="Error" v-slot="props">
            <div v-if="props.row.error">
              <strong>Error:</strong>
              {{ props.row.error }}
            </div>
            <div v-if="props.row.console">
              <strong>Console:</strong>
              {{ props.row.console }}
            </div>
            <div v-if="props.row.requestException">
              <strong>Request Exception:</strong>
              {{ props.row.requestException }}
            </div>
            <div v-if="props.row.pageError">
              <strong>Page Error:</strong>
              {{ props.row.pageError }}
            </div>
          </b-table-column>
        </b-table>
      </div>
      <div class="column site-status">
        <b-table
          :data="siteStatus"
          :loading="loading"
          :row-class="(row, index) => row.nominal && 'nominal'"
        >
          <b-table-column v-slot="props">
            <div class="status" :class="{ nominal: props.row.nominal }">
              <font-awesome-icon
                :icon="props.row.nominal ? 'check-circle' : 'times-circle'"
                :title="props.row.errorDetails"
              ></font-awesome-icon>
            </div>
          </b-table-column>
          <b-table-column label="Site" v-slot="props">
            {{ props.row.name }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Chart, ChartDataset, ChartData } from 'chart.js';
import { Component, Vue } from 'vue-property-decorator';
import { getColor } from '@/utilities/colors';
import { getSitePerformance, getSystemStatus } from '@/services/admin.service';
import { error } from '@/services/user-messages.service';
import { SitePerformance, SiteStatus } from '@/models/site-performance';
import { SystemStatusWithState } from '@/models/admin';

@Component
export default class AdminDashboard extends Vue {
  public siteStatus: SiteStatus[] = [];
  public recentErrors: SitePerformance[] = [];
  public systems: SystemStatusWithState[] = [];
  public loading = true;

  public $refs!: {
    /** Div container for last 24 hours sites' performance */
    lastDayLoads: HTMLCanvasElement;
  };

  private charts: { [key: string]: Chart } = {};

  mounted() {
    this.loadDashboard();
  }

  async loadDashboard() {
    try {
      this.loading = true;
      const [performance, systemStatus] = await Promise.all([
        getSitePerformance(),
        getSystemStatus()
      ]);
      this.systems = systemStatus;
      const results = this.prepareData(performance);
      this.siteStatus = results.siteStatus;
      this.recentErrors = results.recentErrors;
      this.drawSiteLoadTimeLineChart(results.siteLoads);
    } catch (err) {
      error(this, err, 'Site Performance Unavailable', (err as Error).message);
    } finally {
      this.loading = false;
    }
  }

  private prepareData(perfResults: SitePerformance[]) {
    const siteLoads: { [key: string]: ChartDataset } = {};
    const siteStatus: SiteStatus[] = [];
    perfResults.forEach(r => {
      const point = {
        x: r.checkedOn,
        y: r.loadTime
      };
      if (siteLoads[r.siteId]) {
        siteLoads[r.siteId].data!.push(point as any);
        return;
      }
      const index = Object.keys(siteLoads).length;
      const color = getColor(index);
      siteLoads[r.siteId] = {
        label: r.siteName || r.domain,
        backgroundColor: color,
        borderColor: color,
        fill: false,
        data: [point as any]
      };
      // first time in the loop this site was seen, so this is the latest site check
      siteStatus.push({
        name: r.siteName,
        nominal: !r.error && !r.console && !r.requestException && !r.pageError,
        errorDetails: r.error || r.console || r.requestException || r.pageError,
        date: r.checkedOn
      });
    });
    const recentErrors = perfResults.filter(
      r => r.error || r.console || r.requestException || r.pageError
    );
    // sort failing sites first
    siteStatus.sort((a, b) => (a.nominal ? 1 : 0));
    return {
      siteStatus,
      siteLoads,
      recentErrors
    };
  }

  drawSiteLoadTimeLineChart(siteLoads: { [key: string]: ChartDataset }) {
    const canvas = this.$refs.lastDayLoads?.getContext('2d') as CanvasRenderingContext2D;
    if (!canvas) {
      // canvas not present, must have been removed from DOM
      // likely due to user navigating away before data returned
      return;
    }

    const datasets: ChartDataset[] = [];
    const siteIds = Object.keys(siteLoads);
    siteIds.forEach(siteId => {
      const siteData = siteLoads[siteId];
      datasets.push(siteData);
    });
    const existingChart = this.charts.loadTimeLine;
    if (existingChart) {
      // chart already initialized, only need to update data
      existingChart.data.datasets = datasets;
      existingChart.update();
      return;
    }
    this.charts.loadTimeLine = new Chart(canvas, {
      type: 'line',
      data: {
        datasets
      },
      options: {
        scales: {
          x: {
            type: 'time' as any,
            time: {
              displayFormats: {
                hour: 'haaa'
              },
              tooltipFormat: 'M/d h:mm aaa',
              unit: 'hour'
            },
            // maxTicksLimit: 8,
            ticks: {
              autoSkip: true
            }
          },
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            intersect: false,
            mode: 'x'
          }
        }
      }
    });
  }
}
</script>

<style lang="scss">
.site-loads {
  canvas {
    height: 300px;
  }
}

.site-status {
  table {
    tr {
      color: red;
      &.nominal {
        color: green;
      }
    }
  }
}

td.status {
  svg.online {
    color: green;
  }

  svg.offline {
    color: gray;
  }

  svg.error {
    color: red;
  }
}
</style>
