import Vue from 'vue';
import {
  Autocomplete,
  Button,
  Carousel,
  Checkbox,
  ConfigProgrammatic,
  Datepicker,
  Field,
  Icon,
  Image,
  Input,
  Loading,
  Menu,
  Message,
  Modal,
  Navbar,
  Numberinput,
  Select,
  Sidebar,
  Snackbar,
  Steps,
  Switch,
  Table,
  Taginput,
  Tooltip,
  Upload,
  Dropdown
} from 'buefy';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faBell,
  faCheckCircle,
  faChevronDown,
  faCircle,
  faCreditCard,
  faDebug,
  faDotCircle,
  faDownload,
  faEdit,
  faEllipsisV,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faImages,
  faInfoCircle,
  faMoneyCheckAlt,
  faNetworkWired,
  faPaperPlane,
  faPlus,
  faSearch,
  faServer,
  faSignIn,
  faShoppingCart,
  faSitemap,
  faStickyNote,
  faStore,
  faSync,
  faTachometerAltFast,
  faTimesCircle,
  faToiletPaper,
  faTrash,
  faUpload,
  faUser,
  faUsers
} from '@fortawesome/pro-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome';
import 'vue-tel-input/dist/vue-tel-input.css';
import App from './App.vue';
import './registerServiceWorker';
import './utilities/class-component-hooks';
import router from './router';
import store from './store';
import './charts';
import './utilities/pluralize-plugin';
import './filters/date';
import './filters/enums';
import './filters/number';
import './filters/currency';
import './styles/my.buefy.scss';

library.add(
  faAngleLeft as IconDefinition,
  faAngleRight as IconDefinition,
  faArrowUp as IconDefinition,
  faBell as IconDefinition,
  faCheckCircle as IconDefinition,
  faChevronDown as IconDefinition,
  faCircle as IconDefinition,
  faCreditCard as IconDefinition,
  faDebug as IconDefinition,
  faDebug as IconDefinition,
  faDotCircle as IconDefinition,
  faDownload as IconDefinition,
  faEdit as IconDefinition,
  faEllipsisV as IconDefinition,
  faExclamationCircle as IconDefinition,
  faExternalLink as IconDefinition,
  faEye as IconDefinition,
  faImages as IconDefinition,
  faInfoCircle as IconDefinition,
  faMoneyCheckAlt as IconDefinition,
  faNetworkWired as IconDefinition,
  faPaperPlane as IconDefinition,
  faPlus as IconDefinition,
  faSearch as IconDefinition,
  faServer as IconDefinition,
  faSignIn as IconDefinition,
  faShoppingCart as IconDefinition,
  faSitemap as IconDefinition,
  faStickyNote as IconDefinition,
  faStore as IconDefinition,
  faSync as IconDefinition,
  faTachometerAltFast as IconDefinition,
  faTimesCircle as IconDefinition,
  faTrash as IconDefinition,
  faToiletPaper as IconDefinition,
  faUpload as IconDefinition,
  faUser as IconDefinition,
  faUsers as IconDefinition
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.config.productionTip = false;

Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Carousel);
Vue.use(Checkbox);
Vue.use(Datepicker);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(Message);
Vue.use(Modal);
Vue.use(Navbar);
Vue.use(Numberinput);
Vue.use(Select);
Vue.use(Sidebar);
Vue.use(Snackbar);
Vue.use(Steps);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Taginput);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Dropdown);

ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
