import { register } from 'register-service-worker';
import { SnackbarProgrammatic as Snackbar } from 'buefy';

let updateDownloaded = false;
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() { /** app is being served by the service worker */ },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // new content is downloading
      updateDownloaded = true;
    },
    updated() {
      // new content is available
      // this fires every time the page loads, so first verify update was downloaded
      if (updateDownloaded) {
        // notify user to reload page for updated app
        Snackbar.open({
          duration: 30000, // 30 seconds
          type: 'is-info',
          position: 'is-bottom',
          actionText: 'Refresh',
          message: 'A new update is available! Refresh the page to get the latest Lone Lark improvements.',
          queue: true,
          onAction: () => {
            window.location.reload();
          }
        });
      }
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}
