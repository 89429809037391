export default [
  {
    text: 'Dashboard',
    route: { name: 'admin-dashboard' },
    icon: 'tachometer-alt-fast',
    description: 'View system status and performance.'
  },
  {
    text: 'Invoice',
    route: { name: 'create-invoice' },
    icon: 'money-check-alt',
    description: 'Invoice user.'
  },
  {
    text: 'Web Log',
    route: { name: 'log' },
    icon: 'sticky-note',
    description: 'View the event log.'
  },
  {
    text: 'Service Log',
    route: { name: 'service-log' },
    icon: 'server',
    description: 'View the service log.'
  },
  {
    text: 'Users',
    route: { name: 'users' },
    icon: 'users',
    description: 'Create, view, and edit Lone Lark users.'
  },
  {
    text: 'Send Message',
    route: { name: 'send-message' },
    icon: 'paper-plane',
    description: 'Send user a message.'
  },
  {
    text: 'Sites',
    route: { name: 'sites' },
    icon: 'sitemap',
    description: 'View all Lone Lark sites.'
  },
  {
    text: 'Store Issues',
    route: { name: 'store-issues' },
    icon: 'store',
    description: 'View paid but unprocessed items.'
  },
  {
    text: 'Deploy a Site',
    route: { name: 'deploy' },
    icon: 'download',
    description: 'Deploy a site.'
  },
  {
    text: 'Add Theme',
    route: { name: 'add-theme' },
    icon: 'images',
    description: 'Add a theme.'
  }
];
