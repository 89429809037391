import axios from '@/utilities/axios';
import { SiteSubscription, SubscriptionPlan } from '@/models/plans';
import { StoreItem } from '@/models/purchases';
import cart from '@/store/modules/cart';

export function addPlanToCart(plan: StoreItem, domain: string) {
  if (!plan.properties) {
    plan.properties = {};
  }
  plan.properties.domain = domain;
  cart.addToCart(plan);
}

export async function getAvailablePlans(): Promise<StoreItem[]> {
  const { data } = await axios.get<StoreItem[]>('plans');
  return data;
}

export async function getPlanSubscriptions(): Promise<SiteSubscription[]> {
  const { data } = await axios.get<SiteSubscription[]>('plans/subscriptions');
  return data;
}
