import axios from '@/utilities/axios';
import { Summary } from '@/models/analytics';

/**
 * Get latest analytic summary.
 */
export async function getSummary(siteId: string): Promise<Summary> {
  const response = await axios
    .get<Summary>(`analytics/${siteId}`, {});
  return response.data;
}

/** Get analytics summary report by date range */
export async function getSummaryByRange(siteId: string, start: Date, end: Date): Promise<Summary> {
  const response = await axios
    .get<Summary>(`analytics/${siteId}/snapshot`, {
      params: {
        start,
        end
      }
    });
  return response.data;
}
