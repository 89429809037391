import pluralize from 'pluralize';
import {
  Action, Module, Mutation, VuexModule, getModule
} from 'vuex-module-decorators';
import { ShoppingCart, StoreItem } from '@/models/purchases';
import { addToCart, removeFromCart } from '@/services/cart.service';
import store from '../index';

function emptyCart(): ShoppingCart {
  return {
    id: '',
    items: [],
    cartTotal: 0
  };
}

function calculateTotal(items: StoreItem[]): number {
  return items.reduce((total, i) => {
    total += i.price;
    return total;
  }, 0);
}

/**
 * Main module used for storing app data.
 */
@Module({
  store,
  name: 'cart',
  dynamic: true
})
class CartModule extends VuexModule {
  id = '';
  items: StoreItem[] = [];

  /** Total charged now, including items and subscriptions. */
  get total(): number {
    return calculateTotal(this.items);
  }

  /**
   * Purchase items in cart
   */
  get cartItems(): StoreItem[] {
    return this.items.filter(i => !i.properties?.type || i.properties.type !== 'subscription');
  }

  /**
   * Flag set to `true` if cart contains standard purchase items (non-subscriptions)
   */
  get hasCartItems(): boolean {
    return this.cartItems.length > 0;
  }

  /**
   * Price total of cart items (purchaseable, non-subscription items)
   */
  get cartTotal(): number {
    return calculateTotal(this.cartItems);
  }

  /**
   * Subscription items in cart
   */
  get subscriptions(): StoreItem[] {
    return this.items.filter(i => i.properties?.type === 'subscription');
  }

  /**
   * Flag set to `true` if cart contains subscriptions
   */
  get hasSubscriptions(): boolean {
    return this.subscriptions.length > 0;
  }

  /**
   * Price total of cart items (purchaseable, non-subscription items)
   */
  get subscriptionsTotal(): number {
    return calculateTotal(this.subscriptions);
  }

  /** Text summary of the shopping cart */
  get summary(): string {
    return pluralize('items', this.items.length, true);
  }

  @Action
  async addToCart(item: StoreItem): Promise<ShoppingCart> {
    const cart = await addToCart(item);
    this.context.commit('updateCart', cart);
    return cart;
  }

  @Action
  async removeItemFromCart(item: StoreItem): Promise<ShoppingCart> {
    const cart = await removeFromCart(item.id);
    this.context.commit('updateCart', cart);
    return cart;
  }

  /**
   * Add store item to cart
   * @param item Store item
   */
  @Mutation
  public updateCart(cart: ShoppingCart | null) {
    if (!cart) {
      cart = emptyCart();
    }
    this.id = cart.id;
    this.items = cart.items;
  }
}

export default getModule(CartModule);
