import {
  Chart,
  ArcElement,
  LineElement,
  PointElement,
  LineController,
  PieController,
  LinearScale,
  TimeScale,
  Legend,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';

Chart.register(
  ArcElement,
  LineElement,
  PointElement,
  LineController,
  PieController,
  LinearScale,
  TimeScale,
  Legend,
  Title,
  Tooltip
);

(Chart.defaults as any).resizeDelay = 50;
Chart.defaults.maintainAspectRatio = false;
Chart.overrides.line.maintainAspectRatio = true;
Chart.defaults.plugins.legend = { display: false } as any;
Chart.defaults.scales.time.grid = { drawOnChartArea: false } as any;
Chart.defaults.datasets.line.tension = 0.1;
