<template>
  <div ref="container" class="messages-container" @click="showHide">
    <font-awesome-layers full-width>
      <font-awesome-icon icon="bell" />
      <font-awesome-layers-text
        class="has-background-primary has-text-black-bis unread-count"
        counter
        :value="unread"
        v-if="unread"
        position="top-right"
      >
      </font-awesome-layers-text>
    </font-awesome-layers>
    <div class="messages-list" v-show="visible">
      <ul>
        <li v-for="msg in messages" :key="msg.id">
          <font-awesome-icon
            class="has-text-primary unread-dot"
            v-if="msg.unread"
            icon="dot-circle"
          ></font-awesome-icon>
          <div class="msg-body">
            <strong>{{ msg.title }}</strong>
            <p>{{ msg.message }}</p>
            <p class="date">{{ msg.date | toDateTime }}</p>
          </div>
        </li>
      </ul>
      <div class="placeholder" v-if="messages.length === 0">
        <strong>No unread messages</strong>
        <p>
          <em>You're all caught up!</em>
        </p>
      </div>
      <div v-else>
        <b-button type="is-text" @click="clearMessages">Clear all</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import clickOutside from 'click-outside';
import { Component, Vue } from 'vue-property-decorator';
import { LarkHub } from '@/services/lark.hub';
import { UserMessage } from '@/models/user';
import app from '@/store/modules/app';

@Component
export default class MessageCenter extends Vue {
  /** Count of unread messages */
  public unread = 0;

  public messages: UserMessage[] = [];

  /** State of messages visibility. */
  public visible = false;

  public $refs!: {
    container: HTMLDivElement;
  };

  public unbindClickWatcher = () => {};

  private readonly hub: LarkHub;

  constructor() {
    super();
    this.hub = new LarkHub();
    app.setHub(this.hub);
  }

  mounted() {
    this.hub.messageReceived.subscribe((hub, msg) => this.processMessage(msg));
  }

  processMessage(msg: UserMessage) {
    msg.unread = true;
    const existingMessageIndex = this.messages.findIndex((m) => m.id === msg.id);
    if (existingMessageIndex >= 0) {
      // message exists, update it
      this.messages[existingMessageIndex] = msg;
      this.show();
    } else {
      this.messages.unshift(msg);
    }
    if (!this.visible) {
      this.unread += 1;
    }
  }

  hide() {
    this.visible = false;
    this.unread = 0;
    this.unbindClickWatcher();
  }

  show() {
    this.visible = true;
    this.unbindClickWatcher = clickOutside(this.$refs.container, () => this.hide());
  }

  showHide() {
    if (this.visible) {
      this.hide();
    } else {
      this.show();
    }
  }

  clearMessages() {
    this.messages = [];
    this.unread = 0;
  }
}
</script>

<style lang="scss" scoped>
.messages-container {
  position: relative;

  &:hover {
    color: inherit;
    background-color: inherit;
  }

  .unread-count {
    font-size: 2.5em;
    right: -6px;
    top: -9px;
  }

  .messages-list {
    position: absolute;
    top: 28px;
    right: -10px;
    min-width: 350px;
    z-index: 9999;
    background-color: #fff;
    border: 2px lightgray solid;
    border-radius: 0.2em;

    .placeholder {
      padding: 0.75em 1em;

      p {
        font-size: 0.8em;
      }
    }

    ul {
      max-height: 80vh;
      overflow-y: auto;
    }

    li {
      padding: 0.75em 0.75em 0.75em 2.5em;
      border-bottom: 1px solid gray;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        background-color: lightgrey;
      }

      .unread-dot {
        font-size: 0.6em;
        position: absolute;
        left: 1em;
      }

      .msg-body {
        display: inline-block;
      }

      p.date {
        text-align: right;
        color: gray;
        font-size: 0.7em;
        margin-bottom: 0.1em;
      }
    }
  }
}
</style>
