import { render, staticRenderFns } from "./TransferDomain.vue?vue&type=template&id=3c25ed1a&scoped=true&"
import script from "./TransferDomain.vue?vue&type=script&lang=ts&"
export * from "./TransferDomain.vue?vue&type=script&lang=ts&"
import style0 from "./TransferDomain.vue?vue&type=style&index=0&id=3c25ed1a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c25ed1a",
  null
  
)

export default component.exports