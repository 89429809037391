<template>
  <div class="container">
    <h2 class="title">Your Collections</h2>
    <b-button
      class="add-collection"
      size="is-small"
      type="is-text"
      tag="router-link"
      :to="{ name: 'site-collection' }"
      >Add collection</b-button
    >
    <div class="collection" v-for="collection in collections" :key="collection.id">
      <h3 class="title is-4">{{ collection.name }}</h3>
      <div class="columns">
        <div class="column">
          <b-button
            type="is-primary"
            outlined
            tag="router-link"
            :to="{ name: 'add-page', params: { collectionId: collection.id } }"
            >Add {{ collection.childItemName }}</b-button
          >
        </div>
        <div class="column">
          <div class="label">
            {{ $pluralize(collection.childItemName, collection.childItemsCount) }}
          </div>
          <div class="title is-4 flex is-vcentered side-button">
            {{ collection.childItemsCount }}
            <b-button
              class="add-collection"
              size="is-small"
              type="is-text"
              tag="router-link"
              :to="{ name: 'collection-details', params: { collectionId: collection.id } }"
              >View all</b-button
            >
          </div>
        </div>
        <div class="column">
          <div class="label">
            Last updated
          </div>
          <div class="title is-4">{{ collection.lastUpdated | toDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SiteRouting } from '@/mixins/SiteRouting';
import { getAllCollections } from '@/services/site-content.service';
import { SiteCollection } from '@/models/site-content';
import { Site } from '@/models/site';

/**
 * Site content home component.
 */
@Component
export default class ContentHome extends mixins(SiteRouting) {
  public messages: string[] = [];
  public siteSource = '';
  public loadingEditor = false;

  public collections: SiteCollection[] = [];

  created() {
    this.$on('site-updated', async (site: Site) => {
      this.collections = await getAllCollections(site.siteId);
    });
  }
}
</script>

<style lang="scss" scoped>
h2.title {
  margin-bottom: 0;
}

div.side-button {
  .button {
    margin-top: 0;
  }
}

.collection {
  margin: 14px;

  h3 {
    margin-bottom: 10px;
  }

  div.label {
    font-size: 0.9em;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.7;
    font-weight: 400;
    margin-bottom: 0;
  }
}
</style>
