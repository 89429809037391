import axios from '@/utilities/axios';
import { AvailableDomain, Domain, DomainTransfer, DomainRegistrationInfo } from '@/models/domains';

function serializeTlds(params: string[]) {
  let tlds = '&';
  params.forEach(p => {
    tlds += `tlds=${p}&`;
  });
  return JSON.stringify(tlds);
}

export async function search(term: string, tlds: string[]): Promise<AvailableDomain[]> {
  const response = await axios.get<AvailableDomain[]>(`domains/search/${term}`, {
    params: tlds,
    paramsSerializer: serializeTlds
  });
  return response.data;
}

/**
 * Get domains owned or managed by a user
 */
export async function getOwnedDomains(skipRenewalCheck?: boolean): Promise<Domain[]> {
  const response = await axios.get<Domain[]>('domains', {
    params: { skipRenewalCheck }
  });
  return response.data;
}

export async function transfer(domain: string, eppCode: string): Promise<DomainTransfer> {
  const response = await axios.get<DomainTransfer>(`domains/addTransfer/${domain}/${eppCode}`);
  return response.data;
}

export async function getTransfers() {
  const response = await axios.get<DomainTransfer[]>('domains/getTransfers');
  return response.data;
}

export async function getRegistrationData() {
  const response = await axios.get<DomainRegistrationInfo>('domains/registration');
  return response.data;
}
