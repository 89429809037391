import { render, staticRenderFns } from "./TemplateInput.vue?vue&type=template&id=bc269fc6&scoped=true&"
import script from "./TemplateInput.vue?vue&type=script&lang=ts&"
export * from "./TemplateInput.vue?vue&type=script&lang=ts&"
import style0 from "./TemplateInput.vue?vue&type=style&index=0&id=bc269fc6&prod&lang=scss&scoped=true&"
import style1 from "./TemplateInput.vue?vue&type=style&index=1&id=bc269fc6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_css-loader@6.8.1_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc269fc6",
  null
  
)

export default component.exports