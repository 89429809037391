<template>
  <div>
    <h3>{{ id ? 'Create New' : 'Edit' }} Site</h3>
    <form v-on:submit.prevent="saveSite">
      <section>
         <b-field label="Domains">
            <b-select
                v-if="domainOptions.length"
                multiple
                native-size="9"
                v-model="site.domains"
                required :autofocus="true">
                  <option
                    v-for="domainOption in domainOptions"
                    :value="domainOption"
                    :key="domainOption">
                    {{ domainOption }}
                </option>
            </b-select>
            <span v-else>
              No domains found.
            </span>
          </b-field>
        <b-field label="Name">
          <b-input
            type="text"
            v-model="site.name"
            message="Site name is only used for identifying your site on Lone Lark."
            required
          ></b-input>
        </b-field>
        <b-button
          type="is-primary"
          native-type="submit"
          :disabled="!site.name || !site.domains.length"
          :loading="submitting">Save</b-button>
        <router-link tag="button" class="button" to="/admin/sites">Cancel</router-link>
      </section>
    </form>
    <div>{{errorMessage}}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  Site,
  getSite,
  createSite,
  updateSite
} from '@/services/sites.service';
import { getOwnedDomains } from '@/services/domains.service';
import { success, error } from '@/services/user-messages.service';
import { emptyGuid } from '@/utilities/guid';
import { Domain } from '@/models/domains';

@Component
export default class EditSite extends Vue {
  domainOptions: string[] = [];
  public site: Site = {
    id: emptyGuid,
    domains: [],
    name: ''
  };
  public errorMessage = '';
  public newSite = true;
  public changePassword = false;
  public submitting = false;

  @Prop({ default: '' })
  public id!: string;

  public async mounted() {
    if (this.id) {
      this.newSite = false;
      this.loadSite(this.id);
    }

    this.domainOptions = ((await getOwnedDomains(true) || []) as Domain[]).map(d => d.name);
  }

  public async saveSite() {
    try {
      const save = this.newSite ? createSite : updateSite;
      this.submitting = true;
      await save(this.site);
      success(this, 'Site Saved', `Site ${this.site.name} is saved.`);
      this.submitting = false;
      this.$router.push('/admin/sites');
    } catch (err) {
      error(this, err);
    }
  }

  private async loadSite(id: string) {
    this.site = await getSite(id);
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 700px;

  button {
    margin-right: 5px;
  }
}
</style>
