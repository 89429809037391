export interface Site {
    /** Site Guid ID */
    siteId: string;
    /** Site name */
    siteName: string;
}

export enum SiteRole {
    /** Site user */
    User,
    /** Site admin */
    SiteAdmin,
    /** Lone Lark admin */
    LarkAdmin,
}
