<template>
  <div class="sites-container">
    <div class="row">
      <div class="col-md-6">
        <h1>Sites</h1>
      </div>
      <div class="col-md-6">
        <router-link tag="button" class="btn btn-primary float-right" :to="{ name: 'add-site' }">
          <font-awesome-icon icon="plus" /> Create Site
        </router-link>
      </div>
    </div>

    <table class="table" v-if="!!sites && !!sites.length">
      <thead>
        <tr>
          <th>Name</th>
          <th>Domain</th>
          <th>Added</th>
          <th colspan="2">Updated</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="site in sites" :key="site.id">
          <td>{{ site.name }}</td>
          <td>{{ site.domain }}</td>
          <td>{{ site.added | toDate }}</td>
          <td>{{ site.updated | toDate }}</td>
          <td>
            <div class="actions">
              <router-link :to="{ name: 'edit-site', params: {id: site.id } }">Edit</router-link>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { Site, getAll } from '@/services/sites.service';

@Component
export default class Sites extends Vue {
  public sites: Site[] = [];

  public mounted() {
    this.loadSites();
  }

  public async loadSites() {
    try {
      this.sites = await getAll();
    } catch (err) {
      error(this, err);
    }
  }
}
</script>

<style lang="scss" scoped>
.sites-container {
  max-width: 700px;
}

tr:hover {
  .actions {
    visibility: visible;
  }
}

.actions {
  visibility: hidden;
}
</style>
