<template>
  <div class="template-input">
    <strong v-if="value.name">{{ value.name }}</strong>
    <div v-if="type === TemplateInputType.File">
      <b-field class="file is-primary">
        <img :src="imageThumbnailPath" width="150" height="100" alt="Thumbnail" />
        <b-upload class="file-label" @input="uploadFile($event, value)">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label" v-if="!value.content.name">Click to upload</span>
          </span>
          <b-loading :active.sync="saving" :is-full-page="false"></b-loading>
          <span class="file-name" v-if="value.content.name">
            {{ value.content.name }}
          </span>
        </b-upload>
      </b-field>
    </div>
    <div v-if="type === TemplateInputType.ArrayOfInputs">
      <template-input
        class="child"
        v-for="child in value.content.items"
        :type="value.content.type"
        :value="child"
        :key="child.id"
        @change="valueUpdated"
      />
    </div>
    <div class="columns" v-if="type === TemplateInputType.LabelValue">
      <b-input
        class="column"
        type="text"
        v-model="value.label"
        @input="valueUpdated"
        placeholder="Label"
        required
      ></b-input>
      <b-input
        class="column"
        type="text"
        v-model="value.value"
        @input="valueUpdated"
        placeholder="Text"
        required
      ></b-input>
    </div>
    <div class="editor" ref="editor" v-if="type === TemplateInputType.EditorJs"></div>
    <b-taginput
      v-if="type === TemplateInputType.Tags"
      v-model="value.content.items"
      @input="valueUpdated"
    ></b-taginput>
  </div>
</template>

<script lang="ts">
import EditorJS from '@editorjs/editorjs';
import ImageTool from '@editorjs/image';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import { v4 as uuid } from 'uuid';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Asset, TemplateInput, TemplateInputType } from '@/models/site-content';
import SiteAssetService from '@/services/site-asset.service';
import app from '@/store/modules/app';
import { error } from '@/services/user-messages.service';

@Component({
  name: 'template-input'
})
export default class TemplateInputComponent extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        id: '0',
        name: '',
        type: TemplateInputType.Unknown,
        content: {}
      } as TemplateInput;
    }
  })
    value!: TemplateInput;

  @Prop({
    type: Number,
    default: 0
  })
    type!: TemplateInputType;

  private siteAssetService!: SiteAssetService;

  public editor!: EditorJS;

  public $refs!: {
    /** Div container for editor.js */
    editor: HTMLDivElement;
  };

  public get imageThumbnailPath() {
    if (this.value.content.id) {
      this.siteAssetService = this.siteAssetService ?? new SiteAssetService();
      return this.siteAssetService.getAssetPath(app.site?.siteId as string, this.value.content.id);
    }
    return '/img/file-upload.png';
  }

  /** State of async save operation */
  public saving = false;

  TemplateInputType = TemplateInputType;

  mounted() {
    if (this.type === TemplateInputType.ArrayOfInputs) {
      this.value.content.items.forEach((item: TemplateInput) => {
        if (!item.id) {
          // no ID on child item, so assign one
          item.id = uuid();
        }
      });
    }
    if (this.$refs.editor) {
      this.initializeEditor();
    }

    this.siteAssetService = new SiteAssetService();
  }

  public async uploadFile(file: File, property?: TemplateInput): Promise<Asset | undefined> {
    if (file) {
      const siteId = app.site?.siteId as string;
      try {
        this.saving = true;
        const asset = await this.siteAssetService.uploadFile(siteId, file, property?.content?.id);
        if (property) {
          // don't update property if not provided.
          // No property exists if image is embedded in editor.js
          this.$set(property, 'content', asset);
          this.valueUpdated();
        }
        return asset;
      } catch (err) {
        // file upload failed
        error(this, err, 'File upload unavailable', 'Please try again.');
      } finally {
        this.saving = false;
      }
    }
    return undefined;
  }

  public valueUpdated() {
    this.$emit('change', this.value);
  }

  private initializeEditor() {
    const component = this;
    this.editor = new EditorJS({
      holder: this.$refs.editor,
      onChange: async () => {
        this.value.content = await this.editor.save();
        this.valueUpdated();
      },
      minHeight: 150,
      onReady: () => {
        this.restoreEditorContents();
      },
      tools: {
        header: Header,
        list: List,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: async (file: File) => {
                const siteId = app.site?.siteId as string;
                const asset = (await component.uploadFile(file)) as Asset;
                return {
                  success: 1,
                  file: {
                    url: this.siteAssetService.getAssetPath(siteId, asset.id),
                    assetId: asset.id
                  }
                };
              }
            }
          }
        }
      }
    });
  }

  private async restoreEditorContents() {
    await this.editor.isReady;
    if (this.value?.content) {
      this.editor.render(this.value.content);
    }
  }
}
</script>

<style lang="scss" scoped>
span.file-name {
  max-width: 50vw;
}
</style>

<style lang="scss">
.template-input {
  margin-bottom: 0.75em;
}

.template-input.child {
  margin-bottom: -0.75em;
}

div.editor {
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }

  .codex-editor {
    .codex-editor__redactor {
      padding-bottom: 50px !important;
    }
  }
}
</style>
