<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item class="brand" tag="router-link" :to="{ name: 'dashboard', params: { siteName } }">
        <img :src="`${publicPath}img/lone-lark.png`" alt="Lone Lark" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ name: 'dashboard', params: { siteName } }"
        >Dashboard</b-navbar-item
      >
      <b-navbar-item
        tag="router-link"
        v-if="showContentLink"
        :to="{ name: 'site-content-home', params: { siteName } }"
        >Site Content</b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ name: 'reports', params: { siteName } }"
        >Reports</b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ name: 'site-users', params: { siteName } }"
        >Settings</b-navbar-item
      >
      <b-navbar-item
        v-if="currentUser && currentUser.larkAdmin"
        tag="router-link"
        :to="{ name: 'admin' }"
        >Admin</b-navbar-item
      >
    </template>

    <template slot="end">
      <b-navbar-item
        v-if="cart.items.length"
        tag="router-link"
        :to="{ name: 'cart' }"
        :title="cart.summary"
      >
        <font-awesome-layers full-width class="fa-2x">
          <font-awesome-icon icon="shopping-cart" />
          <font-awesome-layers-text
            class="has-background-primary has-text-black-bis"
            counter
            :value="cart.items.length"
            position="top-right"
          />
        </font-awesome-layers>
      </b-navbar-item>
      <b-navbar-item v-if="pageSiteSpecific && sites.length > 1">
        <b-select v-model="site">
          <option v-for="s in sites" :key="s.siteId" :value="s">{{ s.siteName }}</option>
        </b-select>
      </b-navbar-item>
      <template v-if="currentUser">
        <b-navbar-item tag="span">
          <MessageCenter />
        </b-navbar-item>
        <b-navbar-item tag="div">
          <router-link class="button is-white account" to="/account">My Account</router-link>
        </b-navbar-item>
      </template>
    </template>
  </b-navbar>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import MessageCenter from '@/components/MessageCenter.vue';
import { Site } from '@/models/site';
import { ShoppingCart } from '@/models/purchases';
import { User } from '@/models/user';
import app from '@/store/modules/app';
import cart from '@/store/modules/cart';

@Component({
  components: { MessageCenter }
})
export default class NavBar extends Vue {
  public publicPath = process.env.BASE_URL;

  /** Current site */
  get currentUser(): User {
    return app.currentUser as User;
  }

  /** Current site */
  get pageSiteSpecific(): boolean {
    return app.pageSiteSpecific;
  }

  /** Current site */
  get site(): Site {
    return app.site as Site;
  }

  set site(site: Site) {
    app.setActiveSite(site);
  }

  /** All sites user belongs to */
  get sites(): Site[] {
    return app.sites;
  }

  get siteName() {
    if (this.site) {
      return this.site.siteName;
    }
    return undefined;
  }

  get showContentLink(): boolean {
    if (this.currentUser) {
      if (this.currentUser.larkAdmin) {
        return true;
      }
      // let boo's kitchen use Site Content
      if (this.siteName?.includes('booskitchen')) {
        return true;
      }
    }
    return false;
  }

  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }
}
</script>

<style scoped lang="scss">
@import '../styles/bulma-variables';

a.account {
  margin-top: 0;
}

a.router-link-active:not(.brand) {
  // box-shadow: 0px 12px 12px -16px $primary;
  box-shadow: inset 0 -7px 11px -12px $primary;
  font-weight: bold;
  background: none !important;
}
</style>
