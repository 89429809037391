import axios from '@/utilities/axios';
import { Notification } from '@/models/notification';

export async function getNotifications(siteId: string): Promise<Notification[]> {
  const response = await axios.get<Notification[]>(`sites/${siteId}/notifications`);
  const notifications = response.data;

  notifications.forEach(notification => {
    // set updating to false (instead of undefined) so vue binding works correctly
    notification.updating = false;
  });
  return notifications;
}

export async function subscribeToNotification(siteId: string, notificationId: number): Promise<string> {
  const response = await axios.post<string>(`sites/${siteId}/notifications/${notificationId}`);
  return response.data;
}

/**
 * Unsubscribe for a user from a site notification
 * @param siteId Site ID
 * @param subscriptionId Subscription ID
 */
export function unsubscribeFromNotification(siteId: string, subscriptionId: string) {
  return axios.delete(`sites/${siteId}/notifications/${subscriptionId}`);
}
