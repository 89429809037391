<template>
  <div>
    <h3 class="title is-4">Plan Signup</h3>
    <div class="plan-info" v-if="plan">
      <p class="title is-5">{{ plan.title }}</p>
      <p class="subtitle is-5">{{ plan.price | currencyToString }} / month</p>
      <p>{{ plan.description }}</p>
    </div>
    <form v-on:submit.prevent="addPlan">
      <section>
        <b-field label="Domain">
          <b-select v-model="domain" required>
            <option v-for="d in domains" :key="d.name" :value="d">{{ d.name }}</option>
          </b-select>
        </b-field>
        <b-button v-if="inCart" tag="router-link" type="is-primary" :to="{ name: 'cart' }"
          >Proceed to checkout</b-button
        >
        <div v-else class="buttons">
          <b-button type="is-primary" native-type="submit" :loading="loading">
            Add to cart
          </b-button>
          <router-link tag="button" class="button" :to="{ name: 'user-plans' }">Cancel</router-link>
        </div>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { getOwnedDomains } from '@/services/domains.service';
import { Domain } from '@/models/domains';
import { getAvailablePlans, addPlanToCart } from '@/services/plans.service';
import cart from '@/store/modules/cart';
import { StoreItem } from '@/models/purchases';

@Component
export default class ChangePassword extends Vue {
  /** Plan being added */
  public plan: StoreItem | null = null;

  /** Selected domain */
  public domain: Domain | null = null;
  public domains: Domain[] = [];
  public loading = false;

  private get cart() {
    return cart;
  }

  public async mounted() {
    this.loadPlansAndDomains();
  }

  public addPlan() {
    addPlanToCart(this.plan as StoreItem, this.domain?.name as string);
  }

  public get inCart(): boolean {
    const exists = this.cart.items.some(
      d => d.productId === this.plan?.productId && d.properties?.domain === this.domain?.name
    );
    return exists;
  }

  private async loadPlansAndDomains() {
    try {
      this.loading = true;
      const [plans, domains] = await Promise.all([getAvailablePlans(), getOwnedDomains()]);
      if (plans.length > 0) {
        this.plan = plans[0];
      }
      this.domains = domains;
      if (this.domains.length > 0) {
        this.domain = this.domains[0];
      }
    } catch (err) {
      error(this, err, 'Domains Unavailable', 'Unable to load your domains, please try again.');
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.plan-info {
  margin-bottom: 1.5em;
}

form {
  max-width: 500px;

  button {
    margin: 10px;
  }
}
</style>
