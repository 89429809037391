import axios from '@/utilities/axios';

export interface Site {
  /** GUID site ID */
  id: string;
  domains: string[];
  name: string;
  /** Date site was added, in string format */
  added?: string;
  /** Date site was updated, in string format */
  updated?: string;
}

export async function getAll(): Promise<Site[]> {
  const response = await axios.get<Site[]>('sites');
  return response.data;
}

/** Get site details */
export async function getSite(siteId: string): Promise<Site> {
  const response = await axios.get<Site>(`sites/${siteId}`);
  return response.data;
}

/**
 * Create a new site
 */
export function createSite(site: Site) {
  return axios.post('sites', site);
}

/**
 * Update an existing site
 */
export function updateSite(site: Site) {
  return axios.post(`sites/${site.id}`, site);
}
