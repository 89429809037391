import axios from '@/utilities/axios';
import { CardSummary, ShoppingCart, ShoppingCartSummary, Invoice } from '@/models/purchases';

export interface StripePaymentIntent extends stripe.paymentIntents.PaymentIntent {
  /** Correctly-cased client string */
  clientSecret: string;
}

export interface SubscriptionPaymentResponse {
  paymentIntent: StripePaymentIntent;
}

export async function getUserOrders(): Promise<Invoice[]> {
  const { data } = await axios.get<Invoice[]>('invoices');
  return data;
}

export async function getUserReceipts(): Promise<Invoice[]> {
  const { data } = await axios.get<Invoice[]>('invoices/receipts');
  return data;
}

export async function getOrder(invoiceId: string): Promise<Invoice> {
  const { data } = await axios.get<Invoice>(`invoices/${invoiceId}`);
  return data;
}

export async function getSavedCards(): Promise<CardSummary[]> {
  const { data } = await axios.get<CardSummary[]>('payments/cards');
  return data;
}

export function addCard(paymentMethodId: string): Promise<void> {
  return axios.post(`payments/cards/${paymentMethodId}`);
}

export async function beginCheckout(cart: ShoppingCart): Promise<string> {
  const { data } = await axios.post<string>('payments/checkout', {
    id: cart.id,
    cartTotal: cart.cartTotal
  } as ShoppingCartSummary);
  return data;
}

/**
 * Creates an invoice. Returns invoice ID.
 * @param billee Who invoice is billed to.
 * @param items Item IDs in the invoice.
 */
export async function createInvoice(billee: string, items: string[]): Promise<string> {
  const { data } = await axios.post<string>('invoices', {
    billee,
    items
  });
  return data;
}

export async function beginInvoicePayment(invoiceId: string): Promise<string> {
  const { data } = await axios.post<string>(`payments/invoices/${invoiceId}`);
  return data;
}

export async function invoicePaymentComplete(invoiceId: string): Promise<any> {
  const { data } = await axios.post<string>(`payments/invoices/${invoiceId}/paid`);
  return data;
}

export async function completeCheckout(cartId: string, paymentMethodId: string): Promise<any> {
  const { data } = await axios.post(`payments/checkout/${cartId}`, {
    cartId,
    paymentMethodId
  });
  return data;
}

export async function processItem(invoice: string, item: string): Promise<any> {
  const response = await axios.post(`payments/invoices/${invoice}/${item}/process`);
  return response.data;
}

export async function confirmCardPayment(
  clientSecret: string,
  stripe: stripe.Stripe,
  paymentDetails?: stripe.ConfirmCardPaymentData
): Promise<any> {
  const result = await stripe.confirmCardPayment(clientSecret, paymentDetails);
  if (result.error) {
    console.log(result);
    throw new Error(result.error.message);
  }
  return result;
}

/**
 * Verify payment is complete or guide through error resolving
 * @param stripe Stripe instance.
 * @param payment Subscription payment response (from Stripe API on backend)
 */
export async function handleSubscriptionResult(
  stripe: stripe.Stripe,
  payment: SubscriptionPaymentResponse
): Promise<void> {
  const { paymentIntent } = payment;

  if (paymentIntent) {
    const { clientSecret, status } = paymentIntent;

    if (status === 'requires_action') {
      return confirmCardPayment(clientSecret, stripe);
    }
  }
  return Promise.resolve();
}
