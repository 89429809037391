<template>
  <div class="container">
    <h2 class="title">Pages</h2>
    <b-table stickyHeader hoverable :data="pages">
      <b-table-column field="title" label="Title" sortable v-slot="props">
        <router-link :to="{ name: 'edit-page', params: { id: props.row.id } }">
          {{ props.row.title }}
        </router-link>
      </b-table-column>
      <b-table-column field="description" label="Description" sortable v-slot="props">
        {{ props.row.description }}
      </b-table-column>
      <b-table-column field="path" label="Path" sortable v-slot="props">
        {{ props.row.path }}
      </b-table-column>
      <b-table-column field="created" label="Created" sortable v-slot="props">
        {{ props.row.created | toDate }}
      </b-table-column>
      <b-table-column v-slot="props" width="100">
        <div class="actions">
          <!-- <font-awesome-icon title="Replace file" icon="upload" /> -->
          <font-awesome-icon
            title="Delete file"
            icon="trash"
            :disabled="props.row.usageCount > 0"
            v-on:click="deletePage(props.row)"
          />
        </div>
      </b-table-column>
    </b-table>
    <div class="columns">
      <div class="column">
        <p>{{ $pluralize('pages', pages.length, true) }}</p>
      </div>
      <div class="column">
        <!-- <b-button type="is-primary" tag="router-link" :to="{ name: 'add-page', params: {} }"> -->
        <b-button type="is-primary" v-on:click="addPage()">
          Add page
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { SiteRouting } from '@/mixins/SiteRouting';
import { deletePage, getAllPages } from '@/services/site-content.service';
import { SitePage } from '@/models/site-content';
import { success } from '@/services/user-messages.service';

@Component
export default class SitePages extends mixins(SiteRouting) {
  pages: SitePage[] = [];

  created() {
    this.$on('site-updated', () => this.getPages());
  }

  addPage() {
    this.$router.replace({
      name: 'add-page',
      params: {
        myData: { b: false } as any
      }
    });
  }

  async getPages() {
    const content = await getAllPages(this.site.siteId);
    this.pages = content.pages;
    console.log(content);
  }

  async deletePage(page: SitePage) {
    await deletePage(this.site.siteId, page.id);
    success(this, 'Page deleted', `${page.title} successfully deleted.`);
    await this.getPages();
  }
}
</script>

<style scoped lang="scss">
.actions {
  svg {
    margin-right: 0.5em;
  }
}
</style>
