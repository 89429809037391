<template>
  <div class="container">
    <h1 class="title">Site Users</h1>
    <p>Add site users and edit permissions.</p>
    <span>
      <a v-if="!addUserVisible" v-on:click="setAddUserVisibility(true)">
        <font-awesome-icon icon="plus" />Add User
      </a>
    </span>
    <div class="box" v-if="addUserVisible">
      <form v-on:submit.prevent="addUserToSite(newUser.email, newUser.role)">
        <div class="columns">
          <div class="column">
            <b-field label="Email">
              <b-input type="email" v-model="newUser.email" required :autofocus="true"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Role">
              <b-select v-model="newUser.role">
                <option :value="roles.User">User</option>
                <option :value="roles.SiteAdmin">Administrator</option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="buttons">
          <b-button type="is-primary" native-type="submit" :loading="loading">Add User</b-button>
          <b-button outlined v-on:click="setAddUserVisibility(false)">Cancel</b-button>
        </div>
      </form>
    </div>
    <b-table
      ref="table"
      v-if="!!users && !!users.length"
      :data="users"
      :loading="loading"
      detailed
      :show-detail-icon="false"
    >
      <b-table-column field="name" label="Name" v-slot="props">
        <span v-if="props.row.firstName">{{ props.row.firstName + ' ' + props.row.lastName }}</span>
        <i v-else class="has-text-light">Invitation sent</i>
      </b-table-column>
      <b-table-column field="email" label="Email" v-slot="props">
        {{ props.row.email }}
      </b-table-column>
      <b-table-column field="role" label="Role" v-slot="props">
        {{ props.row.role | roleToString }}
      </b-table-column>
      <b-table-column label v-slot="props">
        <a @click="showUserActions(props.row)">Edit</a>
      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column">
            <b-select ref="roleSelect" :value="props.row.role">
              <option :value="roles.User">User</option>
              <option :value="roles.SiteAdmin">Administrator</option>
            </b-select>
          </div>
          <div class="column">
            <b-button
              type="is-primary"
              v-on:click="addUserToSite(props.row.email, $refs.roleSelect.value)"
              >Save</b-button
            >
            <b-button outlined v-on:click="$refs.table.toggleDetails(props.row)">Cancel</b-button>
          </div>
          <div class="column has-text-right">
            <b-button type="is-danger" outlined v-on:click="removeUserFromSite(props.row)"
              >Remove</b-button
            >
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { BComponent } from 'buefy/types/components';
import { SiteUser } from '@/models/user';
import { getAllBySite, addUserToSite, removeUserFromSite } from '@/services/users.service';
import { error } from '@/services/user-messages.service';
import { Site, SiteRole } from '@/models/site';
import { SiteRouting } from '@/mixins/SiteRouting';

@Component
export default class SiteUsers extends mixins(SiteRouting) {
  /** Add user section visibility */
  addUserVisible = false;

  /** if loading data, prop is `true` */
  loading = false;
  users: SiteUser[] = [];

  public roles = SiteRole;

  /** User details when adding a user to a site */
  newUser = {
    email: '',
    role: SiteRole.User
  };

  public $refs!: {
    /** BTable from Buefy doesn't have a type */
    table: BComponent;
  };

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadSiteUsers(site);
    });
  }

  async loadSiteUsers(site: Site) {
    try {
      if (!site) {
        // site not set
        return;
      }
      this.loading = true;
      this.users = await getAllBySite(site.siteId);
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }

  async addUserToSite(email: string, role: SiteRole) {
    try {
      this.loading = true;
      await addUserToSite(this.site.siteId, email, role);
      this.clearNewUserForm();
      await this.loadSiteUsers(this.site);
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }

  async removeUserFromSite(user: SiteUser) {
    try {
      this.loading = true;
      await removeUserFromSite(this.site.siteId, user.id);
      await this.loadSiteUsers(this.site);
    } catch (err) {
      error(
        this,
        err as Error,
        'Unable to Remove User',
        'Removing a user is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }

  /**
   * Show row details with user actions
   */
  showUserActions(row: any) {
    this.$refs.table.toggleDetails(row);
  }

  /** Show add user section */
  setAddUserVisibility(visibility: boolean) {
    this.addUserVisible = visibility;
  }

  private clearNewUserForm() {
    this.newUser = {
      email: '',
      role: SiteRole.User
    };
  }
}
</script>

<style lang="scss" scoped></style>
