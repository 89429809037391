<template>
  <div class="container">
    <div v-for="theme in themes" :key="theme.id">
      <h2 class="theme-title">{{ theme.name }}</h2>
      <b-carousel :indicator-inside="false">
        <b-carousel-item v-for="(assetId, i) in theme.assetIds" :key="i">
          <b-image :src="getImgUrl(assetId)"></b-image>
        </b-carousel-item>
        <template #indicators="props">
          <b-image
            class="al image"
            :src="getSmallImgUrl(theme, props.i)"
            :title="props.i"
          ></b-image>
        </template>
      </b-carousel>
    </div>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SiteRouting } from '@/mixins/SiteRouting';
import { Site } from '@/models/site';
import { getThemes } from '@/services/site-content.service';
import { SiteTheme } from '@/models/site-content';
import AppModule from '@/store/modules/app';

/**
 * Site content home component.
 */
@Component
export default class Theme extends mixins(SiteRouting) {
  public themes: SiteTheme[] = [];

  created() {
    this.$on('site-updated', (site: Site) => {
      this.getThemes(site);
    });
  }

  public async getThemes(site: Site) {
    this.themes = await getThemes(site.siteId);
  }

  public getImgUrl(assetId: string): string {
    console.log(assetId);
    if (AppModule.lonelarkSiteId) {
      return `${process.env.VUE_APP_API_URL}sites/${AppModule.lonelarkSiteId}/assets/${assetId}`;
    }
    return '';
  }

  public getSmallImgUrl(theme: SiteTheme, i: number): string {
    if (AppModule.lonelarkSiteId) {
      return `${process.env.VUE_APP_API_URL}sites/${AppModule.lonelarkSiteId}/assets/${theme.assetIds[i]}`;
    }
    return '';
  }
}
</script>

<style lang="scss" scoped>
.is-active .al img {
  filter: grayscale(0%);
}
.al img {
  filter: grayscale(100%);
}
.theme-title {
  text-align: center;
  font-weight: bold;
}
</style>
