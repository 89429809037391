import { parseISO } from 'date-fns';
import axios from '@/utilities/axios';
import { LogEntry, ServiceLogEntry } from '@/models/log';
import { SitePerformance } from '@/models/site-performance';
import { DeployRequest, SystemState, SystemStatus, SystemStatusWithState } from '@/models/admin';
import { Vm } from '@/models/vm';

/**
 * Get server event log
 */
export async function getLog(): Promise<LogEntry[]> {
  const response = await axios.get<LogEntry[]>('admin/log');
  return response.data;
}

/**
 * Get service log.
 */
export async function getServiceLog(): Promise<ServiceLogEntry[]> {
  const response = await axios.get<ServiceLogEntry[]>('admin/service-log');
  return response.data;
}

/** Get all sites recent performance. */
export async function getSitePerformance(): Promise<SitePerformance[]> {
  const response = await axios.get<SitePerformance[]>('admin/site-performance');
  return response.data;
}

export async function getVmOptions(): Promise<Vm[]> {
  const response = await axios.get<Vm[]>('vms');
  return response.data;
}

export function deploySite(deployRequest: DeployRequest) {
  return axios.post('sites/deploy-site', deployRequest);
}

/**
 * Get statuses of all Lark systems.
 */
export async function getSystemStatus(): Promise<SystemStatusWithState[]> {
  const response = await axios.get<SystemStatus[]>('admin/systems');
  return response.data.map(system => {
    const success = parseISO(system.lastSuccess);
    const err = parseISO(system.lastFailure);
    const heartbeat = parseISO(system.expectedHeartbeat);
    const state: SystemState = {
      class: 'online',
      lastRan: success,
      text: 'Online'
    };

    if (heartbeat > success && heartbeat > err) {
      state.class = 'offline';
      state.text = 'Offline';
      if (err > success) {
        state.lastRan = err;
      }
    }

    if (err > success) {
      state.lastRan = err;
      state.class = 'error';
      state.text = 'Error';
    }

    return {
      ...system,
      state
    };
  });
}
