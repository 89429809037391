<template>
  <div class="store-issues-container">
    <div class="row">
      <div class="col-md-6">
        <h1>Store Issues</h1>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr>
          <th>Invoice</th>
          <th>Title</th>
          <th>Description</th>
          <th colspan="2">Paid</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.invoice }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.paid | toDateTime }}</td>
          <td>
            <b-button class="process" type="is-primary" :loading="loading" @click="process(item)"
              >Process</b-button
            >
            <!-- <div class="actions">
              <router-link :to="{ name: 'edit-user', params: {id: user.id } }">Edit</router-link>
            </div> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { getUnprocessedItems } from '@/services/site-store.service';
import { StoreItemWithInvoice } from '@/models/purchases';
import { processItem } from '@/services/payments.service';

@Component
export default class StoreIssues extends Vue {
  public items: StoreItemWithInvoice[] = [];

  public loading = true;

  public mounted() {
    this.loadItems();
  }

  public async loadItems() {
    try {
      this.loading = true;
      this.items = await getUnprocessedItems();
    } catch (err) {
      error(this, err);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Process a purchased item.
   */
  public async process(item: StoreItemWithInvoice) {
    try {
      this.loading = true;
      await processItem(item.invoice, item.id);
      await this.loadItems();
    } catch (err) {
      error(this, err);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
tr:hover {
  .process {
    visibility: visible;
  }
}

.process {
  visibility: hidden;
}
</style>
