<template>
  <div class="container">
    <h1 class="title">Billing</h1>
    <h2>Cards</h2>
    <b-table :data="cards" :loading="loading">
      <b-table-column label="Brand" v-slot="props">{{ props.row.brand }}</b-table-column>
      <b-table-column label="Last 4 Digits" v-slot="props">
        {{ props.row.lastFourDigits }}
      </b-table-column>
      <b-table-column label="Expires" v-slot="props">{{ props.row.expires }}</b-table-column>
      <b-table-column>
        <!-- <b-button
            type="is-text"
            v-if="expiringSoon(props.row)"
            v-on:click="addToCart(props.row)"
          >Renew</b-button>-->
      </b-table-column>
    </b-table>
    <div class="box add-card" v-if="addingCard">
      <h6 class="title is-6">Add New Card</h6>
      <CreditCard ref="creditCard" actionText="Save card" v-on:card-added="cardAdded" />
    </div>
    <b-button type="is-text" v-if="!addingCard" v-on:click="addingCard = true"
      >Add new card</b-button
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import CreditCard from '@/components/CreditCard.vue';
import { getSavedCards, addCard } from '@/services/payments.service';
import { error } from '../../services/user-messages.service';
import { CardSummary } from '../../models/purchases';

@Component({
  components: { CreditCard }
})
export default class UserBilling extends Vue {
  public loading = false;
  public addingCard = false;

  /** Saved credit/debit cards. */
  public cards: CardSummary[] = [];

  mounted() {
    this.getPaymentMethods();
  }

  public async getPaymentMethods() {
    try {
      this.loading = true;
      this.cards = await getSavedCards();
    } catch (err) {
      error(
        this,
        err,
        'Payment Methods Unavailable',
        'Unable to retrieve saved payment methods. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }

  public async cardAdded(paymentMethodId: string) {
    try {
      this.loading = true;
      await addCard(paymentMethodId);
      this.addingCard = false;
      this.getPaymentMethods();
    } catch (err) {
      error(
        this,
        err,
        'Adding Cards Unavailable',
        'Unable to add a payment card. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin: 1em 0;
}

table {
  td {
    vertical-align: middle;
  }
}

a.button {
  margin-top: 1.5em;
}

div.continue {
  display: flex;
  align-items: center;
  margin: 1.5em 0;

  span {
    margin-right: 1.5em;
  }
}

.add-card {
  max-width: 500px;
}
</style>
