// import axios from '@/utilities/axios';

export function getEditorUrl(sessionId: string) {
  if (process.env.NODE_ENV === 'development') {
    const url = new URL(process.env.VUE_APP_API_URL as string);
    const previewUrl = `${url.protocol}//${sessionId}.localhost:${url.port}`;
    return previewUrl;
  }
  return `http://${sessionId}.preview.lonelark.com`;
}
