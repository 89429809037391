<template>
  <div class="container">
    <h1 class="title">Deploy a Site</h1>
    <form v-on:submit.prevent="deploySite">
      <section>
        <b-field label="VM">
          <b-select v-model="selectedVmId" placeholder="Select a VM" required expanded>
            <option v-for="vm in vmOptions" :key="vm.id" :value="vm.id">{{ vm.name }}</option>
          </b-select>
        </b-field>
        <b-field label="Deployable Sites">
          <b-select v-model="selectedSite" placeholder="Select a site" required expanded>
            <option v-for="site in deployableSites" :key="site.id" :value="site">
              {{ site.name }}
            </option>
          </b-select>
        </b-field>
        <b-button type="is-primary" native-type="submit" :loading="deploying">Deploy 💥</b-button>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getAll, Site } from '@/services/sites.service';
import { deploySite, getVmOptions } from '@/services/admin.service';
import { DeployRequest } from '@/models/admin';
import { Vm } from '@/models/vm';
import { success, error } from '@/services/user-messages.service';

@Component
export default class Deploy extends Vue {
  public vmOptions: Vm[] = [];
  public deployableSites: Site[] = [];
  public selectedVmId: number | null = null;
  public selectedSite: Site | null = null;
  public deploying = false;

  async mounted() {
    this.vmOptions = (await getVmOptions()) || [];
    this.deployableSites = (await getAll()) || [];
  }

  public async deploySite() {
    try {
      if (!this.selectedSite || !this.selectedVmId) return;
      const deployRequest: DeployRequest = {
        vmId: this.selectedVmId!,
        siteId: this.selectedSite.id
      };
      this.deploying = true;
      await deploySite(deployRequest);
      success(this, 'Deploy successful', `Successfully deployed ${this.selectedSite.name}`);
    } catch (err) {
      error(this, err, 'Deploy failed', 'Please contact the @cashman');
    } finally {
      this.deploying = false;
    }
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 500px;
}
</style>
