<template>
  <div class="my-account container">
    <h1 class="title">My Account</h1>
    <div class="columns">
      <div class="column sidebar">
        <div class="user-image">{{ initials }}</div>
        <ul class="actions">
          <router-link tag="li" :to="{ name: 'user-domains' }">Domains</router-link>
          <router-link tag="li" :to="{ name: 'user-plans' }">Plans</router-link>
          <router-link tag="li" :to="{ name: 'user-receipts' }">Receipts</router-link>
          <router-link tag="li" :to="{ name: 'user-billing' }">Billing Details</router-link>
          <router-link tag="li" :to="{ name: 'user-address' }">Address</router-link>
          <router-link tag="li" :to="{ name: 'change-password' }">Change Password</router-link>
          <li v-on:click="logout">Logout</li>
        </ul>
      </div>
      <div class="column">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { logout as authLogout } from '@/services/auth.service';
import app from '@/store/modules/app';
import { User } from '@/models/user';

@Component
export default class AccountLayout extends Vue {
  public async logout() {
    await authLogout();
    this.$router.push('/login');
  }

  get initials(): string {
    const user = app.currentUser as User;
    if (!user) {
      return '';
    }
    return user.firstName[0] + user.lastName[0];
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/bulma-variables';

.sidebar {
  flex: none;
  width: 14em;
}

.user-image {
  border: 5px solid $primary;
  color: $light;
  padding: 10px 20px;
  font-size: 50px;
  display: inline-block;
  margin-bottom: 15px;
}

ul.actions {
  li {
    border-bottom: 1px solid transparent;

    &.router-link-active {
      font-weight: bold;
    }

    &:hover {
      cursor: pointer;
      border-bottom-color: lightgray;
    }
  }
}
</style>
