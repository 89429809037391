<template>
  <div class="container" v-if="collection.id">
    <h2 class="title">{{ collection.name }}</h2>
    <p>{{ $pluralize(collection.childItemName, collection.items.length, true) }}</p>
    <b-table :data="collection.items" :loading="loading">
      <b-table-column label="Title" v-slot="props">
        {{ props.row.title }}
      </b-table-column>
      <b-table-column label="Description" v-slot="props">
        {{ props.row.description }}
      </b-table-column>
      <b-table-column v-slot="props">
        <b-button
          outlined
          tag="router-link"
          :to="{
            name: 'edit-collection-item',
            params: { collectionId, siteName, id: props.row.id }
          }"
        >
          Edit page
        </b-button>
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { SiteRouting } from '@/mixins/SiteRouting';
import { getCollection } from '@/services/site-content.service';
import { error, warn } from '@/services/user-messages.service';
import { newCollection, SiteCollection } from '@/models/site-content';
import { Site } from '@/models/site';

@Component
export default class CollectionDetails extends mixins(SiteRouting) {
  @Prop(Number)
  public readonly collectionId!: number;

  public collection: SiteCollection = newCollection;

  public loading = false;

  created() {
    this.$on('site-updated', (site: Site) => {
      this.loadCollection(site);
    });
  }

  private async loadCollection(site: Site) {
    try {
      const collection = await getCollection(site.siteId, this.collectionId, true);
      if (!collection) {
        warn(this, 'Collection not found', 'This collection does not exist.');
        this.$router.push({ name: 'site-content-home', params: { siteName: site.siteName } });
        return;
      }
      this.collection = collection;
    } catch (err) {
      error(this, err as Error, 'Collection unavailable, please try again.');
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.button {
  margin-top: 10px;
}

.details {
  .title.post-title {
    margin-bottom: 0;
  }

  .edit-details {
    padding: 0.7em 1em 1.2em 1em;
    border-radius: 0.3em;

    .columns {
      margin-bottom: 0;
    }

    .image-upload {
      max-width: 450px;
    }
  }
}

div.editor {
  // min-height: 300px;
  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }
}
</style>
