/* global Stripe */

import { Component, Vue } from 'vue-property-decorator';

/**
 * SiteRouting exposes functionality to add site routing to any page.
 * Allows for /sites/domain.com and /settings/domain.com
 */
@Component
export class StripeIntegrated extends Vue {
  /** Stripe client public key */
  private readonly publicStripeKey = process.env.VUE_APP_STRIPE_PK as string;

  public stripe!: stripe.Stripe;

  mounted() {
    this.loadStripeCheckout();
  }

  loadStripeCheckout() {
    if (window.Stripe) {
      console.log('stripe already loaded', window.Stripe);
      // stripe already loaded
      this.stripeLoaded();
      return;
    }
    const e = document.createElement('script');
    e.src = 'https://js.stripe.com/v3/';
    e.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(e);
    e.addEventListener('load', this.stripeLoaded);
  }

  stripeLoaded() {
    this.stripe = Stripe(this.publicStripeKey);
    this.$emit('stripe-loaded');
  }

  createCardElement(cardOutlet: HTMLElement): stripe.elements.Element {
    const elements = this.stripe.elements();
    const cardElement = elements.create('card');
    cardElement.mount(cardOutlet);
    return cardElement;
  }
}
