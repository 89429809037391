<template>
  <b-button v-if="cart.items.length" type="is-primary" tag="router-link" :to="{ name: 'checkout' }"
    >Checkout</b-button
  >
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import cart from '@/store/modules/cart';

/** View before checkout */
@Component
export default class PreCheckout extends Vue {
  readonly cart = cart;
}
</script>
