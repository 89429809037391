<template>
  <div>
    <h1 class="title is-3">Create Invoice</h1>
    <form v-on:submit.prevent="createInvoice">
      <section>
        <b-field label="User to bill">
          <b-autocomplete
            v-model="searchedUser"
            :data="filteredUsers"
            :custom-formatter="userToString"
            required
            icon="user"
            keep-first
            @select="option => user = option"
          >
            <template slot="empty">No user found.</template>
            <template slot-scope="props">{{ props.option.firstName }} {{ props.option.lastName }}</template>
          </b-autocomplete>
        </b-field>
        <b-message title="Add Item" :closable="false">
          <b-autocomplete
            v-model="searchedItem"
            :data="filteredItems"
            :clear-on-select="true"
            placeholder="Search item titles"
            field="title"
            keep-first
            @select="addItemToInvoice"
          >
            <template slot="empty">No store item found.</template>
            <template slot-scope="props">
              <div>
                <strong>{{ props.option.title }}</strong>
                {{ props.option.price | currencyToString }}
              </div>
              <div>{{ props.option.description }}</div>
            </template>
          </b-autocomplete>
        </b-message>
        <h2 class="title is-5">Invoice Items</h2>
        <div class="item" v-for="item in items" :key="item.id">
          <div>
            <strong>{{ item.title }}</strong>
            {{ item.price | currencyToString }}
          </div>
          <p>{{ item.description }}</p>
        </div>
        <div>
          <div>{{ $pluralize('item', items.length, true) }}</div>
          <span>Total: {{ total | currencyToString }}</span>
        </div>
        <b-button type="is-primary" native-type="submit" :loading="loading">Create Invoice</b-button>
        <router-link tag="button" class="button" :to="{ name: 'admin'}">Cancel</router-link>
      </section>
    </form>
    <div>{{errorMessage}}</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { User, newUser } from '@/models/user';
import { SiteRole } from '@/models/site';
import { getAll } from '@/services/users.service';
import { success, error } from '@/services/user-messages.service';
import { emptyGuid } from '@/utilities/guid';
import { StoreItem } from '@/models/purchases';
import { getAllItems } from '@/services/site-store.service';
import app from '@/store/modules/app';
import { createInvoice } from '@/services/payments.service';

@Component
export default class CreateInvoice extends Vue {
  public users: User[] = [];
  public user!: User;

  /** Invoice items. */
  public items: StoreItem[] = [];
  public storeItems: StoreItem[] = [];
  public searchedUser = '';
  public searchedItem = '';
  public errorMessage = '';
  public loading = false;

  public async mounted() {
    const [users, items] = await Promise.all([
      getAll(),
      getAllItems(app.site?.siteId as string)
    ]);
    this.users = users;
    this.storeItems = items;
  }

  get filteredUsers(): User[] {
    const searchTerm = this.searchedUser.toLowerCase();
    return this.users.filter(user => {
      const name = `${user.firstName} ${user.lastName}`;
      return name.toLowerCase().indexOf(searchTerm) >= 0;
    });
  }

  get filteredItems(): StoreItem[] {
    const searchTerm = this.searchedItem.toLowerCase();
    return this.storeItems.filter(item => {
      const product = `${item.title} ${item.description}`;
      return product.toLowerCase().indexOf(searchTerm) >= 0;
    });
  }

  get total(): number {
    return this.items.reduce(
      (total: number, item: StoreItem) => total + item.price,
      0
    );
  }

  public userToString(user: User) {
    return `${user.firstName} ${user.lastName}`;
  }

  public addItemToInvoice(item: StoreItem) {
    if (item) {
      this.items.push(item);
    }
  }

  public async createInvoice() {
    try {
      const invoiceId = await createInvoice(
        this.user.id,
        this.items.map(i => i.id)
      );
      this.$router.push({ name: 'user-order', params: { invoiceId } });
    } catch (err) {
      error(this, err, 'Invoice Creation Error', 'Error creating invoice.');
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  // max-width: 700px;

  button {
    margin-right: 5px;
  }
}

div.item {
  margin-bottom: 15px;
}
</style>
