<template>
  <div class="container">
    <h3>{{ newItem ? 'Add' : 'Edit' }} store item</h3>
    <form v-on:submit.prevent="saveItem">
      <div class="columns">
        <div class="column">
          <section>
            <b-field label="Product ID">
              <b-input type="text" v-model="item.productId" required :autofocus="true"></b-input>
            </b-field>
            <b-field label="Product title">
              <b-input type="text" v-model="item.title" required></b-input>
            </b-field>
            <b-field label="Product description">
              <b-input type="textarea" v-model="item.description" required></b-input>
            </b-field>
            <b-field label="Price">
              <b-input type="number" v-model="item.price" required></b-input>
            </b-field>
            <b-button type="is-primary" native-type="submit" :loading="loading">Save</b-button>
            <router-link
              tag="button"
              class="button"
              :to="{ name: 'site-store', params: { siteName: site.siteName } }"
              >Cancel</router-link
            >
          </section>
        </div>
        <div class="column">
          <h4>Properties</h4>
          <ul v-for="[key, value] in itemProperties" :key="key">
            <li>{{ key }}: {{ value }}</li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { error, success } from '@/services/user-messages.service';
import { SiteRouting } from '@/mixins/SiteRouting';
import { StoreItem } from '@/models/purchases';
import { getItem, saveItem } from '@/services/site-store.service';

@Component
export default class EditSite extends mixins(SiteRouting) {
  public item: StoreItem = {
    // ID is generated server-side on save
    id: '',
    title: '',
    price: 0,
    productId: '',
    description: ''
  };

  public itemProperties: [string, string][] = [];

  public newItem = true;

  /** Flag set to true when waiting on a network request */
  public loading = false;

  @Prop({ default: '' })
  public id!: string;

  public mounted() {
    if (this.id) {
      this.newItem = false;
      this.loadItem(this.id);
    }
  }

  public async saveItem() {
    try {
      this.loading = true;
      const id = this.item.id;
      const item = await saveItem(this.site.siteId, this.item);
      if (id !== item.id) {
        // id changing means it was a first save, so update URL
        this.$router.push({
          name: 'edit-store-item',
          params: {
            id: item.id,
            siteName: this.siteName!
          }
        });
      }
      this.item = item;
      success(this, 'Item saved', `${this.item.title} saved.`);
    } catch (err) {
      error(this, err as Error);
    } finally {
      this.loading = false;
    }
  }

  private async loadItem(id: string) {
    try {
      this.loading = true;
      this.item = await getItem(this.site.siteId, id);
      this.itemProperties = Object.entries(this.item.properties || {});
    } catch (err) {
      error(
        this,
        err as Error,
        'Store Item Unavailable',
        'Unable to load store item, please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin-right: 5px;
}
</style>
