<template>
  <form v-on:submit.prevent="stripeFormSubmitted">
    <div class="payment-container" ref="stripeOutlet"></div>
    <b-loading :active.sync="loading" :is-full-page="false"></b-loading>
    <b-button
      v-if="showSubmitButton"
      type="is-primary"
      native-type="submit"
      :loading="submitting"
      >{{ actionText }}</b-button
    >
    <b-message v-if="showErrorMessage" type="is-warning">{{ paymentError }}</b-message>
  </form>
</template>

<script lang="ts">
/* global stripe */
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { error } from '@/services/user-messages.service';
import app from '@/store/modules/app';
import { User } from '@/models/user';
import { StripeIntegrated } from '@/mixins/StripeIntegrated';

@Component
export default class CreditCard extends mixins(StripeIntegrated) {
  @Prop({
    type: String,
    default: 'Submit'
  })
    actionText!: string;

  @Prop({
    type: Boolean,
    default: true
  })
    showSubmitButton!: boolean;

  public $refs!: {
    stripeOutlet: HTMLDivElement;
  };

  /** Current user */
  get currentUser(): User {
    return app.currentUser as User;
  }

  /** Validity status of payment */
  paymentError = 'Please enter your payment information.';
  showErrorMessage = false;

  /** Flag set to `true` if payment is submitting. */
  submitting = false;

  /** Flag set to `true` if Stripe controls are loading. */
  loading = true;

  cardElement!: stripe.elements.Element;

  created() {
    this.$on('stripe-loaded', () => {
      this.cardElement = this.createCardElement(this.$refs.stripeOutlet);
      this.loading = false;
      this.cardElement.addEventListener(
        'change',
        (change?: stripe.elements.ElementChangeResponse) => {
          const err = change?.error?.message;
          if (err) {
            this.paymentError = err;
          } else {
            // clear error message
            this.showErrorMessage = false;
            this.paymentError = '';
          }
        }
      );
    });
  }

  async stripeFormSubmitted(): Promise<string | null> {
    try {
      if (this.paymentError) {
        // payment not valid
        this.showErrorMessage = true;
        return null;
      }
      this.submitting = true;
      const paymentMethodId = await this.createStripePaymentMethod();
      this.$emit('card-added', paymentMethodId);
      return paymentMethodId;
    } catch (err) {
      if (!this.paymentError) {
        // only show error toast if payment error not set
        error(this, err, 'Error processing payment');
      }
      return null;
    } finally {
      this.submitting = false;
    }
  }

  async createStripePaymentMethod(): Promise<string> {
    const result = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        email: this.currentUser.email
      }
    });
    if (result.error) {
      this.paymentError = result.error.message as string;
      this.showErrorMessage = true;
    }
    return result.paymentMethod!.id;
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/bulma-variables';

button {
  margin: 1em 0;
}

form {
  margin-bottom: 1em;
}

.payment-container {
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  padding: 10px 10px;
}
</style>
