/**
 * Promise that resolves in a given number of milliseconds. A promise-based setTimeout().
 * @param milliseconds Milliseconds to wait.
 * If you are confused, come find me.
 *      - Holo
 */
export function wait(milliseconds: number) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

export function setPageTitle(title: string) {
  const formattedTitle = title ? `${title} | Lone Lark` : 'Lone Lark';
  document.title = formattedTitle;
}
