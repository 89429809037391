<template>
  <div class="my-account container">
    <h1 class="title">Site Editor</h1>
    <div class="buttons">
      <b-button type="is-primary" v-on:click="startSession">Start Editor</b-button>
      <b-button type="is-light" v-on:click="stopSession">Stop Editor</b-button>
      <b-button type="is-success" outlined v-on:click="deploy">Deploy</b-button>
    </div>
    <ul>
      <li v-for="message in messages" :key="message">{{ message }}</li>
    </ul>
    <div class="editor">
      <b-loading :active.sync="loadingEditor"></b-loading>
    </div>
    <iframe :src="siteSource" @load="editorLoaded" frameborder="0" title="Editor"></iframe>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { LarkHub } from '@/services/lark.hub';
import { Site } from '@/models/site';
import { getEditorUrl } from '@/services/site-editor.service';
import app from '@/store/modules/app';
import { SiteRouting } from '@/mixins/SiteRouting';

@Component
export default class SiteEditor extends mixins(SiteRouting) {
  public messages: string[] = [];
  public siteSource = '';
  public loadingEditor = false;

  /** Current site */
  get site(): Site {
    return app.site as Site;
  }

  private hub: LarkHub;

  constructor() {
    super();
    this.hub = app.hub;
  }

  editorSessionStarted(sessionId: string) {
    this.messages.push(`${new Date().toLocaleTimeString()} Session ${sessionId} started.`);
    this.siteSource = getEditorUrl(sessionId);
  }

  editorLoaded() {
    this.loadingEditor = false;
  }

  /** Start editor session */
  startSession() {
    const { siteId } = this.site;
    this.hub.startSession(siteId);
    this.loadingEditor = true;
    this.messages.push(`${new Date().toLocaleTimeString()} Starting session for site ${siteId}.`);
  }

  /** Start editor session */
  stopSession() {
    const { siteId } = this.site;
    this.hub.endSession(siteId);
    this.messages.push(`${new Date().toLocaleTimeString()} Session ended.`);
  }

  deploy() {
    const { siteId } = this.site;
    this.hub.deploySite(siteId);
    this.messages.push(`${new Date().toLocaleTimeString()} Site deploy started.`);
  }
}
</script>

<style lang="scss" scoped>
ul.actions {
  li:hover {
    cursor: pointer;
    background-color: lightgray;
  }
}

iframe {
  width: 100%;
  min-height: 400px;
}
</style>
