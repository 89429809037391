import axios from '@/utilities/axios';
import { ShoppingCart, StoreItem } from '@/models/purchases';

/**
 * Get user's current shopping cart
 */
export async function getCart(): Promise<ShoppingCart> {
  const response = await axios.get<ShoppingCart>('cart');
  return response.data;
}

export async function addToCart(item: StoreItem): Promise<ShoppingCart> {
  const response = await axios.post<ShoppingCart>('cart', item);
  return response.data;
}

export async function removeFromCart(cartItemId: string): Promise<ShoppingCart> {
  const response = await axios.delete<ShoppingCart>(`cart/${cartItemId}`);
  return response.data;
}
