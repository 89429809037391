<template>
  <div class="container">
    <h1 class="title">Create Your Account</h1>
    <form v-on:submit.prevent="saveUser">
      <section>
        <b-field label="Email">
          <b-input type="email" v-model="user.email" disabled></b-input>
        </b-field>
        <b-field label="First Name">
          <b-input type="text" v-model="user.firstName" required></b-input>
        </b-field>
        <b-field label="Last Name">
          <b-input type="text" v-model="user.lastName" required></b-input>
        </b-field>
        <b-field label="New Password">
          <b-input type="password" v-model="user.password" required></b-input>
        </b-field>
        <b-field label="Confirm Password">
          <b-input type="password" v-model="passwordConfirmed" required></b-input>
        </b-field>
        <b-button type="is-primary" native-type="submit" :loading="loading">Create Account</b-button>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { completeUserSetup, getInvitedUser } from '@/services/auth.service';
import { success, error } from '@/services/user-messages.service';
import { User, newUser } from '@/models/user';

@Component
export default class NewUserWelcome extends Vue {
  /** New user token, provided by link emailed to user */
  @Prop(String) readonly token!: string;

  /** New user details */
  public user: User = newUser();

  public passwordConfirmed = '';
  public loading = false;

  public async mounted() {
    if (this.token) {
      const { id, email } = await getInvitedUser(this.token);
      this.user.id = id;
      this.user.email = email;
    }
  }

  public async saveUser() {
    try {
      if (this.user.password !== this.passwordConfirmed) {
        error(this, null, 'Verify Password', 'Passwords do not match.');
        return;
      }
      this.loading = true;

      await completeUserSetup(this.token, this.user);
      success(this, 'Account Created!', 'Your account has been successfully created. Welcome to Lone Lark 😃');
      this.$router.push('/');
    } catch (err) {
      error(
        this,
        err as Error,
        'Error Creating Account',
        'Unable to successfully create an account. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 500px;
}
</style>
