<template>
  <div class="info-header">
    <component :is="`h${level}`">
      <slot></slot>
      <b-tooltip v-if="message" :label="message" :delay="75" :position="position">
        <b-icon icon="info-circle" type="is-primary" size="is-small" />
      </b-tooltip>
    </component>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'header-with-info'
})
export default class HeaderWithInfo extends Vue {
  /**
   * Header tag level (1 == h1, 2 == h2, etc)
   */
  @Prop({
    type: Number,
    default: 2
  })
    level!: number;

  /**
   * Descriptive text shown on info icon tooltip
   */
  @Prop({
    type: String,
    default: ''
  })
    message!: number;

  /** Tooltip opening position */
  @Prop({
    type: String,
    default: 'is-top'
  })
    position!: string;
}
</script>

<style lang="scss">
.info-header {
  h1,
  h2,
  h3,
  h4,
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.b-tooltip {
  margin-left: 0.5em;
}
</style>
