<template>
  <div class="container">
    <h1 class="title">Site Content</h1>
    <!--
      TODO: pull this out into its own secondary nav.
      Will be a mix between a navbar and breadcrumbs.
     -->
    <b-navbar>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ name: 'site-pages', params: { siteName } }"
          >Pages</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'site-collections', params: { siteName } }"
          >Collections</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'site-files', params: { siteName } }"
          >Files</b-navbar-item
        >
      </template>
    </b-navbar>
    <router-view />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import app from '@/store/modules/app';

@Component
export default class ContentLayout extends Vue {
  get siteName() {
    return app.site?.siteName;
  }
}
</script>

<style scoped lang="scss"></style>
