import { BSnackbarConfig } from 'buefy/types/components';

const defaultSnack: BSnackbarConfig = {
  duration: 5000,
  type: 'is-success',
  position: 'is-bottom',
  actionText: 'OK',
  message: 'Thank you for choosing Lone Lark.',
  queue: true
};

export function success(component: Vue, title: string, message: string) {
  component.$buefy.snackbar.open({
    ...defaultSnack,
    duration: 4000,
    message: `<h4 class="title is-5 has-text-white" style="margin-bottom:0.25em">${title}</h4><p>${message}</p>`
  });
}

/** Show warning message */
export function warn(component: Vue, title: string, message: string) {
  component.$buefy.snackbar.open({
    ...defaultSnack,
    type: 'is-warning',
    message: `<h4 class="title is-5 has-text-white" style="margin-bottom:0.25em">${title}</h4><p>${message}</p>`
  });
}

export function error(component: Vue, err: Error | unknown | null = null, title = 'Error', message = '') {
  if (err) {
    console.error(err);
  }
  component.$buefy.snackbar.open({
    ...defaultSnack,
    type: 'is-danger',
    message: `<h5 class="title is-5 has-text-white" style="margin-bottom:0.25em">${title}</h5><p>${message}</p>`
  });
}
