import Vue from 'vue';
import { SiteRole } from '@/models/site';
import { TransferDomainState } from '@/models/domains';

Vue.filter('roleToString', (role: SiteRole) => {
  switch (role) {
    case SiteRole.User:
      return 'User';
    case SiteRole.SiteAdmin:
      return 'Admin';
    case SiteRole.LarkAdmin:
      return 'Lone Lark Admin';
    default:
      console.warn('Site role to string not yet implemented.');
      return '';
  }
});

Vue.filter('transferStateToString', (state: TransferDomainState) => {
  switch (state) {
    case TransferDomainState.PaymentPending:
      return 'Awaiting payment';
    case TransferDomainState.Complete:
      return 'Transfer complete';
    case TransferDomainState.Error:
      return 'Transfer error';
    case TransferDomainState.InProgress:
      return 'Transfer pending';
    case TransferDomainState.Unknown:
      return '';
    default:
      console.warn('Site role to string not yet implemented.');
      return '';
  }
});
