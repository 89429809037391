<template>
  <div class="log-viewer">
    <h2 class="title">
      Log Viewer
      <button
        class="button is-info is-light"
        v-on:click="loadLog"
        title="Refresh log"
        :disabled="loading"
        type="button"
      >
        <font-awesome-icon icon="sync" :spin="loading" />
      </button>
    </h2>
    <b-table
      ref="table"
      v-if="!!log && !!log.length"
      :data="log"
      :loading="loading"
      :mobile-cards="false"
      detailed
      :show-detail-icon="false"
    >
      <b-table-column field="date" label="Date" v-slot="props">
        {{ props.row.date | toDateTime }}
      </b-table-column>
      <b-table-column field="level" label="Level" v-slot="props">
        <span :title="props.row.level">
          <font-awesome-icon
            class="error"
            v-if="props.row.level === 'ERROR'"
            icon="exclamation-circle"
          />
          <font-awesome-icon
            class="info"
            v-else-if="props.row.level === 'INFO'"
            icon="info-circle"
          />
          <font-awesome-icon class="debug" v-else-if="props.row.level === 'DEBUG'" icon="debug" />
          <span v-else>{{ props.row.level }}</span>
        </span>
      </b-table-column>
      <b-table-column field="logger" label="Logger" v-slot="props">
        {{ props.row.logger }}
      </b-table-column>
      <b-table-column label v-slot="props">
        <a @click="$refs.table.toggleDetails(props.row)" title="View stack trace" aria-label="Show trace">
          <font-awesome-icon icon="toilet-paper" />
        </a>
      </b-table-column>
      <b-table-column field="message" label="Message" v-slot="props">
        {{ props.row.message }}
      </b-table-column>
      <b-table-column field="exception" label="Exception" v-slot="props">
        {{ props.row.exception }}
      </b-table-column>

      <template #detail="props">
        <div>
          <strong>Exception</strong>
          <span>{{ props.row.exception }}</span>
        </div>
        <pre>{{ props.row.stackTrace }}</pre>
        <div class="buttons">
          <b-button type="is-info" v-on:click="$refs.table.toggleDetails(props.row)">
            <font-awesome-icon icon="times-circle"></font-awesome-icon>Hide
          </b-button>
          <b-button type="is-primary" outlined v-on:click="copyStack(props.row)"
            >Copy stack trace</b-button
          >
        </div>
      </template>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { BComponent } from 'buefy/types/components';
import { LogEntry } from '@/models/log';
import { error } from '@/services/user-messages.service';
import { getLog } from '@/services/admin.service';

@Component
export default class WebLogViewer extends Vue {
  public log: LogEntry[] = [];
  public loading = true;

  public $refs!: {
    table: BComponent;
  };

  public mounted() {
    this.loadLog();
  }

  public async loadLog() {
    try {
      this.loading = true;
      this.log = await getLog();
    } catch (err) {
      error(this, err);
    } finally {
      this.loading = false;
    }
  }

  public async copyStack(entry: LogEntry) {
    try {
      await navigator.clipboard.writeText(entry.stackTrace);
    } catch (err) {
      console.error('Unable to copy stack.', err);
    }
  }
}
</script>

<style>
/* Global styles */
.table-wrapper {
  overflow: auto;
}
</style>

<style lang="scss" scoped>
.detail-container {
  strong {
    padding-right: 0.5em;
  }

  pre {
    padding: 0.5em;
  }
}

button {
  margin-left: 10px;

  svg {
    margin-right: 5px;
  }
}

.buttons {
  margin-top: 15px;
}

.log-viewer {
  max-width: 95vw;
}

svg.error {
  color: #ff3860;
}

svg.info {
  color: #bfd7ea;
}

svg.debug {
  color: #878787;
}
</style>
