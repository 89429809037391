<template>
  <div class="container">
    <domain-search actionText="View in cart"></domain-search>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ShoppingCart } from '@/models/purchases';
import cart from '@/store/modules/cart';
import DomainSearch from '@/components/DomainSearch.vue';

@Component({
  components: { DomainSearch }
})
export default class AddDomain extends Vue {
  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }

  mounted() {
    // set step index
    this.$emit('step-changed', 1);
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 400px;

  button {
    margin-left: 10px;
  }
}

.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin-top: 1em;
}

table {
  td {
    vertical-align: middle;
  }

  span.tag {
    // premium tag
    margin-right: 10px;
  }
}

div.continue {
  display: flex;
  align-items: center;
  margin: 1.5em 0;

  span {
    margin-right: 1.5em;
  }
}
</style>
