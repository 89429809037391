import Vue from 'vue';
import pluralize from 'pluralize';

const pluralizePlugin = {
  install() {
    Vue.prototype.$pluralize = pluralize;
  }
};

Vue.use(pluralizePlugin);
