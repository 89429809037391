<template>
  <div>
    <h3 class="title is-4">Change Password</h3>
    <form v-on:submit.prevent="changePassword">
      <section>
        <b-field label="New Password">
          <b-input
            type="password"
            v-model="password"
            required
            placeholder="New password"
            :autofocus="true"
          ></b-input>
        </b-field>
        <b-field label="Confirm New Password">
          <b-input
            type="password"
            v-model="passwordConfirmed"
            required
            placeholder="Confirm new password"
          ></b-input>
        </b-field>
        <b-button type="is-primary" native-type="submit" :loading="saving">Save</b-button>
        <router-link tag="button" class="button" to="/account">Cancel</router-link>
      </section>
    </form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as AuthService from '@/services/auth.service';
import { success, error } from '@/services/user-messages.service';

@Component
export default class ChangePassword extends Vue {
  public password = '';
  public passwordConfirmed = '';
  public saving = false;

  public async changePassword() {
    try {
      this.saving = true;
      await AuthService.changePassword(this.password, this.passwordConfirmed);
      success(this, 'Password Updated', 'Password successfully updated.');
      this.$router.push('/account');
    } catch (err) {
      error(
        this,
        err,
        'Password Change Failed',
        'Password change failed, please try again.'
      );
    } finally {
      this.saving = false;
    }
  }
}
</script>

<style scoped lang="scss">
form {
  max-width: 500px;

  button {
    margin: 10px;
  }
}
</style>
