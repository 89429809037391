<template>
  <div>
    <h2 class="title">
      Service Log Viewer
      <button
        class="button is-info is-light"
        v-on:click="loadLog"
        title="Refresh log"
        :disabled="loading"
      >
        <font-awesome-icon icon="sync" :spin="loading" />
      </button>
    </h2>
    <b-table ref="table" v-if="!!log && !!log.length" :data="log" :loading="loading">
      <b-table-column field="date" label="Date" v-slot="props">
        {{ props.row.date | toDateTime }}
      </b-table-column>
      <b-table-column field="service" label="Service" v-slot="props">
        {{ props.row.service }}
      </b-table-column>
      <b-table-column field="message" label="Message" v-slot="props">
        {{ props.row.message }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ServiceLogEntry } from '@/models/log';
import { error } from '@/services/user-messages.service';
import { getServiceLog } from '@/services/admin.service';

@Component
export default class ServiceLogViewer extends Vue {
  public log: ServiceLogEntry[] = [];
  public loading = true;

  public $refs!: {
    /** BTable from Buefy doesn't have a type */
    table: any;
  };

  public mounted() {
    this.loadLog();
  }

  public async loadLog() {
    try {
      this.loading = true;
      this.log = await getServiceLog();
    } catch (err) {
      error(this, err);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  margin-left: 10px;

  svg {
    margin-right: 5px;
  }
}

.buttons {
  margin-top: 15px;
}

pre {
  max-width: 76vw;
}
</style>
