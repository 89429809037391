<template>
  <div class="container">
<!-- TODO: add breadcrumbs -->
    <div >
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Pages from './pages';

@Component
export default class AdminLayout extends Vue {
  pages = Pages;
}
</script>

<style scoped lang="scss">
div.b-sidebar,
div.inline-content {
  display: inline-flex;
}

div.inline-content {
  padding: 1em 2em;
}
</style>
