<template>
  <div class="container">
    <h1 class="title">Transfer Domain</h1>
    <form v-on:submit.prevent="transferDomain">
      <b-field>
        <b-input v-model="eppCode" placeholder="Enter EPP Code"></b-input>
      </b-field>
      <b-field>
        <b-input v-model="domainName" placeholder="Enter domain"></b-input>
      </b-field>
      <b-button native-type="submit">Transfer</b-button>
    </form>
    <b-table :data="transfers">
      <b-table-column field="domain" label="Domain" v-slot="props">
        {{ props.row.domainName }}
      </b-table-column>
      <b-table-column field="cost" label="Price" v-slot="props">
        {{ props.row.transfer.price | currencyToString }}
      </b-table-column>
      <b-table-column field="state" label="State" v-slot="props">
        {{ props.row.state | transferStateToString }}
      </b-table-column>
      <b-table-column width="425" v-slot="props">
        <div class="action-row" v-if="inCart(props.row)">
          <span class="has-text-success">
            <font-awesome-icon icon="check-circle" />Added to cart
          </span>
          <b-button type="is-text" v-on:click="removeFromCart(props.row)">Remove</b-button>
        </div>
        <div class="action-row" v-else>
          <b-button v-on:click="addToCart(props.row)">Add to cart</b-button>
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ShoppingCart } from '@/models/purchases';
import { transfer, getTransfers } from '@/services/domains.service';
import { error } from '@/services/user-messages.service';
import cart from '@/store/modules/cart';
import { DomainTransfer } from '@/models/domains';

@Component
export default class TransferDomain extends Vue {
  public eppCode = '';
  public domainName = '';
  public transfers: DomainTransfer[] = [];
  /** Shopping cart */
  get cart(): ShoppingCart {
    return cart;
  }

  async mounted() {
    this.transfers = await getTransfers();
  }

  public removeFromCart(cartItem: DomainTransfer) {
    if (cartItem.transfer) {
      cart.removeItemFromCart(cartItem.transfer);
    }
  }
  /** Add domain to cart */
  public addToCart(dt: DomainTransfer) {
    if (dt.transfer) {
      cart.addToCart(dt.transfer);
    }
  }
  /**
   * Check if domain is already in cart
   */
  public inCart(domainTransfer: DomainTransfer): boolean {
    const exists = this.cart.items.some((d) => d.properties?.domain === domainTransfer.domainName);
    console.log(exists);
    return exists;
  }

  private async transferDomain() {
    try {
      if (this.eppCode === '') {
        error(this, null, 'Missing Info', 'EPP Code required for transfer');
        return;
      }
      if (this.domainName === '') {
        error(this, null, 'Missing Info', 'Domain required for transfer');
        return;
      }
      if (!this.transfers.some((d) => d.domainName === this.domainName)) {
        this.transfers.push(await transfer(this.domainName, this.eppCode));
        this.domainName = '';
        this.eppCode = '';
      } else {
        error(this, null, 'Already Added', 'Domain already in list');
      }
    } catch (err) {
      error(this, err, 'Transfer Unavailable', 'Domain transfer unavailable. Please try again.');
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  max-width: 200px;

  button {
    margin-left: 10px;
  }
}

.action-row {
  display: flex;
  align-items: center;
  margin-left: 2em;

  svg {
    margin-right: 0.3em;
  }

  span {
    margin-right: 1em;
  }
}

div.b-table {
  margin-top: 1em;
}

table {
  td {
    vertical-align: middle;
  }
}
</style>
