<template>
  <div class="container">
    <h1 class="title">Login</h1>
    <form v-on:submit.prevent="login">
      <section>
        <b-field label="Email">
          <b-input type="email" v-model="email" required :autofocus="true"></b-input>
        </b-field>

        <b-field label="Password">
          <b-input type="password" v-model="password" required></b-input>
        </b-field>
        <b-button type="is-primary" native-type="submit" :loading="submitting">Log In</b-button>
      </section>
    </form>

    <router-link :to="{ name: 'forgot-password', params: { email: email } }"
      >Forgot Password</router-link
    >
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { login as authLogin, isLoggedIn } from '@/services/auth.service';
import { error } from '@/services/user-messages.service';

@Component
export default class Login extends Vue {
  @Prop({
    type: String,
    default: '/'
  })
  readonly redirect!: string;

  public email = '';
  public password = '';
  /** if form is in submitting state */
  public submitting = false;

  public async mounted() {
    if (await isLoggedIn()) {
      this.redirectToSite();
    }
  }

  public async login() {
    try {
      this.submitting = true;
      await authLogin(this.email, this.password);
      this.redirectToSite();
    } catch (err) {
      const e = err as AxiosError;
      const status: number = e?.response ? e.response.status : 0;
      const message = {
        text: 'Log in failed, please try again.',
        title: 'Log In Failed'
      };
      if (status === 401) {
        message.text = 'Invalid email address or password.';
      } else if (status === 0) {
        message.text = 'Unable to reach the Lone Lark server.';
      }
      error(this, err as Error, message.title, message.text);
    } finally {
      this.submitting = false;
    }
  }

  /**
   * If user already signed in, redirect to site
   */
  private redirectToSite() {
    this.$router.push(this.redirect).catch(() => {
      // We don't care about navigation errors here.
      // An error is thrown if the user doesn't have a
      // site, since they are redirected to the "no sites" page.
    });
  }
}
</script>

<style scoped>
form {
  max-width: 500px;
}
</style>
