<template>
  <div class="container">
    <h1 class="title">Order Details</h1>
    <div v-if="invoice">
      <h1 class="subtitle is-5">Order {{ invoice.orderId }}</h1>
      <div class="columns">
        <div class="column is-one-third">
          <table>
            <tr>
              <td>
                <strong>Date</strong>
              </td>
              <td>{{ invoice.created | toDate }}</td>
            </tr>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>{{ invoice.total | currencyToString }}</td>
            </tr>
            <tr>
              <td>
                <strong>Status</strong>
              </td>
              <td>
                <span v-if="invoice.paid">Paid</span>
                <em v-else>Awaiting payment</em>
              </td>
            </tr>
          </table>
        </div>
        <div v-if="!invoice.paid" class="column">
          <b-button
            class="pay"
            type="is-primary"
            tag="router-link"
            :to="{name: 'order-payment'}"
          >Pay Invoice</b-button>
        </div>
      </div>
      <p class="summary">{{ $pluralize('item', invoice.items.length, true) }}</p>
      <div class="items">
        <div class="order-item" v-for="item in invoice.items" :key="item.id">
          <div class="columns">
            <div class="column">
              <h6 class="title is-6">{{ item.title }}</h6>
              <p>{{ item.description }}</p>
            </div>
            <div class="column">
              <p>{{ item.price | currencyToString }}</p>
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { error } from '@/services/user-messages.service';
import { Invoice } from '@/models/purchases';
import { getOrder } from '@/services/payments.service';

@Component
export default class UserOrder extends Vue {
  public loading = false;

  @Prop(String) readonly invoiceId!: string;

  /** User order. */
  public invoice: Invoice | null = null;

  mounted() {
    this.loadOrder();
  }

  public async loadOrder() {
    try {
      this.loading = true;
      this.invoice = await getOrder(this.invoiceId);
    } catch (err) {
      error(
        this,
        err,
        'Order Unavailable',
        'Viewing order is currently unavailable. Please try again.'
      );
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
div.b-table {
  margin-top: 1em;
}

table {
  margin-bottom: 1em;

  td {
    vertical-align: middle;
    padding-right: 1em;
  }
}

p.summary {
  margin-bottom: 1em;
}

.items {
  margin-bottom: 3em;
}

.order-item {
  margin-bottom: 10px;

  .title {
    margin-bottom: 2px;
  }
}

a.pay.router-link-exact-active {
  // hide "pay" button when user is actively paying
  display: none;
}
</style>
