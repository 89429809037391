import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import { isLoggedIn } from '@/services/auth.service';
import app from '@/store/modules/app';
import AccountHome from './pages/account/AccountHome.vue';
import AccountLayout from './pages/account/AccountLayout.vue';
import ChangePassword from './pages/account/ChangePassword.vue';
import UserDomains from './pages/account/Domains.vue';
import UserOrders from './pages/account/Orders.vue';
import UserPlans from './pages/account/Plans.vue';
import AddPlan from './pages/account/AddPlan.vue';
import UserReceipts from './pages/account/Receipts.vue';
import UserOrder from './pages/account/Order.vue';
import Billing from './pages/account/Billing.vue';
import ForgotPassword from './pages/ForgotPassword.vue';
import ResetPassword from './pages/ResetPassword.vue';
import Dashboard from './pages/Dashboard.vue';
import Reports from './pages/Reports.vue';
import Login from './pages/Login.vue';
import NoSites from './pages/NoSites.vue';
import Cart from './pages/cart/Cart.vue';
import PreCheckout from './pages/cart/PreCheckout.vue';
import Checkout from './pages/cart/Checkout.vue';
import NewUserWelcome from './pages/NewUserWelcome.vue';
import SettingsLayout from './pages/site-settings/SettingsLayout.vue';
import SiteUsers from './pages/site-settings/SiteUsers.vue';
import SiteNotifications from './pages/site-settings/SiteNotifications.vue';
import SiteEditor from './pages/site-editor/Editor.vue';
import ContentLayout from './pages/content/ContentLayout.vue';
import SitePages from './pages/content/SitePages.vue';
import SiteContentHome from './pages/content/ContentHome.vue';
import SiteAssets from './pages/content/SiteAssets.vue';
import AddCollection from './pages/site-editor/AddCollection.vue';
import CollectionDetails from './pages/site-editor/CollectionDetails.vue';
import EditPage from './pages/site-editor/EditPage.vue';
import StoreItems from './pages/store/StoreItems.vue';
import EditStoreItem from './pages/store/EditStoreItem.vue';
import AdminLayout from './pages/admin/AdminLayout.vue';
import AdminHome from './pages/admin/AdminHome.vue';
import AdminDashboard from './pages/admin/AdminDashboard.vue';
import StoreIssues from './pages/admin/StoreIssues.vue';
import EditSite from './pages/admin/sites/EditSite.vue';
import Sites from './pages/admin/sites/Sites.vue';
import EditUser from './pages/admin/users/EditUser.vue';
import Users from './pages/admin/users/Users.vue';
import SendUserMessage from './pages/admin/SendUserMessage.vue';
import WebLogViewer from './pages/admin/WebLogViewer.vue';
import ServiceLogViewer from './pages/admin/ServiceLogViewer.vue';
import CreateInvoice from './pages/admin/CreateInvoice.vue';
import AddDomain from './pages/account/AddDomain.vue';
import TransferDomain from './pages/account/TransferDomain.vue';
import Deploy from './pages/admin/Deploy.vue';
import Theme from './pages/content/Theme.vue';
import AddTheme from './pages/admin/AddTheme.vue';
import { setPageTitle } from './utilities/helpers';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    // site param is optional. If not provided, dashboard component will load default site for user
    path: '/dashboard/:siteName?',
    name: 'dashboard',
    component: Dashboard,
    props: true,
    meta: { title: 'Dashboard' }
  },
  {
    // site param is optional. If not provided, report component will load default/current site for user
    path: '/reports/:siteName?',
    name: 'reports',
    component: Reports,
    props: route => ({
      start: route.query.start,
      end: route.query.end,
      siteName: route.params.siteName
    }),
    meta: { title: 'Reports' }
  },
  {
    path: '/account',
    component: AccountLayout,
    // "notSiteSpecific" routes mean they are not tied to a specific site
    //      so the site dropdown is hidden to prevent confusion
    meta: { notSiteSpecific: true },
    children: [
      {
        path: '/',
        name: 'account',
        component: AccountHome,
        meta: { title: 'My account' }
      },
      {
        path: 'change-password',
        name: 'change-password',
        component: ChangePassword,
        meta: { title: 'Change password' }
      },
      {
        path: 'domains',
        name: 'user-domains',
        component: UserDomains,
        meta: { title: 'Domains' }
      },
      {
        path: 'domains/add',
        name: 'add-domain',
        component: AddDomain,
        meta: { title: 'Add domain' }
      },
      {
        path: 'domains/transfer',
        name: 'transfer-domain',
        component: TransferDomain,
        meta: { title: 'Transfer domain' }
      },
      {
        path: 'orders',
        name: 'user-orders',
        component: UserOrders,
        meta: { title: 'Orders' }
      },
      {
        path: 'plans',
        name: 'user-plans',
        component: UserPlans,
        meta: { title: 'Plans' }
      },
      {
        path: 'plans/add',
        name: 'add-plan',
        component: AddPlan,
        meta: { title: 'Plan signup' }
      },
      {
        path: 'receipts',
        name: 'user-receipts',
        component: UserReceipts,
        meta: { title: 'Receipts' }
      },
      {
        path: 'orders/:invoiceId',
        name: 'user-order',
        props: true,
        component: UserOrder,
        meta: { title: 'Order' },
        children: [
          {
            path: 'payment',
            name: 'order-payment',
            props: true,
            component: Checkout,
            meta: { title: 'Order payment' }
          }
        ]
      },
      {
        path: 'billing',
        name: 'user-billing',
        component: Billing,
        meta: { title: 'Billing details' }
      },
      {
        path: 'address',
        name: 'user-address',
        component: () => import('./pages/account/UserAddress.vue'),
        meta: { title: 'Address' }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: route => ({
      redirect: route.query.path
    }),
    meta: { allowAnyone: true, title: 'Login' }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { allowAnyone: true }
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword,
    meta: { allowAnyone: true }
  },
  {
    path: '/welcome/:token',
    name: 'new-user-welcome',
    component: NewUserWelcome,
    props: true,
    meta: { allowAnyone: true }
  },
  {
    path: '/no-sites',
    name: 'no-sites',
    component: NoSites,
    meta: { allowAnyone: true, title: 'No site memberships' }
  },
  {
    path: '/cart',
    component: Cart,
    meta: { notSiteSpecific: true },
    children: [
      {
        path: '/',
        name: 'cart',
        component: PreCheckout,
        meta: { title: 'Shopping cart' }
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: Checkout,
        meta: { title: 'Checkout' }
      }
    ]
  },
  {
    path: '/sign-up',
    component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/Layout.vue'),
    meta: { title: 'Sign up', notSiteSpecific: true },
    children: [
      {
        path: '/',
        name: 'sign-up',
        component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/Welcome.vue'),
        meta: { allowAnyone: true, title: 'Sign up' }
      },
      {
        path: 'account',
        name: 'sign-up-account',
        component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/Account.vue'),
        meta: { allowAnyone: true, title: 'Create account' }
      },
      {
        path: 'domain',
        name: 'sign-up-domain',
        component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/Domain.vue'),
        meta: { title: 'Choose Domain' }
      },
      {
        path: 'payment',
        component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/Payment.vue'),
        meta: { title: 'Payment' },
        children: [
          {
            path: '/',
            name: 'sign-up-payment',
            component: Checkout,
            meta: { title: 'Checkout' }
          }
        ]
      },
      {
        path: 'complete',
        name: 'sign-up-complete',
        component: () => import(/* webpackChunkName: "sign-up" */ './pages/sign-up/SignUpComplete.vue'),
        meta: { title: 'Sign-up complete!' }
      }
    ]
  },
  {
    path: '/content/:siteName?',
    component: ContentLayout,
    name: 'site-content-home',
    props: true,
    children: [
      {
        path: 'collections',
        name: 'site-collections',
        component: SiteContentHome,
        props: true,
        meta: { title: 'Site content' }
      },
      {
        path: 'files',
        name: 'site-files',
        component: SiteAssets,
        props: true,
        meta: { title: 'Site assets' }
      },
      {
        path: 'pages',
        name: 'site-pages',
        component: SitePages,
        props: true,
        meta: { title: 'Site pages' }
      },
      {
        path: 'pages/add',
        name: 'add-page',
        component: EditPage,
        meta: { title: 'Add page' },
        props: true
      },
      {
        path: 'pages/:id',
        name: 'edit-page',
        component: EditPage,
        meta: { title: 'Edit page' },
        props: true
      },
      {
        path: 'collection',
        name: 'site-collection',
        component: AddCollection,
        props: true,
        meta: { title: 'Site collection' }
      },
      {
        path: 'collections/:collectionId',
        name: 'collection-details',
        component: CollectionDetails,
        meta: { title: 'Site collection' },
        props(route) {
          const props = { ...route.params };
          // cast string to number
          props.collectionId = +props.collectionId as any;
          return props;
        }
      },
      {
        path: 'editor',
        name: 'site-editor',
        component: SiteEditor,
        props: true,
        meta: { title: 'Site editor' }
      },
      {
        path: 'theme',
        name: 'select-theme',
        component: Theme,
        meta: { title: 'Theme' }
      }
    ]
  },
  {
    path: '/store/:siteName?',
    name: 'site-store',
    component: StoreItems,
    meta: { title: 'Site Store' },
    props: true
  },
  {
    path: '/store/:siteName?/add',
    name: 'add-store-item',
    component: EditStoreItem,
    props: true,
    meta: { title: 'Add Item' }
  },
  {
    path: '/store/:siteName?/:id',
    name: 'edit-store-item',
    component: EditStoreItem,
    props: true,
    meta: { title: 'Edit Item' }
  },
  {
    path: '/settings/:siteName?',
    component: SettingsLayout,
    props: true,
    children: [
      {
        path: 'users',
        name: 'site-users',
        component: SiteUsers,
        props: true,
        meta: { title: 'Edit Site Users' }
      },
      {
        path: 'notifications',
        name: 'site-notifications',
        component: SiteNotifications,
        props: true,
        meta: { title: 'Edit Site Notifications' }
      },
      { path: '/', redirect: 'users' }
    ]
  },
  {
    path: '/admin',
    component: AdminLayout,
    meta: { title: 'Lark Admin', notSiteSpecific: true },
    children: [
      {
        path: '/',
        name: 'admin',
        component: AdminHome,
        meta: { title: 'Admin' }
      },
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: AdminDashboard,
        meta: { title: 'Admin Dashboard' }
      },
      {
        path: 'store',
        name: 'store-issues',
        component: StoreIssues,
        meta: { title: 'Store Issues' }
      },
      {
        path: 'add-theme',
        name: 'add-theme',
        component: AddTheme,
        meta: { title: 'Add Theme' }
      },
      {
        path: 'log',
        name: 'log',
        component: WebLogViewer,
        meta: { title: 'Web Log' }
      },
      {
        path: 'deploy',
        name: 'deploy',
        component: Deploy,
        meta: { title: 'Deploy a Site' }
      },
      {
        path: 'service-log',
        name: 'service-log',
        component: ServiceLogViewer,
        meta: { title: 'Service Log' }
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: { title: 'All Users' }
      },
      {
        path: 'users/add',
        name: 'add-user',
        component: EditUser,
        meta: { title: 'Create New User' }
      },
      {
        path: 'users/:id',
        name: 'edit-user',
        component: EditUser,
        props: true,
        meta: { title: 'Edit User' }
      },
      {
        path: 'sites',
        name: 'sites',
        component: Sites,
        meta: { title: 'Lone Lark Sites' }
      },
      {
        path: 'sites/add',
        name: 'add-site',
        component: EditSite,
        meta: { title: 'Create New Site' }
      },
      {
        path: 'sites/:id',
        name: 'edit-site',
        component: EditSite,
        props: true,
        meta: { title: 'Edit Site' }
      },
      {
        path: 'invoice',
        name: 'create-invoice',
        component: CreateInvoice,
        meta: { title: 'Create Invoice' }
      },
      {
        path: 'send-message',
        name: 'send-message',
        component: SendUserMessage,
        meta: { title: 'Send User Message' }
      }
    ]
  },
  { path: '/', redirect: '/dashboard' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const isRouteSiteSpecific = (to: Route) => {
  let notSiteSpecific = false;
  const configs = to.matched.filter(r => r.meta.notSiteSpecific !== undefined);
  if (configs.length) {
    notSiteSpecific = configs[configs.length - 1].meta.notSiteSpecific;
  }
  return !notSiteSpecific;
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.allowAnyone)) {
    next();
  } else {
    // route requires authentication
    const user = await isLoggedIn();
    if (!user) {
      next({
        path: '/login',
        query: { path: to.fullPath }
      });
      return;
    }
    if (user.sites.length === 0 && isRouteSiteSpecific(to)) {
      // user does not belong to any sites
      next({
        path: '/no-sites'
      });
      return;
    }
    next();
  }
});

router.beforeEach((to, from, next) => {
  setPageTitle(to?.meta?.title);

  // set if page is site specific or not
  const siteSpecific = isRouteSiteSpecific(to);
  app.setPageSiteSpecific(siteSpecific);

  next();
});

export default router;
