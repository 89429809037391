import {
  Action, Module, Mutation, VuexModule, getModule
} from 'vuex-module-decorators';
import { Site, SiteRole } from '@/models/site';
import { User } from '@/models/user';
import { getCurrentUser } from '@/services/users.service';
import { LarkHub } from '@/services/lark.hub';
import store from '../index';
import cart from './cart';

/**
 * Main module used for storing app data.
 */
@Module({
  store,
  name: 'app',
  dynamic: true
})
class AppModule extends VuexModule {
  currentUser: User | null = null;

  sites: Site[] = [];

  /** Current active site */
  private currentSite: Site | null = null;

  /** Flag if current page and route are site-specific. */
  private _pageSiteSpecific = true;

  private larkHub: LarkHub | undefined;

  get site() {
    return this.currentSite;
  }

  get hub() {
    return this.larkHub as LarkHub;
  }

  /** Flag if current page/route is site-specific. */
  get pageSiteSpecific(): boolean {
    return this._pageSiteSpecific;
  }

  /** Get the LL site id if the user belongs to LL. */
  get lonelarkSiteId(): string | null {
    if (this.currentUser) {
      for (let i = 0; i < this.currentUser.sites.length; i++) {
        if (this.currentUser.sites[i].siteName === 'lonelark.com') {
          return this.currentUser.sites[i].siteId;
        }
      }
    }
    return null;
  }

  /**
   * Set whether current page is site-specific.
   * @param isSiteSpecific Flag if page is site-specific
   */
  @Mutation
  setPageSiteSpecific(isSiteSpecific: boolean) {
    this._pageSiteSpecific = isSiteSpecific;
  }

  @Mutation
  setUserAndSites(user: User | null) {
    if (user && user.sites) {
      if (user.sites.length) {
        this.currentSite = user.sites[0];
      }
      this.sites = user.sites;
      user.larkAdmin = user.sites.some(s => s.role === SiteRole.LarkAdmin);
    } else {
      // clear out sites if no user or sites passed in
      this.sites = [];
    }
    this.currentUser = user;
  }

  @Mutation
  setActiveSite(site: Site) {
    this.currentSite = site;
  }

  @Mutation
  setHub(hub: LarkHub) {
    this.larkHub = hub;
  }

  @Action
  async getCurrentUser(): Promise<User | null> {
    const existingUser = await Promise.resolve(this.currentUser);
    if (existingUser) {
      return existingUser;
    }

    const user = await getCurrentUser();
    this.context.commit('setUserAndSites', user);

    const userCart = user && user.cart ? user.cart : null;
    cart.updateCart(userCart);

    return user;
  }
}

export default getModule(AppModule);
